import { useState } from 'react';
import SponsorWidget from './SponsorWidget';
import PieChart from '../../Screens/MYVR/PieChart';
import {
	satelliteIcon,
	sponsorEventIcon,
} from '../../../components/icons/icons';

const items = [
	{
		title: 'Event',
		icon: sponsorEventIcon,
	},
	{
		title: 'Hospitality',
		icon: sponsorEventIcon,
	},
	{
		title: 'Industry',
		icon: sponsorEventIcon,
	},
];
const CubesAvailability = ({ cubes_data, numberOfCubes }) => {
	const [sponsorType, setSponsorType] = useState('');
	return (
		<div className="flex h-[271px] gap-2 absolute bottom-0 m-2 w-full">
			<div className="flex bg-[#1E1E21] h-full w-[399px]">
				<SponsorWidget
					title="Cube Availability"
					icon={satelliteIcon}>
					<div className="flex items-center justify-center m-4">
						<PieChart
							cubes_data={cubes_data}
							numberOfCubes={numberOfCubes}
						/>
					</div>
				</SponsorWidget>
			</div>
			<div className="flex bg-[#1E1E21] h-full w-[399px]">
				<SponsorWidget
					title="Sponsorship Type"
					icon={satelliteIcon}>
					<div className="flex flex-col gap-4 m-4">
						<div className="flex">
							{items.map((ele) => {
								return (
									<div
										className={`flex flex-row items-center justify-center h-[76px] w-[82px] border-[#BFBFBF] border-2 rounded-lg m-2 ${sponsorType === ele.title ? 'bg-[#87e78b] opacity-20 border-[#87E78B]' : 'border-[#BFBFBF]'}`}>
										<div
											className="flex flex-col items-center justify-center h-[76px] w-[82px]"
											role="button"
											tabIndex="0"
											onKeyDown={() => {}}
											onClick={() =>
												setSponsorType(ele.title)
											}>
											<span
												className="h-10 w-20 p-2 box-border flex items-center justify-center"
												dangerouslySetInnerHTML={{
													__html: ele.icon,
												}}
											/>
											<span className="text-[#FFF] text-sm font-bold ">
												{ele.title}
											</span>
										</div>
									</div>
								);
							})}
						</div>
						<span className="font-bold text-sm text-[#FFF]">
							Number of Attendees
						</span>
						<div className="flex items-center justify-center border-2 bg-[#3E3E3E] rounded-3xl w-[160px] h-10">
							<span className="text-lg font-medium">25000</span>
						</div>
					</div>
				</SponsorWidget>
			</div>
			<div className="flex flex-1 bg-[#1E1E21] h-full">
				<SponsorWidget
					title="Cubes To Sponsor"
					icon={satelliteIcon}>
					<div className="flex flex-col gap-4 m-4 items-center justify-center">
						<div className="flex flex-col items-center justify-center border-2 bg-[#3E3E3E] rounded-3xl w-[268px] h-[30px]">
							<span className="text-lg">25000</span>
						</div>
						<div className="flex flex-col items-center justify-center border-2 bg-[#DCFF06] rounded-3xl w-[191px] h-10">
							<span className="text-sm uppercase text-[#2A2A2A]">
								Add To Cart
							</span>
						</div>
					</div>
				</SponsorWidget>
			</div>
		</div>
	);
};

export default CubesAvailability;
