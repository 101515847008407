import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, PersonAddAlt1Outlined } from '@mui/icons-material';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import Profile from '../../../components/grid/Profile';
import TextToBool from '../../../components/grid/TextToBool';
import { useGridState } from '../../../components/hooks/useGridState';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';
import { setClients } from '../../ReduxStore/Actions/ClientAction';

const ClientList = (props) => {
	const {
		loginedInUserData,
		showModal: showModalAction,
		clients,
		setClients: setClientsAction,
	} = props;
	const { assignedRole, clientId } =
		loginedInUserData?.data?.getLoggedInUserData;

	const {
		error,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateClientsTable',
		onUpdateTable: 'onUpdateClientsTable',
		fetchRecords: async () => {
			try {
				if (!clients.length) {
					let filter_variables = {};
					if (assignedRole === 'super-admin') {
						filter_variables = {};
					} else if (assignedRole === 'client-admin') {
						filter_variables = {
							filter: { clientName: { eq: clientId } },
						};
					} else {
						filter_variables = {
							filter: { clientName: { eq: 'NA' } },
						};
					}

					const responseObject = await makeGraphQLRequest({
						query: queries.listClientsTables,
						variables: filter_variables,
					});
					const items_list =
						responseObject?.data?.listClientsTables?.items || [];
					onRecords(items_list);
					setClientsAction(items_list);
				} else {
					onRecords(clients);
				}
			} catch (e) {
				onError(e);
			}
		},
		onData: (data, type) => {
			const newData =
				type === 'new'
					? [data, ...clients]
					: clients.map((item) => {
							if (item.id === data.id) {
								return data;
							}
							return item;
						});
			setClientsAction(newData);
		},
		useRedux: true,
	});

	const actions = [
		{
			id: 'add_client',
			name: 'Add Client',
			primary: true,
			onClick: () => showModalAction({ value: true }),
			icon: <PersonAddAlt1Outlined />,
		},
		{
			id: 'edit_client',
			name: 'Update Client',
			onClick: () =>
				showModalAction({ value: true, selectedItem: selectedRows[0] }),
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'clientProfilePicURL',
				headerName: 'Profile',
				width: 100,
				cellRenderer: Profile,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				pinned: 'left',
			},
			{
				field: 'clientName',
				headerName: 'Client',
				width: 200,
			},
			{
				field: 'clientSatus',
				headerName: 'Status',
				width: 100,
				cellRenderer: TextToBool,
			},
			{
				field: 'clientContact',
				headerName: 'Contact',
				width: 180,
			},
			{
				field: 'clientAddress',
				headerName: 'Address',
				width: 150,
			},
			{
				field: 'clientCountry',
				headerName: 'Country',
				width: 150,
			},
			{
				field: 'clientVAT',
				headerName: 'VAT',
				width: 150,
			},
			{
				field: 'clienttype',
				headerName: 'Type of Client',
				width: 150,
			},
			{
				field: 'createdAt',
				flex: 1,
			},
			{
				field: 'createdBy',
				flex: 1,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Clients">
			<Grid
				refObject={gridRef}
				rowData={clients}
				colDefs={colDefs}
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
	clients: state.clients,
});

export default connect(mapStateToProps, { showModal, setClients })(ClientList);
