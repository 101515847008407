import { cameraIcon, moreIcon } from '../../../../components/icons/icons';
import { latestSightingMap, camera } from '../common/images';

const LatestSightingSidebar = () => {
	return (
		<div className="flex flex-col overflow-hidden gap-[10px] max-h-full flex-1">
			<div className="flex w-full gap-[10px] h-[28%]">
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-4/5"
						src={camera}
						alt="CAM_LG01"
					/>
					<span className="text-[#BFBFBF] shrink-0">Cam LG01</span>
				</div>
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-4/5"
						src={camera}
						alt="CAM_LG02"
					/>
					<span className="text-[#BFBFBF] shrink-0">Cam LG02</span>
				</div>
			</div>
			<div className="flex w-full gap-[10px] h-[28%]">
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-4/5"
						src={camera}
						alt="CAM_LG03"
					/>
					<span className="text-[#BFBFBF] shrink-0">Cam LG03</span>
				</div>
				<div className="flex flex-row items-end justify-end bg-[#1E1E21] rounded-[5px] flex-1 overflow-hidden">
					<div className="flex flex-row gap-2 p-6">
						<div className="rounded-full bg-[#BFBFBF] h-3 w-3" />
						<div className="rounded-full bg-[#BFBFBF] h-3 w-3" />
						<div className="rounded-full bg-[#BFBFBF] h-3 w-3" />
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden relative">
				<div className="flex justify-between items-center p-[10px]">
					<span className="uppercase">iNaturalist sightings</span>
					<span
						className="h-5 w-5 box-border flex items-center justify-center"
						dangerouslySetInnerHTML={{ __html: moreIcon }}
					/>
				</div>
				<img
					className="object-cover h-4/5"
					src={latestSightingMap}
					alt="Latest Sighting Map"
				/>
				<span
					className="absolute bottom-8 right-20 rounded-full bg-[#D7F4B1]"
					dangerouslySetInnerHTML={{ __html: cameraIcon }}
				/>
			</div>
		</div>
	);
};

export default LatestSightingSidebar;
