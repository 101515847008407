import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// Register the ArcElement component globally
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ cubes_data, numberOfCubes }) => {
	const noOfCubesEntered = numberOfCubes ? Number(numberOfCubes) : 0;
	const finalCubesData = [
		String(Number(cubes_data[0]) - noOfCubesEntered),
		String(Number(cubes_data[1]) + noOfCubesEntered),
		cubes_data[2],
	];
	const data = {
		labels: ['Available Units', 'Sponsored Units', 'Unavailable Units'],
		datasets: [
			{
				data: finalCubesData,
				backgroundColor: [
					'rgba(121, 231, 205, 1)',
					'rgba(147, 244, 97, 1)',
					'rgba(91, 146, 120, 1)',
				],
				hoverBackgroundColor: [
					'rgba(121, 231, 205, 0.6)',
					'rgba(147, 244, 97, 0.6)',
					'rgba(91, 146, 120, 0.6)',
				],
				borderWidth: 0,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'right',
				align: 'center',
				labels: {
					usePointStyle: true,
					pointStyle: 'circle',
					color: '#585858',
					font: {
						weight: 'bold',
						size: 12,
					},
				},
			},
			tooltip: {
				enabled: true,
			},
		},
		layout: {
			padding: {
				right: 20,
			},
		},
		aspectRatio: 1.5, // Adjust aspect ratio as needed
	};

	return (
		<Pie
			data={data}
			options={options}
		/>
	);
};

export default PieChart;
