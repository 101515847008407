/* eslint-disable default-param-last */
export function loginedInUserData(state = {}, action) {
	switch (action.type) {
		case 'login':
			return action.payload;
		case 'SET_CLIENT_AND_ROLES':
			return {
				...state,
				data: {
					...state.data,
					getLoggedInUserData: {
						...state.data.getLoggedInUserData,
						listofinvitationClients:
							action.payload?.listofinvitationClients,
						listofinvitationRoles:
							action.payload?.listofinvitationRoles,
					},
				},
			};
		case 'clear':
			return {};
		default:
			return state;
	}
}

export function currentLoginSection(state = { page: 'LOGIN_FORM' }, action) {
	switch (action.type) {
		case 'CURRENT_LOGIN_SECTION':
			return action.payload;
		default:
			return state;
	}
}
