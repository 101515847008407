import { useMemo } from 'react';
import { connect } from 'react-redux';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import Grid from '../../CommonComponents/AGGrid/Grid';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useGridState } from '../../../components/hooks/useGridState';
import * as queries from '../../../graphql/queries';
import PurchaseStatusActionRenderer from './PurchaseStatusActionRenderer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const InvoiceRenderer = ({ value }) => {
	return (
		<span
			style={{
				display: 'flex',
				height: '100%',
				width: '100%',
				alignItems: 'center',
				fontWeight: 'bold',
				fontSize: 12,
			}}>
			{value ? value.toUpperCase() : 'NA'}
		</span>
	);
};

const PurchaseList = (props) => {
	const { loginedInUserData } = props;
	const { assignedRole, clientId } =
		loginedInUserData?.data?.getLoggedInUserData;
	const {
		error,
		rowData,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreatePurchaseOrders',
		onUpdateTable: 'onUpdatePurchaseOrders',
		fetchRecords: async () => {
			try {
				// const { assignedRole, clientId } = props.loginedInUserData?.data?.getLoggedInUserData;
				let filter_variables = {};
				if (assignedRole !== 'super-admin') {
					filter_variables = {
						filter: { purchasedClientName: { eq: clientId } },
					};
				}
				const responseObject = await makeGraphQLRequest({
					query: queries.listPurchaseOrders,
					variables: filter_variables,
				});
				const items_list =
					responseObject?.data?.listPurchaseOrders?.items || [];
				onRecords(items_list);
			} catch (e) {
				onError(e);
			}
		},
	});

	const colDefs = useMemo(
		() => [
			{
				field: 'purchasedInvoiceNumber',
				headerName: 'Invoice #',
				pinned: 'left',
				cellRenderer: InvoiceRenderer,
				width: 110,
			},
			{
				field: 'purchasedClientName',
				headerName: 'Client',
				width: 150,
			},
			{
				field: 'purchasedForestName',
				headerName: 'Forest / Plot',
				valueGetter: (params) => {
					return `${params.data.purchasedForestName} / ${params.data.purchasedForestArea}`;
				},
				width: 250,
			},
			{
				field: 'purchasedNoOfCubes',
				headerName: 'Cubes * Price',
				valueGetter: (params) => {
					return `${params.data.purchasedNoOfCubes} * ${params.data.purchasedCubePrice}`;
				},
				width: 150,
			},
			{
				field: 'purchasedInvoiceCost',
				headerName: 'Amount($)',
				cellStyle: { 'font-weight': 'bold' },
				valueGetter: (params) => {
					return `${params.data.purchasedInvoiceCost}$`;
				},
				width: 110,
			},
			{
				field: 'purchaseStatus',
				headerName: 'Status',
				cellStyle: { 'font-weight': 'bold' },
				width: 110,
			},
			{
				field: 'purchaseStatus',
				headerName: 'Action',
				width: 125,
				cellRenderer: PurchaseStatusActionRenderer,
				cellRendererParams: {
					onError,
					customParam: assignedRole,
				},
			},
			{
				field: 'purchaseComments',
				headerName: 'Comments',
				autoHeight: true,
				cellStyle: { 'white-space': 'normal', lineHeight: '1.5' },
				width: 480,
				valueGetter: (params) => {
					if (
						params.data.purchaseStatus === 'New' ||
						params.data.purchaseStatus === ''
					) {
						return '-';
					}
					return `${params.data.purchaseStatus} by ${params.data.purchaseComments}`;
				},
			},
			{
				headerName: 'Purchased By',
				field: 'purchaseddBy',
				width: 300,
			},
			{
				field: 'purchasedOn',
				headerName: 'Purchased Date',
				width: 200,
			},
		],
		[assignedRole, onError]
	);

	return (
		<GridLayout
			error={error}
			title="Purchases">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				suppressRowClickSelection
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(PurchaseList);
