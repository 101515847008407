import { FormControlLabel, Checkbox } from '@digitalreality/ui';
import { memo } from 'react';

const CheckboxField = ({ onChange, label, value, disabled }) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					onChange={onChange}
					checked={value || false}
					disabled={disabled}
				/>
			}
			label={label}
		/>
	);
};

export default memo(CheckboxField);
