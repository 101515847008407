import React, { useState, useEffect, useRef } from 'react';
import './MyVRScrollingDiv.css';

const MyVRScrollingDiv = ({ children }) => {
	const scrollContainerRef = useRef(null);
	const [isScrolling, setIsScrolling] = useState(true);

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current;

		if (isScrolling) {
			scrollContainer.classList.add('scrolling');
		} else {
			scrollContainer.classList.remove('scrolling');
		}
	}, [isScrolling]);

	return (
		<div
			ref={scrollContainerRef}
			className="scroll-container"
			onFocus={() => {}}
			onBlur={() => {}}
			onMouseOver={() => setIsScrolling(false)}
			onMouseOut={() => setIsScrolling(true)}>
			<div className="scroll-content">{children}</div>
		</div>
	);
};

export default MyVRScrollingDiv;
