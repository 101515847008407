import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from './Screens/RootPages/Layout';
import RouteChangeHandler from './RouteChangeHandler';
import { routeList } from './CommonComponents/Drawer/routeList';
import NotFound from './Screens/NotFound/NotFound';
import SessionExpired from './SessionExpired';

const Routers = (props) => {
	const { loginedInUserData } = props;
	const { permissions } =
		loginedInUserData?.data?.getLoggedInUserData?.accessLevel || {};

	const filteredRoutes = routeList.filter((route) =>
		(permissions || []).includes(route.allowedPermissions)
	);
	return (
		<Router>
			<SessionExpired />
			<RouteChangeHandler />
			<Layout>
				<Routes>
					{filteredRoutes.map((route) => (
						<Route
							key={route.url}
							routeexact
							path={route.url}
							element={route.element}
						/>
					))}
					<Route
						path="*"
						element={<NotFound />}
					/>
				</Routes>
			</Layout>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(Routers);
