import { Storage } from 'aws-amplify';
import { IconButton, Tooltip } from '@digitalreality/ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const handleMediaDownload = async (value) => {
	try {
		const url = await Storage.get(value, { download: false });
		const parts = value.split('/');
		const fileName = parts[parts.length - 1];

		const response = await fetch(url);
		const blob = await response.blob();
		const blobUrl = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = blobUrl;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (error) {
		// console.log('Error downloading video:', error);
	}
};

function MediaDownLoad(props) {
	const { value } = props;
	return (
		<div className="flex justify-center w-full items-center h-full">
			<Tooltip title="Download Media">
				<IconButton onClick={() => handleMediaDownload(value)}>
					<FileDownloadOutlinedIcon className="text-sky-500" />
				</IconButton>
			</Tooltip>
		</div>
	);
}

export default MediaDownLoad;
