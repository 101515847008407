import { useContext } from 'react';
import { liveWeather, mapPinIcon } from '../../../../components/icons/icons';
import ExploreWidget from '../common/ExploreWidget';
import ForestContext from '../utils/ForestContext';

const DashboardSidebar = () => {
	const { images } = useContext(ForestContext);
	return (
		<div className="flex flex-col overflow-hidden gap-[10px] flex-1">
			<div className="flex w-full h-[30%] min-h-10 flex-1 shrink-0">
				<ExploreWidget
					title="Live Weather"
					icon={liveWeather}>
					<img
						className="object-cover"
						src={images?.weather}
						alt="Weather"
					/>
				</ExploreWidget>
			</div>
			<div className="flex w-full h-[30%] min-h-10 flex-1 shrink-0">
				<ExploreWidget
					title="Operation Area"
					icon={mapPinIcon}>
					<img
						className="object-cover w-full h-full"
						src={images?.operational}
						alt="mine plan"
					/>
				</ExploreWidget>
			</div>
		</div>
	);
};

export default DashboardSidebar;
