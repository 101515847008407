import { BasicPasswordField } from '@digitalreality/ui';
import { memo } from 'react';

const PasswordField = ({ onChange, value, onKeyDown, error, disabled }) => {
	return (
		<BasicPasswordField
			fullWidth
			value={value || ''}
			onChange={onChange}
			onKeyDown={(e) => onKeyDown?.(e)}
			error={!!error}
			disabled={disabled}
			helperText={error}
		/>
	);
};

export default memo(PasswordField);
