import { cameraRightsIcon } from '../../../../components/icons/icons';
import VideoPlayer from './VideoPlayer';

const AnimalShow = ({ selectedAnimal }) => {
	return (
		<div className="w-full h-full relative">
			{selectedAnimal.video ? (
				<VideoPlayer
					key={selectedAnimal.video}
					videoStorageKey={selectedAnimal.video}
				/>
			) : (
				<img
					className="w-full h-full object-cover"
					src={selectedAnimal.image}
					alt="Latest Panel Capture Icon"
				/>
			)}
			<div className="absolute bottom-1 p-2 left-2 flex gap-2 text-[#D9D9D9] text-xs items-center justify-center">
				<span
					dangerouslySetInnerHTML={{
						__html: cameraRightsIcon,
					}}
				/>
				<span>© 2021 Andrew Kerr. All rights reserved</span>
			</div>
		</div>
	);
};

export default AnimalShow;
