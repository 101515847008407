import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined } from '@mui/icons-material';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import { useGridState } from '../../../components/hooks/useGridState';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const commentsRenderer = ({ value }) => {
	return (
		<span
			style={{
				display: 'flex',
				height: '100%',
				width: '100%',
				alignItems: 'center',
			}}>
			{value || 'NA'}
		</span>
	);
};

const SupportTicketList = (props) => {
	const { loginedInUserData, showModal: showModalAction } = props;
	const { assignedRole, clientId } =
		loginedInUserData?.data?.getLoggedInUserData;

	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateSupportTicketsTable',
		onUpdateTable: 'onUpdateSupportTicketsTable',
		fetchRecords: async () => {
			try {
				let filter_variables = {};
				if (assignedRole !== 'super-admin') {
					filter_variables = {
						filter: { tickerCreatedClientName: { eq: clientId } },
					};
				}
				const responseObject = await makeGraphQLRequest({
					query: queries.listSupportTicketsTables,
					variables: filter_variables,
				});
				const items_list =
					responseObject?.data?.listSupportTicketsTables?.items || [];
				onRecords(items_list);
			} catch (e) {
				onError(e);
			}
		},
	});

	const actions = [
		{
			id: 'update_ticket',
			name: 'Update Ticket',
			onClick: () => {
				showModalAction({ value: true, selectedItem: selectedRows[0] });
			},
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'ticketRefNumber',
				headerName: 'Ticket ID',
				width: 150,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				pinned: 'left',
				filter: true,
			},
			{
				field: 'tickerCreatedClientName',
				headerName: 'Client',
				width: 150,
			},
			{
				field: 'ticketCreatedBy',
				headerName: 'Created By',
				flex: 1,
			},
			{
				field: 'ticketCreatedOn',
				headerName: 'Created At',
				width: 150,
			},
			{
				field: 'contactEmail',
				headerName: 'Contact Email',
				flex: 1,
			},
			{
				field: 'contactPhone',
				headerName: 'Contact Phone',
				width: 200,
			},
			{
				field: 'ticketStatus',
				headerName: 'Status',
				width: 100,
			},
			{
				field: 'ticketComments',
				headerName: 'Comments',
				cellRenderer: commentsRenderer,
				flex: 1,
				autoHeight: true,
				cellStyle: { 'white-space': 'normal' },
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Tickets">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(SupportTicketList);
