/* eslint-disable */
export async function generateThumbnail(
	media_type,
	mediaUrl,
	maxWidth,
	maxHeight,
	quality
) {
	return new Promise((resolve, reject) => {
		if (media_type == 'video') {
			console.log('type video>>>>>>>', media_type, mediaUrl);
			generateVideoThumbnail(
				mediaUrl,
				maxWidth,
				maxHeight,
				quality,
				resolve,
				reject
			);
		} else {
			console.log('type Image>>>>>>>', media_type, mediaUrl);
			generateImageThumbnail(
				mediaUrl,
				maxWidth,
				maxHeight,
				quality,
				resolve,
				reject
			);
		}
	});
}

async function generateVideoThumbnail(
	videoUrl,
	maxWidth = 100,
	maxHeight = 100,
	quality = 1,
	resolve,
	reject
) {
	try {
		const video = document.createElement('video');
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		video.crossOrigin = 'anonymous';
		video.src = videoUrl;

		video.addEventListener('loadeddata', () => {
			video.currentTime = 0;
		});

		video.addEventListener('seeked', () => {
			const videoWidth = video.videoWidth;
			const videoHeight = video.videoHeight;
			const aspectRatio = videoWidth / videoHeight;
			let width = maxWidth;
			let height = maxHeight;

			// Calculate dimensions respecting aspect ratio
			if (width / aspectRatio > height) {
				width = height * aspectRatio;
			} else {
				height = width / aspectRatio;
			}

			canvas.width = width;
			canvas.height = height;

			context.drawImage(video, 0, 0, width, height);

			canvas.toBlob(
				(blob) => {
					const url = URL.createObjectURL(blob);
					resolve(url);
				},
				'image/jpeg',
				quality
			);
		});

		video.addEventListener('error', (error) => {
			reject(error);
		});

		video.load();
	} catch (error) {
		reject(error);
	}
}

async function generateImageThumbnail(
	imageUrl,
	maxWidth = 100,
	maxHeight = 100,
	quality = 1,
	resolve,
	reject
) {
	try {
		resolve(imageUrl);
		// const img = new Image();
		// img.crossOrigin = 'anonymous';
		// img.src = imageUrl;

		// img.onload = () => {
		//   const canvas = document.createElement('canvas');
		//   const context = canvas.getContext('2d');

		//   let width = img.width;
		//   let height = img.height;
		//   const aspectRatio = width / height;

		//   // Adjust dimensions respecting aspect ratio and maximum size
		//   if (width > maxWidth) {
		//     width = maxWidth;
		//     height = width / aspectRatio;
		//   }
		//   if (height > maxHeight) {
		//     height = maxHeight;
		//     width = height * aspectRatio;
		//   }

		//   canvas.width = width;
		//   canvas.height = height;

		//   context.drawImage(img, 0, 0, width, height);

		//   canvas.toBlob((blob) => {
		//     const url = URL.createObjectURL(blob);
		//     resolve(url);
		//   }, 'image/jpeg', quality);
		// };

		// img.onerror = (error) => {
		//   reject(error);
		// };
	} catch (error) {
		reject(error);
	}
}
