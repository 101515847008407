import { useEffect, useState } from 'react';

const isEmptyObject = (obj) => {
	return Object.keys(obj).length === 0;
};

const MyVRWeather = ({ selectedForest }) => {
	const [weather, setWeather] = useState(null);

	useEffect(() => {
		const fetchWeatherData = async () => {
			try {
				if (!isEmptyObject(selectedForest)) {
					const weatherAPIURL = `https://api.openweathermap.org/data/2.5/weather?lat=${selectedForest.lat}&lon=${selectedForest.lon}&units=metric&appid=6175724b4bc9f4525567f74a609b03de`;
					const response = await fetch(weatherAPIURL);
					if (!response.ok) {
						throw new Error(`Error: ${response.status}`);
					}
					const result = await response.json();
					const { weather: weatherResult, dt, main } = result;
					const weatherData = {
						icon: `https://openweathermap.org/img/wn/${weatherResult[0].icon}@2x.png`,
						description: weatherResult[0].description,
						dateTime: new Date(dt * 1000).toLocaleString(),
						feels_like: main.feels_like,
						humidity: main.humidity,
						pressure: main.pressure,
						temp_max: main.temp_max,
						temp_min: main.temp_min,
						temp_current: main.temp,
					};
					setWeather(weatherData);
				}
			} catch (err) {
				// Error
			}
		};

		fetchWeatherData();
	}, [selectedForest]);

	if (!weather) {
		return null;
	}

	return (
		<div className="flex flex-col text-black rounded-lg w-full h-full overflow-auto">
			<div className=" flex flex-row space-x-20">
				<span className="text-white font-bold mt-3.5">
					Forest Live Stats
				</span>
				<img
					src={weather.icon}
					alt={weather.description}
					width="50"
					height="50"
				/>
			</div>
			<div className=" flex flex-row space-x-5">
				<span className="text-white font-bold">Description :</span>
				<span className=" text-green-500">{weather.description}</span>
			</div>
			<div className=" flex flex-row space-x-5">
				<span className="text-white font-bold">Temp :</span>
				<span className="text-green-500">{weather.temp_current}</span>
			</div>
			<div className=" flex flex-row space-x-5">
				<span className="text-white font-bold">Time :</span>
				<span className="text-green-500">{weather.dateTime}</span>
			</div>
			<div className=" flex flex-row space-x-5">
				<span className="text-white font-bold">Humidity :</span>
				<span className="text-green-500">{weather.humidity}</span>
			</div>
			<div className=" flex flex-row space-x-5">
				<span className="text-white font-bold">Max Temp :</span>
				<span className="text-green-500">{weather.temp_max}</span>
			</div>
			<div className=" flex flex-row space-x-5">
				<span className="text-white font-bold">Min Temp :</span>
				<span className="text-green-500">{weather.temp_min}</span>
			</div>
		</div>
	);
};

export default MyVRWeather;
