import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showModal } from './ReduxStore/Actions/ModalActions';
import { routeList } from './CommonComponents/Drawer/routeList';

const RouteChangeHandler = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		const route = routeList.find((r) => r.url === location.pathname);

		const completeRouteName = route?.name ? ` | ${route?.name}` : '';
		document.title = `GreenCubes${completeRouteName}`;

		// Close the modal when route changes
		dispatch(showModal(false));
	}, [location, dispatch]);

	return null;
};

export default RouteChangeHandler;
