import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, PersonAddAltOutlined } from '@mui/icons-material';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import TextToBool from '../../../components/grid/TextToBool';
import { useGridState } from '../../../components/hooks/useGridState';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';
import { setPolygons } from '../../ReduxStore/Actions/PoligonAction';

const ForestAreaList = (props) => {
	const {
		showModal: showModalAction,
		loginedInUserData,
		setPolygons: setPolygonsAction,
		polygons,
	} = props;
	const { assignedRole } = loginedInUserData?.data?.getLoggedInUserData;

	const {
		error,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateForestsData',
		onUpdateTable: 'onUpdateForestsData',
		fetchRecords: async () => {
			try {
				if (!polygons.length) {
					let filter_variables = {};

					if (assignedRole !== 'super-admin') {
						filter_variables = {};
					}

					const responseObject = await makeGraphQLRequest({
						query: queries.listForestsData,
						variables: filter_variables,
					});
					const items_list =
						responseObject?.data?.listForestsData?.items || [];
					onRecords(items_list);
					setPolygonsAction(items_list);
				} else {
					onRecords(polygons);
				}
			} catch (e) {
				onError(e);
			}
		},
		onData: (data, type) => {
			const newData =
				type === 'new'
					? [data, ...polygons]
					: polygons.map((item) => {
							if (item.id === data.id) {
								return data;
							}
							return item;
						});
			setPolygonsAction(newData);
		},
		useRedux: true,
	});

	const actions = [
		{
			id: 'add_forest',
			name: 'Add Forest Area',
			onClick: () => showModalAction({ value: true }),
			icon: <PersonAddAltOutlined />,
			primary: true,
		},
		{
			id: 'edit_forest',
			name: 'Edit Forest Area',
			onClick: () =>
				showModalAction({ value: true, selectedItem: selectedRows[0] }),
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'ForestName',
				headerName: 'Name',
				width: 150,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				pinned: 'left',
				filter: true,
			},
			{
				field: 'polygon_name',
				headerName: 'Plot Name',
				width: 160,
			},
			{
				field: 'lat',
				width: 150,
			},
			{
				field: 'lon',
				width: 150,
			},
			{
				field: 'NoOfCubes',
				width: 120,
			},
			{
				field: 'CubePrice',
				width: 120,
			},
			{
				field: 'Status',
				cellRenderer: TextToBool,
				width: 100,
			},
			{
				field: 'area_WEBGL_cubified_file_path',
				headerName: 'WEBGL Cubfied Path',
				flex: 1,
				autoHeight: true,
			},
			{
				field: 'area_MYVR_scene_file_path',
				headerName: 'MYVR Scene Path',
				flex: 1,
			},
			{
				field: 'createdBy',
				flex: 1,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Forests">
			<Grid
				refObject={gridRef}
				rowData={polygons}
				colDefs={colDefs}
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
	polygons: state.polygons,
});

export default connect(mapStateToProps, { showModal, setPolygons })(
	ForestAreaList
);
