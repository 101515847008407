export const AppThemeObject = {
	appThemeColor: '#ff0000',
	loadingIndicatorColor: '#ffffff',
	errorViewbgColor: '#ff0000',
	errorViewtextColor: '#ffffff',
	primary_container_bg_color: '#313131',

	primary_heading_text_color_green: '#9BE938',
	primary_heading_text_font_size: 30,
	secondary_heading_text_color_green: '#ffffff',
	secondary_heading_text_font_size: 12,

	primary_text_font_size: 12,
	primary_text_font_color: '#fff',
	secondary_text_font_size: 12,
	secondary_text_font_color: '#ffffff',

	button_bg_color: '#9BE938',
	button_bg_secondary_color: '#f5f5f5',
	button_text_color: '#000000',
	button_font_size: 12,
	button_font_weight: 'bold',
};
