import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { SearchIcon } from '@digitalreality/ui-icons';
import SubmitButton from './SubmitButton';

const containerStyle = {
	width: '100%',
	height: '400px',
};

const defaultCenter = {
	lat: 9.748916999999999,
	lng: -83.753428,
};

const MapComponent = ({
	onLocationSelect,
	onLocationCancel,
	center = defaultCenter,
}) => {
	const [selectedPosition, setSelectedPosition] = useState(center);
	const [selectedLocation, setSelectedLocation] = useState({
		country: '',
		placeName: '',
	});
	const searchBoxRef = useRef(null);
	const mapRef = useRef(null);

	const fetchLocationDetails = (lat, lng) => {
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ location: { lat, lng } }, (results, status) => {
			if (status === 'OK' && results[0]) {
				const addressComponents = results[0].address_components;
				const countryComponent = addressComponents.find((component) =>
					component.types.includes('country')
				);
				const placeComponent = addressComponents.find((component) =>
					component.types.includes('locality')
				);
				const countryName = countryComponent?.long_name || '';
				const placeName = placeComponent?.long_name || '';
				setSelectedLocation({ country: countryName, placeName });
			}
		});
	};
	const handleMapClick = useCallback((event) => {
		const lat = event.latLng.lat();
		const lng = event.latLng.lng();
		setSelectedPosition({ lat, lng });
		fetchLocationDetails(lat, lng);
	}, []);

	const handlePlacesChanged = useCallback(() => {
		const places = searchBoxRef.current.getPlaces();
		if (places.length) {
			const place = places[0];
			const lat = place.geometry.location.lat();
			const lng = place.geometry.location.lng();
			setSelectedPosition({ lat, lng });
			fetchLocationDetails(lat, lng);
		}
	}, []);

	const handleChoosePlace = () => {
		const { lat, lng } = selectedPosition;
		const { country, placeName } = selectedLocation;
		onLocationSelect({ lat, lng, placeName, country });
	};

	return (
		<>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={selectedPosition}
				options={{
					mapTypeControl: false,
					fullscreenControl: false,
					streetViewControl: false,
				}}
				zoom={10}
				ref={mapRef}
				onClick={handleMapClick}>
				<StandaloneSearchBox
					onLoad={(ref) => {
						searchBoxRef.current = ref;
					}}
					onPlacesChanged={handlePlacesChanged}>
					<div className="w-60 h-10 absolute left-1/2 top-2.5 -ml-[120px] shadow-lg">
						<input
							type="text"
							placeholder="Search location"
							className="box-border border border-solid border-transparent w-full h-full p-3 text-black rounded text-base outline-none text-ellipsis"
						/>
						<SearchIcon
							fontSize="small"
							className="absolute right-2.5 text-gray-400 top-1/2 -translate-y-2/4 pointer-events-none"
						/>
					</div>
				</StandaloneSearchBox>
				<Marker position={selectedPosition} />
			</GoogleMap>
			<div className="flex justify-end gap-2 items-center mt-2">
				<SubmitButton
					onClick={onLocationCancel}
					variant="secondary"
					text="Cancel"
				/>
				<SubmitButton
					onClick={handleChoosePlace}
					text="Choose Place"
				/>
			</div>
		</>
	);
};

export default MapComponent;
