import ExploreWidget from './ExploreWidget';

import { mapPinIcon } from '../../../../components/icons/icons';

const DistributionMap = ({ selectedAnimal }) => {
	return (
		<div className="w-full h-full relative">
			<ExploreWidget
				title="Distribution"
				icon={mapPinIcon}>
				<img
					className="w-full h-full object-cover relative"
					src={selectedAnimal.mapImage}
					alt="Camera Trap Map1"
				/>
				<div className="flex absolute flex-col bg-[#1E1E21] left-4 bottom-4 z-10 p-[5px] gap-[10px]">
					<span
						className="uppercase font-bold"
						style={{ fontSize: 'clamp(0.4rem,1vw,1rem)' }}>
						Distribution
					</span>
					<div className="flex gap-2">
						<div className="h-4 w-4 bg-[#B0730C] max-md:h-1 max-md:w-1" />
						<span
							className="text-[#BFBFBF] text-[10px]"
							style={{ fontSize: 'clamp(0.4rem,1vw,0.7rem)' }}>
							EXTANT (resident)
						</span>
					</div>
					<div className="flex gap-2">
						<div className="h-4 w-4 bg-[#713B55]  max-md:h-1 max-md:w-1" />
						<span
							className="text-[#BFBFBF] text-[10px]"
							style={{ fontSize: 'clamp(0.4rem,1vw,0.7rem)' }}>
							POSSIBLE EXTANT (resident)
						</span>
					</div>
				</div>
			</ExploreWidget>
		</div>
	);
};

export default DistributionMap;
