import React, { useRef, useEffect, useState } from 'react';
import { Riple } from 'react-loading-indicators'; // Assuming Riple is the correct loading indicator component
import { IconButton } from '@digitalreality/ui';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const temp_hardcodedObject = {
	'La Gamba Tropenstation':
		'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/Lagamba.mov',
	Ibiti: 'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/IBITpointcloud.mov',
	'Reclamation mine':
		'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/MacPointCloud.mp4',
};

const getURL = (forestName) => {
	let video_url =
		'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/Lagamba.mov';
	if (forestName) {
		video_url =
			temp_hardcodedObject[forestName] ||
			'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/Lagamba.mov';
	}
	return video_url;
};

const VideoPlayer = (props) => {
	const { selectedForest } = props;
	const { forestName } = selectedForest || {};
	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isLoading, setIsLoading] = useState(true); // Loading state

	useEffect(() => {
		const videoElement = videoRef.current;

		// Handle the video being loaded initially
		const handleLoadedData = () => {
			setIsLoading(false);
		};

		// Handle the seek (fast forward/rewind) completion
		const handleSeeked = () => {
			setIsLoading(false);
		};

		videoElement.addEventListener('loadeddata', handleLoadedData);
		videoElement.addEventListener('seeked', handleSeeked);

		return () => {
			videoElement.removeEventListener('loadeddata', handleLoadedData);
			videoElement.removeEventListener('seeked', handleSeeked);
		};
	}, []);

	const handlePlayPause = () => {
		if (videoRef.current.paused) {
			videoRef.current.play();
			setIsPlaying(true);
		} else {
			videoRef.current.pause();
			setIsPlaying(false);
		}
	};

	const handleForward = () => {
		if (videoRef.current) {
			setIsLoading(true); // Show loading when skipping forward
			videoRef.current.currentTime += 10;
		}
	};

	const handleBackward = () => {
		if (videoRef.current) {
			setIsLoading(true); // Show loading when skipping backward
			videoRef.current.currentTime -= 10;
		}
	};

	return (
		<div className="relative w-full h-screen bg-black">
			{isLoading && (
				<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black">
					<Riple
						color="#0cc50c"
						size="large"
						text="Loading"
						textColor="#0deb30"
					/>
				</div>
			)}
			<video
				ref={videoRef}
				className="w-full h-full object-cover"
				src={getURL(forestName)}
				controls={false}
				autoPlay={false} // Disable autoplay
				muted={false}
			/>
			{!isLoading && (
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100px',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						bottom: 0,
					}}>
					<div className="flex flex-row bg-[#000] h-15 rounded-full p-2 mb-40">
						<IconButton onClick={handleBackward}>
							<SkipPreviousIcon
								sx={{ color: '#fff', fontSize: '36px' }}
							/>
						</IconButton>

						{!isPlaying && (
							<IconButton onClick={handlePlayPause}>
								<PlayArrowIcon
									sx={{ color: '#fff', fontSize: '40px' }}
								/>
							</IconButton>
						)}

						{isPlaying && (
							<IconButton onClick={handlePlayPause}>
								<PauseIcon
									sx={{ color: '#fff', fontSize: '40px' }}
								/>
							</IconButton>
						)}

						<IconButton onClick={handleForward}>
							<SkipNextIcon
								sx={{ color: '#fff', fontSize: '36px' }}
							/>
						</IconButton>
					</div>
					{/* <div className="flex w-80 h-50 mb-40 ml-40 bg-[#ff0000]">
						<img
							src={HXDRImage}
							alt="Description"
							style={{ width: '246px', height: '56px' }}
						/>
					</div> */}
				</div>
			)}
		</div>
	);
};

export default VideoPlayer;
