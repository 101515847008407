import ForestForm from './ForestForm';
import ForestList from './ForestList';

const Forest = () => {
	return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<ForestForm />
			<ForestList />
		</div>
	);
};

export default Forest;
