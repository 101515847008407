import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import Globe from 'react-globe.gl';
import MapViewCustomTilesPolygons from './MapViewCustomTilesPolygons';

const GlobeOwn = forwardRef(
	(
		{
			forest_data = [],
			polygons_data = [],
			selectedForest = {},
			// forest_polygons_geoJson = 'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/GreenCubesGlobePolygons.json',
			onGlobeReadyCallback = () => {},
			handleGlobeMovements = () => {},
			handlecallbackActions = () => {},
			isPolygonsVisible = false,
		},
		ref
	) => {
		const globeRef = useRef();
		const animation_period = 1000;

		useImperativeHandle(ref, () => ({
			moveToLocation: (lat, lng) => {
				if (globeRef.current) {
					globeRef.current.pointOfView(
						{ lat, lng },
						animation_period
					);
				}
			},
			zoomToLocation: (lat, lng, altitude) => {
				if (globeRef.current) {
					globeRef.current.pointOfView(
						{ lat, lng, altitude },
						animation_period
					);
				}
			},
		}));

		const handleGlobeReady = () => {
			onGlobeReadyCallback();
			const forest = forest_data[0];
			if (forest) {
				globeRef.current.pointOfView(
					{
						lat: forest.lat,
						lng: forest.lng,
						altitude: 1.5,
					},
					2000
				);
			}
		};

		const handle_html_country_click = (forest_object) => {
			setTimeout(() => {
				handlecallbackActions('MovetoMultiPolygons', forest_object, 1);
			}, animation_period);
			ref.current.zoomToLocation(
				forest_object.lat,
				forest_object.lng,
				0.08
			);
		};

		// ${d.ForestCountry}
		const markerSvg = (d) => {
			return `<div style="display: flex; align-items: center; justify-content: flex-start; border: 0px solid #ddd; padding: 10px; width: 400px; font-family: Arial, sans-serif;margin-left:370px;">
            <img src=${d.project_icon_path} alt="Icon" style="width: 20px; height: 20px; margin-right: 10px; object-fit: cover; border-radius: 20px;background-color:#fff">
            <div style="width:400px; display: flex; flex-direction:row; align-items: flex-start;">
                <div style="font-size: 16px; color: #fff;font-family: Roboto;font-weight: bold;">${d.forestName}</div> 
				<div style="font-size: 12px; color: #fff;font-family: Roboto;margin-top:4px">, ${d.ForestCountry}</div>
            </div>
        </div>`;
		};

		return (
			<div
				style={{ position: 'relative', width: '100%', height: '100%' }}>
				<div
					style={{
						position: 'relative',
						width: '100%',
						height: '100%',
					}}>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: '#000',
							zIndex: 1,
						}}>
						<Globe
							ref={globeRef}
							globeImageUrl="https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/cloudyEarth.jpg"
							backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
							atmosphereColor="#fff"
							onGlobeReady={handleGlobeReady}
							htmlElementsData={forest_data}
							htmlElement={(d) => {
								const el = document.createElement('div');
								el.innerHTML = markerSvg(d);
								el.style.color = d.color;
								el.style.width = `${d.size}px`;
								el.style.position = 'absolute';
								el.style['pointer-events'] = 'auto';
								el.style.cursor = 'pointer';
								el.onclick = () => handle_html_country_click(d);
								return el;
							}}
						/>
					</div>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							display: isPolygonsVisible ? 'flex' : 'none',
							alignItems: 'center',
							justifyContent: 'center',
							color: '#000',
							zIndex: 2,
						}}>
						<MapViewCustomTilesPolygons
							handleGlobeMovements={handleGlobeMovements}
							polygons_data={polygons_data}
							handlecallbackActions={handlecallbackActions}
							selectedForest={selectedForest}
						/>
					</div>
				</div>
			</div>
		);
	}
);

export default GlobeOwn;
