import PurchaseList from './PurchaseList';

const Purchase = () => {
	return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<PurchaseList />
		</div>
	);
};

export default Purchase;
