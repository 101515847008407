import { useContext, useState } from 'react';
import { upNavIcon, downNavIcon } from '../../../../components/icons/icons';
import { monthlyAudioCapture } from '../common/images';
import SpeciesInfo from '../common/SpeciesInfo';
import MusicPlayer from '../common/MusicPlayer';
import DistributionMap from '../common/DistributionMap';
import AnimalStatus from '../common/AnimalStatus';
import AnimalShow from '../common/AnimalShow';
import ForestContext from '../utils/ForestContext';

const AudioCaptureLayout = () => {
	const { audioCaptureItems, audioTrapItems } = useContext(ForestContext);
	const [selectedItem, setSelectedItem] = useState(0);
	const selectedAnimal = audioCaptureItems[selectedItem];
	return (
		<div className="flex flex-col flex-1 bg-[#131314] h-full w-full justify-between gap-[10px] overflow-hidden">
			<div className="flex gap-[10px] h-[43%]">
				<div className="w-2/3 h-full rounded-[5px] overflow-hidden relative">
					<AnimalShow selectedAnimal={selectedAnimal} />
					<div className="absolute bottom-4 right-4">
						<MusicPlayer
							key={selectedAnimal.id}
							src={selectedAnimal.audioFile}
						/>
					</div>
				</div>

				<div className="w-1/3 h-full">
					<DistributionMap selectedAnimal={selectedAnimal} />
				</div>
			</div>
			<div className="flex gap-[10px] h-[15%]">
				<div className="w-2/3 h-full ">
					<SpeciesInfo selectedAnimal={selectedAnimal} />
				</div>
				<div className="w-1/3 h-full">
					<AnimalStatus />
				</div>
			</div>
			<div className="flex gap-[10px] h-full overflow-hidden">
				<div className="w-1/6 h-full flex flex-col gap-[10px] bg-[#1E1E21] rounded-[5px] p-4 shrink-0">
					<span
						className="font-bold uppercase"
						style={{ fontSize: 'clamp(8px,2vw,16px)' }}>
						Captures
					</span>
					<img
						className="object-contain h-full overflow-hidden"
						src={monthlyAudioCapture}
						alt="Monthly Audio Capture"
					/>
				</div>
				<div className="flex flex-col bg-[#1E1E21] rounded-[5px] gap-2 overflow-hidden h-full p-2 flex-1">
					<div className="flex flex-col">
						<span
							className="uppercase font-bold text-[16px]"
							style={{ fontSize: 'clamp(8px,2vw,16px)' }}>
							audio trap
						</span>
						<span
							className="uppercase text-[10px]"
							style={{ fontSize: 'clamp(5px,2vw,10px)' }}>
							Cam lg01
						</span>
					</div>
					<div className="flex flex-row justify-around flex-1 p-4 px-16 overflow-hidden w-full">
						<div className="flex items-center justify-end gap-4 w-[30%] h-full overflow-hidden">
							{audioCaptureItems.map((item) => (
								<div
									key={item.id}
									className="flex flex-col h-full justify-center"
									style={{
										display:
											selectedAnimal.id === item.id
												? 'flex'
												: 'none',
									}}>
									<img
										src={item.thumbnail}
										alt={item.title}
										className="h-4/5 object-cover"
									/>
									<span className="font-bold text-base">
										{item.title}
									</span>
									<span className="text-[10px] italic">
										{item.subTitle || ' '}
									</span>
								</div>
							))}
						</div>
						<div className="flex flex-col gap-3 w-[30%] items-center">
							<span
								role="button"
								tabIndex="0"
								onKeyDown={() => {}}
								aria-label="Span"
								className="cursor-pointer"
								onClick={() =>
									setSelectedItem((prevSelectedItem) =>
										prevSelectedItem + 1 >
										audioCaptureItems.length - 1
											? 0
											: prevSelectedItem + 1
									)
								}
								dangerouslySetInnerHTML={{
									__html: upNavIcon,
								}}
							/>
							<div className="flex flex-col justify-center items-center w-full h-full">
								<span
									className="text-[36px] leading-[36px] font-light"
									style={{
										fontSize: 'clamp(16px,3vw,36px)',
									}}>
									56
								</span>
								<span
									className="font-bold text-xs"
									style={{ fontSize: 'clamp(5px,2vw,10px)' }}>
									Species
								</span>
								<span
									className="font-bold text-xs"
									style={{ fontSize: 'clamp(5px,2vw,10px)' }}>
									Recordings
								</span>
							</div>
							<span
								role="button"
								tabIndex="0"
								onKeyDown={() => {}}
								aria-label="Span"
								className="cursor-pointer"
								onClick={() =>
									setSelectedItem((prevSelectedItem) =>
										prevSelectedItem - 1 < 0
											? audioCaptureItems.length - 1
											: prevSelectedItem - 1
									)
								}
								dangerouslySetInnerHTML={{
									__html: downNavIcon,
								}}
							/>
						</div>
						<div className="flex flex-col overflow-auto flex-1">
							{audioTrapItems.map((item) => (
								<div
									key={item.name}
									className={`flex gap-5 items-center ${selectedAnimal.title === item.name ? 'text-[#87E78B] font-bold' : ''}`}>
									<span
										className="w-1/2 text-[10px]"
										style={{
											fontSize: 'clamp(8px,2vw,10px)',
										}}>
										{item.name}
									</span>
									<span
										className="text-[10px]"
										style={{
											fontSize: 'clamp(5px,2vw,10px)',
										}}>
										{item.scientificName}
									</span>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AudioCaptureLayout;
