import React, { useEffect, useState } from 'react';

const AboutForestPage = ({ htmlFilePath }) => {
	const [htmlContent, setHtmlContent] = useState('');

	useEffect(() => {
		fetch(htmlFilePath)
			.then((response) => response.text())
			.then((data) => setHtmlContent(data));
	}, [htmlFilePath]);

	return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default AboutForestPage;
