import { SET_POLYGONS } from './types';

const setPolygons = (payload) => {
	return {
		type: SET_POLYGONS,
		payload,
	};
};

export { setPolygons };
