import { useContext } from 'react';
import { IconButton, Tooltip } from '@digitalreality/ui';
import { StyleContext } from '../../Pages/ContextThemes/StyleContext';

export const GridActions = ({ actions }) => {
	const contextStyles = useContext(StyleContext);

	return (
		<div className="flex w-full flex-row-reverse items-center gap-3">
			{actions.map((item) => {
				return (
					<Tooltip
						key={item.id}
						title={item.name}>
						<span className="cursor-pointer">
							<IconButton
								disabled={item.disabled}
								onClick={item.onClick}
								sx={{
									color: '#000000',
									backgroundColor: item.primary
										? contextStyles.button_bg_color
										: contextStyles.button_bg_secondary_color,
									':hover': {
										color: '#ffffff',
									},
									':disabled': {
										backgroundColor:
											'rgba(255, 255, 255, 0.1)',
									},
								}}>
								{item.icon}
							</IconButton>
						</span>
					</Tooltip>
				);
			})}
		</div>
	);
};
