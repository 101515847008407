import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as mutations from '../../../graphql/mutations';

import FormModal from '../../../components/base/FormModal';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
// eslint-disable-next-line import/no-cycle
import { permissionsList } from '../../CommonComponents/Drawer/routeList';
import * as queries from '../../../graphql/queries';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const formatSelectedItem = (selectedItem) => {
	if (!selectedItem) return selectedItem;
	const newSelectedItem = {
		...selectedItem,
		permissions:
			selectedItem?.permissions?.map((permission) =>
				permissionsList.find((item) => item.value === permission)
			) || [],
	};

	return newSelectedItem;
};

const RolesForm = (props) => {
	const {
		modalStatus,
		loginedInUserData,
		showModal: showModalAction,
	} = props;
	const { selectedItem } = modalStatus || {};
	const updatedSelectedItem = useMemo(
		() => formatSelectedItem(selectedItem),
		[selectedItem]
	);
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, updatedSelectedItem);

	const formFields = [
		{
			id: 'roleName',
			label: 'Enter name of the role',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('roleName', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'permissions',
			label: 'Permissions',
			type: 'multiSelectAuto',
			items: permissionsList,
			placeholder: 'Access to',
			onChange: useCallback(
				(newValue) => onChange('permissions', newValue),
				[onChange]
			),
		},
	];

	const handleSubmit = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			loginedInUserData?.data?.getLoggedInUserData;
		try {
			const data = {
				...reducedData,
				permissions: reducedData?.permissions?.map(
					(permission) => permission.value
				),
			};
			if (!isEdit) {
				data.id = data.roleName;
				data.createdBy = loginedInUserData.attributes.email;
				data.status = true;
			} else {
				data.id = newState.id.value;
			}

			data.audit_client_msg = `${clientId}@@${emailId}`;

			// Validation code start//
			if (!isEdit) {
				const validation_result = await makeGraphQLRequest({
					query: queries.duplicateCheck,
					variables: {
						tableName: 'rolesTable',
						columns: ['roleName'],
						enteredvalues: [data.roleName],
					},
				});
				if (!validation_result) return;
			}
			// Validation code end//

			await makeGraphQLRequest({
				query: isEdit
					? mutations.updateRolesTable
					: mutations.createRolesTable,
				variables: { input: data },
			});
			setFormStatus(true);
		} catch (e) {
			onError(e);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		showModalAction({ value: false });
	};

	if (!modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handleSubmit(!!selectedItem)}
			isLoading={isLoading}
			title={`${selectedItem ? 'Update' : 'Add'} Role`}
			error={error}
			formStatus={formStatus}
			formFields={formFields}
			formData={formData}
			isEdit={!!selectedItem}
			rowSelection="single"
		/>
	);
};
const mapStateToProps = (state) => ({
	modalStatus: state.modalStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(RolesForm);
