import { useContext } from 'react';
import { SubmitButton as DRSubmitButton } from '@digitalreality/ui';
import { getStyles } from './SubmitButton.styles';
import { StyleContext } from '../../Pages/ContextThemes/StyleContext';

const SubmitButton = (props) => {
	const { disabled, isLoading, text, onClick, endIcon, variant } = props;

	const contextStyles = useContext(StyleContext);
	const styles = getStyles(contextStyles, variant);
	const loginBackground =
		isLoading || disabled
			? { backgroundColor: 'gray', color: 'white' }
			: {};

	return (
		<DRSubmitButton
			endIcon={endIcon}
			loading={isLoading}
			disabled={disabled || isLoading}
			loadingPosition="end"
			style={{ ...styles.buttonStyle, ...loginBackground }}
			onClick={onClick}>
			{text}
		</DRSubmitButton>
	);
};

export default SubmitButton;
