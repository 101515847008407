import { Storage } from 'aws-amplify';

import * as fincaAmableData from './data-lagamba-finca-amable';
import * as reclamationMineData from './data-reclamation-mine';
import * as ibitiData from './data-ibiti';
import * as alcoaData from './data-alcoa';

const getStorageUrls = async (items) => {
	const result = await Promise.all(
		items.map(async (obj) => {
			try {
				const media = await Storage.get(obj.key);
				return media;
			} catch (error) {
				return undefined;
			}
		})
	);
	return result;
};

async function getMediaFiles(folderName) {
	try {
		const result = await Storage.list(folderName);
		const items = result.results;
		const storageUrls = await getStorageUrls(items);
		return storageUrls;
	} catch (error) {
		return [];
	}
}

const getData = (selectedPolygon) => {
	if (selectedPolygon.polygon_name === 'Finca Amable') {
		return fincaAmableData;
	}

	if (selectedPolygon.polygon_name === 'Ibiti restoration site') {
		return ibitiData;
	}

	if (selectedPolygon.polygon_name === 'Mining Restoration Site') {
		return reclamationMineData;
	}
	if (selectedPolygon.polygon_name === 'Alcoa') {
		return alcoaData;
	}

	return {};
};

export { getMediaFiles, getData };
