import React from 'react';
import { connect } from 'react-redux';
import { Riple } from 'react-loading-indicators';
import DrawerLists from '../../CommonComponents/Drawer/DrawerLists';

const Layout = (props) => {
	const { children, appLoadingIndicator } = props;
	return (
		<div className="flex h-screen w-screen overflow-hidden relative">
			{appLoadingIndicator && (
				<div className="absolute flex items-center justify-center top-0 left-0 w-full h-full z-[1499] bg-[#262626]/[.6]">
					<Riple
						color="#0cc50c"
						size="large"
						text="Loading"
						textColor="#0deb30"
					/>
				</div>
			)}
			<div className="flex w-full h-full">
				<DrawerLists />
				<div className="flex-1 overflow-hidden">{children}</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	appLoadingIndicator: state.appLoadingIndicator,
});

export default connect(mapStateToProps)(Layout);
