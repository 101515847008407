import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useState, useRef, useEffect } from 'react';

import * as subscriptions from '../../graphql/subscriptions';

export const useGridState = ({
	onCreateTable,
	onUpdateTable,
	fetchRecords,
	onData,
	useRedux,
} = {}) => {
	const [error, setError] = useState('');
	const [rowData, setRowData] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const gridRef = useRef();

	useEffect(() => {
		let create_subscription;
		let update_subscription;

		if (onCreateTable) {
			create_subscription = API.graphql(
				graphqlOperation(subscriptions[onCreateTable])
			).subscribe({
				next: (eventData) => {
					if (useRedux) {
						onData(eventData.value.data[onCreateTable], 'new');
					} else {
						setRowData((prevItems) => [
							eventData.value.data[onCreateTable],
							...prevItems,
						]);
					}
				},
			});
		}

		if (onUpdateTable) {
			update_subscription = API.graphql(
				graphqlOperation(subscriptions[onUpdateTable])
			).subscribe({
				next: (eventData) => {
					const updatedItem = eventData.value.data[onUpdateTable];
					if (useRedux) {
						onData(updatedItem, 'update');
					} else {
						setRowData((prevItems) => {
							const newItems = prevItems.map((item) => {
								if (item.id === updatedItem.id) {
									return updatedItem;
								}
								return item;
							});

							return newItems;
						});
					}
				},
			});
		}
		return () => {
			if (onCreateTable) create_subscription.unsubscribe();
			if (onUpdateTable) update_subscription.unsubscribe();
		};
	}, [onCreateTable, onUpdateTable, onData, useRedux]);

	const onSelectionChanged = useCallback((params) => {
		const selectedItems = params.api.getSelectedRows();
		setSelectedRows(selectedItems);
	}, []);

	const onError = useCallback((e) => {
		let errorMsg = e.message;

		if (!errorMsg) {
			errorMsg = e?.errors?.[0]?.message;
		}
		setError(errorMsg);
		gridRef.current?.api?.hideOverlay();
	}, []);

	const onRecords = useCallback(
		(items) => {
			if (!useRedux) {
				setRowData(items);
			}

			if (!items.length) {
				gridRef.current.api.showNoRowsOverlay();
			} else {
				gridRef.current.api.hideOverlay();
			}
		},
		[useRedux]
	);

	const onGridReady = useCallback(
		(params) => {
			params.api.showLoadingOverlay();

			if (fetchRecords) {
				fetchRecords();
			}
		},
		[fetchRecords]
	);

	return {
		error,
		rowData: rowData.sort((a, b) =>
			b?.createdAt?.localeCompare(a?.createdAt)
		),
		selectedRows,
		setError,
		setRowData,
		setSelectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	};
};
