import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@digitalreality/ui';
import { ChevronLeft, ChevronRight, LogoutOutlined } from '@mui/icons-material';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { Auth, Storage } from 'aws-amplify';
import GreenCubesLogo from '../../../Images/GreenCubes_CombineLogo.png';
import { loggedInUserData } from '../../ReduxStore/Actions/CommonActions';
import { routeList, getDrawerListByPermissions } from './routeList';
import Icon from '../../../utils/Icon';

const DrawerLists = (props) => {
	const { loginedInUserData, loggedInUserData: loggedInUserDataAction } =
		props;
	const { firstName, profilePicURL, accessLevel } =
		loginedInUserData?.data?.getLoggedInUserData || {};
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [selectedText, setselectedText] = useState('');
	const [profilePic, setprofilePic] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		const syncLogout = (event) => {
			if (event.key === 'logout') {
				// If a logout event is detected, sign the user out of all browsers
				Auth.signOut();
				window.location.href = '/';
			}
		};
		window.addEventListener('storage', syncLogout);
		return () => {
			window.removeEventListener('storage', syncLogout);
		};
	}, []);

	useEffect(() => {
		const item = routeList.find(
			(ele) => ele.url === window.location.pathname
		);

		if (item) {
			setselectedText(item.name);
		}
	}, []);

	useEffect(() => {
		const resizeEvent = new Event('resize');
		setTimeout(() => {
			window.dispatchEvent(resizeEvent);
		}, 310);
	}, [isDrawerOpen]);

	useEffect(() => {
		const getProfilePic = async () => {
			try {
				const imageAccessURL = await Storage.get(profilePicURL);
				setprofilePic(imageAccessURL);
			} catch (error) {
				// console.log('error signing out: ', error);
			}
		};

		getProfilePic();
	}, [profilePicURL]);

	const handleDrawer = () => {
		setIsDrawerOpen((open) => !open);
	};

	const handleLogout = async () => {
		try {
			await Auth.signOut();
			localStorage.setItem('logout', Date.now());
			navigate('/');
			loggedInUserDataAction({});
		} catch (error) {
			// console.log(error);
		}
	};

	const handleSelectedItem = (child) => {
		setselectedText(child.name);
		navigate(child.url);
	};

	const filteredDrawerList = useMemo(
		() => getDrawerListByPermissions(accessLevel?.permissions || []),
		[accessLevel?.permissions]
	);

	return (
		<div
			className="flex flex-col bg-[#313131] max-w-full mr-0 relative"
			style={{
				transition: 'width 0.3s ease-in-out',
				width: isDrawerOpen ? '20vw' : '0px',
				maxWidth: '240px',
			}}>
			<div
				role="button"
				tabIndex="0"
				onKeyDown={() => {}}
				className="absolute top-2/4 bg-gradient-to-b from-green-500 to-green-800 right-0 -mr-3 -mb-3 rounded-r-lg py-6 bg-[#313131] w-3 h-3 flex justify-center items-center cursor-pointer text-white px-1 z-50"
				onClick={handleDrawer}>
				{isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
			</div>
			<div className="p-4 max-md:p-2">
				<img
					src={GreenCubesLogo}
					alt="GreenCubes"
					className="object-contain w-20 h-10 sm:w-28 sm:h-12 md:w-36 md:h-14 lg:w-40 lg:h-[50px]"
				/>
			</div>
			<div className="bg-gradient-to-r from-green-500 to-green-800 h-[0.5px]" />
			<div
				className={`flex flex-col ${isDrawerOpen ? 'gap-4' : 'gap-2'} h-screen overflow-auto bg-[#313131] overflow-y-auto pt-4`}>
				{filteredDrawerList.map((item) => (
					<div
						key={item.title}
						className="flex flex-col max-md:gap-y-1  gap-y-2 pl-4 pr-4">
						{isDrawerOpen && (
							<span
								className="font-bold"
								style={{
									fontSize: 'clamp(0.5rem,2vw,1rem)',
								}}>
								{item.title}
							</span>
						)}
						{item.items.map((child) => (
							<div
								role="button"
								tabIndex="0"
								onKeyDown={() => {}}
								key={child.name}
								className={`flex items-center gap-x-2 p-[10px] max-md:p-[5px] cursor-pointer rounded-md hover:bg-gray-700 hover:opacity-70 ${selectedText === child.name ? 'bg-green-600 text-white hover:text-white' : ''}`}
								onClick={() => handleSelectedItem(child)}
								style={{
									justifyContent: isDrawerOpen
										? 'flex-start'
										: 'center',
								}}>
								<Icon>
									<Tooltip
										placement="right"
										title={child.name}
										className="flex flex-col items-center w-full">
										{child.iconComponent}
									</Tooltip>
								</Icon>
								{isDrawerOpen && (
									<ul
										className="overflow-hidden text-ellipsis"
										style={{
											fontSize: 'clamp(0.5rem,2vw,1rem)',
										}}>
										{child.name}
									</ul>
								)}
							</div>
						))}
					</div>
				))}
			</div>
			{isDrawerOpen && (
				<div className="flex flex-col items-center justify-center mb-3 mt-3 gap-3">
					<div className="flex items-center gap-2 mx-1 p-2 rounded-full w-11/12 bg-[#9BE938]">
						<Avatar
							alt="Remy Sharp"
							src={profilePic}
							sx={{
								width: { sm: 20, md: 26 },
								height: { sm: 20, md: 26 },
								borderRadius: '50%',
							}}
						/>
						{isDrawerOpen && (
							<span
								className="overflow-hidden text-ellipsis text-black"
								style={{
									fontSize: 'clamp(0.8rem, 1vw, 1rem)',
								}}>
								{firstName}
							</span>
						)}
					</div>
					{isDrawerOpen ? (
						<Button
							color="secondary"
							variant="outlined"
							sx={{
								borderRadius: 40,
								width: '91.7%',
							}}
							endIcon={<LogoutOutlined />}
							onClick={handleLogout}>
							<span>Logout</span>
						</Button>
					) : (
						<IconButton
							color="secondary"
							sx={{
								borderRadius: 40,
								width: 30,
								height: 30,
							}}
							onClick={handleLogout}>
							<LogoutOutlined />
						</IconButton>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { loggedInUserData })(DrawerLists);
