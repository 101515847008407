import { useState } from 'react';
import {
	dashboardIcon1,
	greenCubesIcon,
	reportIcon,
	sugarCubesIcon,
} from '../../../components/icons/icons';
import Icon from '../../../utils/Icon';

const SponsorForestSite = (props) => {
	const { callbackHandler, selectedPolygon } = props;
	const [selectedItem, setSelectedItem] = useState(null);

	const siteOptions = [
		{
			id: 'Explore',
			onClick: () => callbackHandler('showExperience'),
			icon: dashboardIcon1,
		},
		{
			id: 'Sponsor',
			onClick: () => callbackHandler('showSponsor'),
			icon: sugarCubesIcon,
		},
		{
			id: 'Baseline Report',
			onClick: () => callbackHandler('showForestBaseline'),
			icon: reportIcon,
		},
	];

	return (
		<div className="flex flex-col items-center flex-1">
			<div className="flex flex-col justify-center items-center w-full gap-1">
				<span
					className="text-center font-light max-md:px-[5px] px-2"
					style={{ fontSize: 'clamp(1rem,3vw,2.25rem)' }}>
					{selectedPolygon?.polygon_name}
				</span>
				<div className="flex items-center justify-center gap-2">
					{/* <img
						src={GreenCubeMiniLogo}
						alt=""
						className="w-5 h-5 object-contain"
					/> */}
					<span
						style={{
							width: 'clamp(1.25rem,1.5vw,2.25rem)',
							height: 'clamp(1.25rem,1.5vw,2.25rem)',
						}}
						dangerouslySetInnerHTML={{
							__html: greenCubesIcon,
						}}
					/>
					<span
						className="font-normal"
						style={{ fontSize: 'clamp(0.5rem,1.5vw,1rem)' }}>
						{/* {formatNumber(selectedPolygon?.NoOfCubes)} Cubes */}
						2.8 M Active cubes
					</span>
				</div>
			</div>
			<div className="flex flex-col items-center w-full py-16 max-md:py-4">
				{siteOptions.map((option) => {
					return (
						<div
							role="button"
							tabIndex="0"
							onKeyDown={() => {}}
							aria-label="Span"
							key={option.id}
							className={`w-full hover:bg-[#D7F4B1] hover:text-black border-b px-10 max-md:px-[10px] p-[10px] cursor-pointer flex gap-4 items-center ${
								selectedItem === option.id
									? 'bg-[#D7F4B1] text-black'
									: ''
							}`}
							onClick={() => {
								setSelectedItem(option.id);
								option.onClick();
							}}>
							<Icon>
								<span
									style={{
										width: 'clamp(1rem,1.5vw,2rem)',
										height: 'clamp(1rem,1.5vw,2rem)',
									}}
									className="flex justify-center items-center"
									dangerouslySetInnerHTML={{
										__html: option.icon,
									}}
								/>
							</Icon>
							<span
								className="capitalize"
								style={{
									fontSize: 'clamp(0.5rem,1.5vw,1rem)',
								}}>
								{option.id}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SponsorForestSite;
