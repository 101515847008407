/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';

import 'mapbox-gl/dist/mapbox-gl.css';

const greencubes_polygon =
	'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/GreenCubesGlobePolygons.json';

const temp_hardcodedObject = {
	'La Gamba':
		'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/GreenCubesGlobePolygons.json',
	Ibiti: 'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/GreenCubesGlobePolygons_Ibiti.json',
	'Reclamation mine':
		'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/GreenCubesGlobePolygons_ReclamationMine.json',
};

const MapViewCustomTilesPolygons = ({
	handleGlobeMovements = () => {},
	handlecallbackActions = () => {},
	polygons_data = [],
	selectedForest = {},
}) => {
	const mapContainerRef = useRef();
	const mapRef = useRef();

	const [map, setMap] = useState(null);
	const [mapCenter, setmapCenter] = useState([
		-83.1593741610754, 8.69426322357377,
	]);
	const [polygonsList, setpolygonsList] = useState([]);
	useEffect(() => {
		console.log('selected forest>>>>', selectedForest.forestName);
		if (selectedForest && selectedForest.forestName) {
			const forest_polygon_url =
				temp_hardcodedObject[selectedForest.forestName] ||
				greencubes_polygon;
			fetch(forest_polygon_url)
				.then((res) => res.json())
				.then((json) => {
					const features_list = json.features;
					const polygons_list = [];
					features_list.map((item, index) => {
						const polygon_item = {};
						const polygon_coordinates =
							item.geometry.coordinates[0].map((coord) => ({
								lat: coord[1],
								lng: coord[0],
							}));
						const only_polygon_coordinates = [
							item.geometry.coordinates[0].map((coord) => [
								coord[0],
								coord[1],
							]),
						];
						polygon_item.id = item.id;
						polygon_item.type = item.geometry.type;
						polygon_item.name = item.properties.Name || 'NA';
						polygon_item.strokeColor =
							item.properties.stroke || '#FFFF00';
						polygon_item.strokeWidth =
							item.properties['stroke-width'] || 3;
						polygon_item.coordinates = polygon_coordinates;
						polygon_item.only_polygon_coordinates =
							only_polygon_coordinates;
						polygon_item.fillColor =
							item.properties.fillColor || '#536456';
						polygon_item.fillColorOpacity =
							item.properties.fillColorOpacity || 0.5;
						polygon_item.fillColorOnMouseOver =
							item.properties.fillColorOnMouseOver || '#536456';
						polygon_item.fillcolorMouseOveropacity =
							item.properties.fillcolorMouseOveropacity || 0.35;
						polygon_item.strokeOpacity =
							item.properties.strokeOpacity || 0.5;
						polygons_list.push(polygon_item);
					});
					handlecallbackActions(
						'geoJSONDataForSelectedForest',
						polygons_list,
						0
					);
					calculateMapCenter(polygons_list);
					setpolygonsList(polygons_list);
				});
		}
	}, [selectedForest]);
	useEffect(() => {
		mapboxgl.accessToken =
			'pk.eyJ1Ijoic3Jpbml2YXNhbmFtYnVyaSIsImEiOiJjbTJpdWF2bXkwMXM4MmtxdDc5Nmh6OGVhIn0.iz4-yqZ__o9XKCqOj4Gn7w';

		mapRef.current = new mapboxgl.Map({
			container: 'map',
			zoom: 13,
			minZoom: 10,
			maxZoom: 20,
			center: mapCenter,
			style: 'mapbox://styles/mapbox/satellite-v9',
			pitch: 60,
			// pitch: 80,
			// bearing: 41,

			attributionControl: true, // Optional: Show attribution
		});

		mapRef.current.on('style.load', () => {
			// Adding DEM for terrain
			mapRef.current.addSource('mapbox-dem', {
				type: 'raster-dem',
				url: 'mapbox://mapbox.mapbox-terrain-rgb',
				tileSize: 512,
				maxzoom: 14,
			});
			mapRef.current.setTerrain({
				source: 'mapbox-dem',
				exaggeration: 2.5,
			});

			// Hide label layers (symbol layers)
			const layers = mapRef.current.getStyle().layers;
			layers.forEach((layer) => {
				if (layer.type === 'symbol') {
					mapRef.current.setLayoutProperty(
						layer.id,
						'visibility',
						'none'
					);
				}
			});

			const formattedFeatures = formate_polygons();

			const polygons = {
				type: 'FeatureCollection',
				features: formattedFeatures,
			};

			mapRef.current.addSource('polygon-source', {
				type: 'geojson',
				data: polygons,
			});

			mapRef.current.addLayer({
				id: 'polygon-layer',
				type: 'fill',
				source: 'polygon-source',
				paint: {
					'fill-color': ['get', 'fillColor'],
					'fill-opacity': ['get', 'fillColorOpacity'],
				},
			});

			mapRef.current.addLayer({
				id: 'polygon-outline-layer',
				type: 'line',
				source: 'polygon-source',
				layout: {
					'line-join': 'round',
					'line-cap': 'round',
				},
				paint: {
					'line-color': ['get', 'strokeColor'],
					'line-width': ['get', 'strokeWidth'],
					'line-opacity': ['get', 'strokeOpacity'],
				},
			});

			// Loop through each feature to add vertical lines, boxes, titles, and images
			formattedFeatures.forEach((feature) => {
				const center = turf.center(feature.geometry); // Get the center point of the polygon
				console.log('center....', center);
				const lineHeight = 0.005; // Adjust the height of the vertical line
				const lineEndPoint = [
					center.geometry.coordinates[0] + 0.006,
					center.geometry.coordinates[1] + lineHeight,
				];

				// Draw the vertical line
				mapRef.current.addSource(`line-${feature.properties.id}`, {
					type: 'geojson',
					data: {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [
								center.geometry.coordinates,
								lineEndPoint,
							],
						},
					},
				});
				mapRef.current.addLayer({
					id: `line-layer-${feature.properties.id}`,
					type: 'line',
					source: `line-${feature.properties.id}`,
					paint: {
						'line-color': '#fff', // Line color
						'line-width': 2,
					},
				});

				// Add rectangle layer to the map
				mapRef.current.addLayer({
					id: `box-layer-${feature.properties.id}`,
					type: 'fill',
					source: `box-${feature.properties.id}`,
					paint: {
						'fill-color': '#ffffff', // Box color
						'fill-opacity': 0.8, // Box opacity
					},
				});

				// Optional: Add a border around the box
				mapRef.current.addLayer({
					id: `box-outline-layer-${feature.properties.id}`,
					type: 'line',
					source: `box-${feature.properties.id}`,
					paint: {
						'line-color': '#000000', // Outline color
						'line-width': 2,
					},
				});

				// Create a div element for the title and image
				const title = feature.properties.name; // Assuming 'name' is a property in the feature
				const imageUrl = feature.properties.imageUrl; // Assuming 'imageUrl' is a property in the feature

				const popupContent = document.createElement('div');
				popupContent.innerHTML = `
					<div style="text-align: center">
						<h1>${title}</h1>
					</div>
				`;

				// Create a popup and set its coordinates
				const popup = new mapboxgl.Popup({
					closeButton: false,
					closeOnClick: false,
				})
					.setLngLat(lineEndPoint)
					.setDOMContent(popupContent)
					.addTo(mapRef.current);
			});

			// Event listeners for polygon layer
			mapRef.current.on('mouseenter', 'polygon-layer', (e) => {
				const features = mapRef.current.queryRenderedFeatures(e.point, {
					layers: ['polygon-layer'],
				});

				if (features.length > 0) {
					const clickedFeature = features[0];

					const hoverColor =
						clickedFeature.properties.fillColorOnMouseOver;
					const hoverOpacity =
						clickedFeature.properties.fillOpacityOnMouseOver || 0.6;

					mapRef.current.setPaintProperty(
						'polygon-layer',
						'fill-color',
						[
							'case',
							['==', ['get', 'id'], clickedFeature.properties.id],
							hoverColor,
							['get', 'fillColor'],
						]
					);

					mapRef.current.setPaintProperty(
						'polygon-layer',
						'fill-opacity',
						[
							'case',
							['==', ['get', 'id'], clickedFeature.properties.id],
							hoverOpacity,
							['get', 'fillColorOpacity'],
						]
					);
				}
			});

			mapRef.current.on('mouseleave', 'polygon-layer', () => {
				mapRef.current.setPaintProperty('polygon-layer', 'fill-color', [
					'get',
					'fillColor',
				]);
				mapRef.current.setPaintProperty(
					'polygon-layer',
					'fill-opacity',
					['get', 'fillColorOpacity']
				);
			});

			mapRef.current.on('click', 'polygon-layer', (e) => {
				const features = mapRef.current.queryRenderedFeatures(e.point, {
					layers: ['polygon-layer'],
				});

				if (features.length > 0) {
					const clickedFeature = features[0];
					console.log('clicked polygon----', clickedFeature.geometry);
					handlePolygonClick({
						...clickedFeature.properties,
						...clickedFeature.geometry,
					});
				}
			});
		});
	}, [polygonsList]);

	const formate_polygons = () => {
		if (polygonsList.length > 0) {
			const formated_list = polygonsList.map((item) => {
				let formated_polygon = {};
				formated_polygon['type'] = 'Feature';
				formated_polygon['properties'] = {
					id: item.id,
					name: item.name,
					fillColor: item.fillColor,
					fillColorOnMouseOver: item.fillColorOnMouseOver,
					fillColorOpacity: item.fillColorOpacity,
					fillcolorMouseOveropacity: item.fillcolorMouseOveropacity,
					strokeColor: item.strokeColor,
					strokeWidth: item.strokeWidth,
					strokeOpacity: item.strokeOpacity,
				};
				formated_polygon['geometry'] = {
					type: 'Polygon',
					coordinates: item['only_polygon_coordinates'],
				};
				return formated_polygon; // return the formatted polygon for each item
			});
			return formated_list; // return the formatted list after mapping
		}
		return [];
	};
	const calculateCentroid = (coordinates) => {
		let lngSum = 0;
		let latSum = 0;
		const points = coordinates[0]; // Assuming coordinates[0] holds the outer boundary

		points.forEach(([lng, lat]) => {
			lngSum += lng;
			latSum += lat;
		});

		return [lngSum / points.length, latSum / points.length];
	};
	const calculateMapCenter = (polygonsList) => {
		let totalLng = 0;
		let totalLat = 0;

		polygonsList.forEach((polygon) => {
			const centroid = calculateCentroid(
				polygon.only_polygon_coordinates
			);
			totalLng += centroid[0];
			totalLat += centroid[1];
		});

		// Average the centroids to find the overall center
		const centerLng = totalLng / polygonsList.length;
		const centerLat = totalLat / polygonsList.length;

		setmapCenter([centerLng, centerLat]);
	};
	const handlePolygonClick = (polygon) => {
		const selected_polygon = polygons_data.find(
			(item) => item['polygon_name'] == polygon.id
		);
		console.log('Polygons data,', polygons_data);
		console.log('final selected ', selected_polygon);
		console.log('selected polygon', polygon);
		let computed_polygon_data = {};
		//As of now we dont have complete data to we have to handle case, if polygon data found use that, else take firstone from availble polygons list
		if (selected_polygon) {
			computed_polygon_data = { ...polygon, ...selected_polygon };
		} else {
			computed_polygon_data = { ...polygon, ...polygons_data[0] };
		}
		handlecallbackActions(
			'handlePolygonSelectionFromGlobe',
			computed_polygon_data,
			2
		);
	};
	return (
		<div
			id="map"
			ref={mapContainerRef}
			style={{
				height: '100%',
				width: '100%',
			}}></div>
	);
};

export default MapViewCustomTilesPolygons;
