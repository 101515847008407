/* eslint-disable default-param-last */
import { SET_POLYGONS } from '../Actions/types';

export const polygonsInfo = (state = [], action) => {
	switch (action.type) {
		case SET_POLYGONS:
			return action.payload;
		default:
			return state;
	}
};
