import { useState } from 'react';
import CameraTrapLayout from './layout/CameraTrapLayout';
import ExploreDashboardLayout from './layout/DashboardLayout';
import LatestSightingLayout from './layout/LatestSightingLayout';
import ExploreSideLayout from './sidebar/ExploreSideLayout';
import AudioCaptureLayout from './layout/AudioCaptureLayout';
import ChangeDetectionLayout from './layout/ChangeDetectionLayout';
import { layoutKeys } from './common/constants';
import ExploreHeader from './common/ExploreHeader';
import Video360 from '../MYVR/MockScreens/Video360Player';
import VideoLatest from '../MYVR/MockScreens/VideoPlayerLatest';
import ForestContext from './utils/ForestContext';
import { getData } from './utils/utils';

const ExploreLayout = ({
	selectedForest,
	selectedPolygon,
	selectedItem,
	onBreadcrumbClick,
}) => {
	const [selectedSection, setSelectedSection] = useState(
		selectedItem || layoutKeys.DASHBOARD
	);

	const [showExperince, setShowExperince] = useState(false);
	const [isSubLayout, setIsSubLayout] = useState(false);
	// <VideoLatest selectedForest={selectedForest} />

	const handleSelectionSection = (section) => {
		setSelectedSection(section);
	};

	const layoutList = {
		[layoutKeys.DASHBOARD]: (
			<ExploreDashboardLayout
				onChangeDetection={() => {
					setIsSubLayout(true);
					handleSelectionSection(layoutKeys.CHANGEDETECTION);
				}}
			/>
		),
		[layoutKeys.CAMERA360]: showExperince ? (
			<Video360 selectedForest={selectedForest} />
		) : (
			<VideoLatest selectedForest={selectedForest} />
		),
		[layoutKeys.CAMERATRAP]: <CameraTrapLayout />,
		[layoutKeys.AUDIOCAPTURE]: <AudioCaptureLayout />,
		[layoutKeys.LATESTSIGHTINGS]: <LatestSightingLayout />,
		[layoutKeys.CHANGEDETECTION]: <ChangeDetectionLayout />,
	};

	const handleCameraSideBarSelection = (section) => {
		setShowExperince(section);
	};

	return (
		<ForestContext.Provider value={getData(selectedPolygon)}>
			<div className="flex flex-col flex-1 bg-[#131314] h-full w-full overflow-hidden">
				<ExploreHeader
					forestTitle={selectedForest.forestName}
					polygonTitle={selectedPolygon.polygon_name}
					tileTitle={isSubLayout ? selectedSection : ''}
					onBreadcrumbClick={(id, local) => {
						setIsSubLayout(false);
						if (local) {
							setSelectedSection(layoutKeys.DASHBOARD);
						} else {
							onBreadcrumbClick(id);
						}
					}}
				/>
				<div className="flex h-full p-[10px] pt-0 overflow-auto gap-[10px]">
					<div className="w-1/5 overflow-auto">
						<ExploreSideLayout
							selectedSection={selectedSection}
							onSelectedSection={(...args) => {
								setIsSubLayout(false);
								handleSelectionSection(...args);
							}}
							showVisulization={showExperince}
							selectedPolygon={selectedPolygon}
							handleVisulizationSelection={
								handleCameraSideBarSelection
							}
						/>
					</div>
					<div className="w-4/5">{layoutList[selectedSection]}</div>
				</div>
			</div>
		</ForestContext.Provider>
	);
};

export default ExploreLayout;
