import {
	changeDetection,
	NDVI,
	ortho,
	health,
	operationalArea,
} from '../common/images';

const ChangeDetectionSideBar = () => {
	return (
		<div className="flex flex-col overflow-hidden gap-[10px] max-h-full flex-1">
			<div className="flex w-full gap-[10px] h-[30%]">
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-3/5"
						src={NDVI}
						alt="NDVI"
					/>
					<span className="text-[#BFBFBF] shrink-0 mt-2">NDVI</span>
				</div>
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-3/5"
						src={changeDetection}
						alt="Change Detection"
					/>
					<span className="text-[#BFBFBF] shrink-0 mt-2">Change</span>
				</div>
			</div>
			<div className="flex w-full gap-[10px] h-[30%]">
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden shrink-0">
					<img
						className="object-contain h-3/5"
						src={ortho}
						alt="Ortho"
					/>
					<span className="text-[#BFBFBF] shrink-0 mt-2">Ortho</span>
				</div>
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden shrink-0">
					<img
						className="object-contain h-3/5"
						src={health}
						alt="Health"
					/>
					<span className="text-[#BFBFBF] shrink-0 mt-2">Health</span>
				</div>
			</div>
			<div className="flex flex-col justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
				<img
					className="object-contain h-4/5"
					src={operationalArea}
					alt="Operation Area"
				/>
			</div>
		</div>
	);
};

export default ChangeDetectionSideBar;
