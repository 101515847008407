/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateForestMainTable = /* GraphQL */ `
	subscription OnCreateForestMainTable(
		$filter: ModelSubscriptionForestMainTableFilterInput
	) {
		onCreateForestMainTable(filter: $filter) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const onUpdateForestMainTable = /* GraphQL */ `
	subscription OnUpdateForestMainTable(
		$filter: ModelSubscriptionForestMainTableFilterInput
	) {
		onUpdateForestMainTable(filter: $filter) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const onDeleteForestMainTable = /* GraphQL */ `
	subscription OnDeleteForestMainTable(
		$filter: ModelSubscriptionForestMainTableFilterInput
	) {
		onDeleteForestMainTable(filter: $filter) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const onCreateForestsData = /* GraphQL */ `
	subscription OnCreateForestsData(
		$filter: ModelSubscriptionForestsDataFilterInput
	) {
		onCreateForestsData(filter: $filter) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateForestsData = /* GraphQL */ `
	subscription OnUpdateForestsData(
		$filter: ModelSubscriptionForestsDataFilterInput
	) {
		onUpdateForestsData(filter: $filter) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteForestsData = /* GraphQL */ `
	subscription OnDeleteForestsData(
		$filter: ModelSubscriptionForestsDataFilterInput
	) {
		onDeleteForestsData(filter: $filter) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateEmailConfig = /* GraphQL */ `
	subscription OnCreateEmailConfig(
		$filter: ModelSubscriptionEmailConfigFilterInput
	) {
		onCreateEmailConfig(filter: $filter) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateEmailConfig = /* GraphQL */ `
	subscription OnUpdateEmailConfig(
		$filter: ModelSubscriptionEmailConfigFilterInput
	) {
		onUpdateEmailConfig(filter: $filter) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteEmailConfig = /* GraphQL */ `
	subscription OnDeleteEmailConfig(
		$filter: ModelSubscriptionEmailConfigFilterInput
	) {
		onDeleteEmailConfig(filter: $filter) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateAuditTrail = /* GraphQL */ `
	subscription OnCreateAuditTrail(
		$filter: ModelSubscriptionAuditTrailFilterInput
	) {
		onCreateAuditTrail(filter: $filter) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateAuditTrail = /* GraphQL */ `
	subscription OnUpdateAuditTrail(
		$filter: ModelSubscriptionAuditTrailFilterInput
	) {
		onUpdateAuditTrail(filter: $filter) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteAuditTrail = /* GraphQL */ `
	subscription OnDeleteAuditTrail(
		$filter: ModelSubscriptionAuditTrailFilterInput
	) {
		onDeleteAuditTrail(filter: $filter) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateSupportTicketsTable = /* GraphQL */ `
	subscription OnCreateSupportTicketsTable(
		$filter: ModelSubscriptionSupportTicketsTableFilterInput
	) {
		onCreateSupportTicketsTable(filter: $filter) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateSupportTicketsTable = /* GraphQL */ `
	subscription OnUpdateSupportTicketsTable(
		$filter: ModelSubscriptionSupportTicketsTableFilterInput
	) {
		onUpdateSupportTicketsTable(filter: $filter) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteSupportTicketsTable = /* GraphQL */ `
	subscription OnDeleteSupportTicketsTable(
		$filter: ModelSubscriptionSupportTicketsTableFilterInput
	) {
		onDeleteSupportTicketsTable(filter: $filter) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreatePurchaseInvoices = /* GraphQL */ `
	subscription OnCreatePurchaseInvoices(
		$filter: ModelSubscriptionPurchaseInvoicesFilterInput
	) {
		onCreatePurchaseInvoices(filter: $filter) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const onUpdatePurchaseInvoices = /* GraphQL */ `
	subscription OnUpdatePurchaseInvoices(
		$filter: ModelSubscriptionPurchaseInvoicesFilterInput
	) {
		onUpdatePurchaseInvoices(filter: $filter) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const onDeletePurchaseInvoices = /* GraphQL */ `
	subscription OnDeletePurchaseInvoices(
		$filter: ModelSubscriptionPurchaseInvoicesFilterInput
	) {
		onDeletePurchaseInvoices(filter: $filter) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const onCreateInvitationTable = /* GraphQL */ `
	subscription OnCreateInvitationTable(
		$filter: ModelSubscriptionInvitationTableFilterInput
	) {
		onCreateInvitationTable(filter: $filter) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateInvitationTable = /* GraphQL */ `
	subscription OnUpdateInvitationTable(
		$filter: ModelSubscriptionInvitationTableFilterInput
	) {
		onUpdateInvitationTable(filter: $filter) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteInvitationTable = /* GraphQL */ `
	subscription OnDeleteInvitationTable(
		$filter: ModelSubscriptionInvitationTableFilterInput
	) {
		onDeleteInvitationTable(filter: $filter) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateUserData = /* GraphQL */ `
	subscription OnCreateUserData(
		$filter: ModelSubscriptionUserDataFilterInput
	) {
		onCreateUserData(filter: $filter) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateUserData = /* GraphQL */ `
	subscription OnUpdateUserData(
		$filter: ModelSubscriptionUserDataFilterInput
	) {
		onUpdateUserData(filter: $filter) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteUserData = /* GraphQL */ `
	subscription OnDeleteUserData(
		$filter: ModelSubscriptionUserDataFilterInput
	) {
		onDeleteUserData(filter: $filter) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateMedia = /* GraphQL */ `
	subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
		onCreateMedia(filter: $filter) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateMedia = /* GraphQL */ `
	subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
		onUpdateMedia(filter: $filter) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteMedia = /* GraphQL */ `
	subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
		onDeleteMedia(filter: $filter) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateGEOJsonMapper = /* GraphQL */ `
	subscription OnCreateGEOJsonMapper(
		$filter: ModelSubscriptionGEOJsonMapperFilterInput
	) {
		onCreateGEOJsonMapper(filter: $filter) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateGEOJsonMapper = /* GraphQL */ `
	subscription OnUpdateGEOJsonMapper(
		$filter: ModelSubscriptionGEOJsonMapperFilterInput
	) {
		onUpdateGEOJsonMapper(filter: $filter) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteGEOJsonMapper = /* GraphQL */ `
	subscription OnDeleteGEOJsonMapper(
		$filter: ModelSubscriptionGEOJsonMapperFilterInput
	) {
		onDeleteGEOJsonMapper(filter: $filter) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreatePDFReports = /* GraphQL */ `
	subscription OnCreatePDFReports(
		$filter: ModelSubscriptionPDFReportsFilterInput
	) {
		onCreatePDFReports(filter: $filter) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdatePDFReports = /* GraphQL */ `
	subscription OnUpdatePDFReports(
		$filter: ModelSubscriptionPDFReportsFilterInput
	) {
		onUpdatePDFReports(filter: $filter) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeletePDFReports = /* GraphQL */ `
	subscription OnDeletePDFReports(
		$filter: ModelSubscriptionPDFReportsFilterInput
	) {
		onDeletePDFReports(filter: $filter) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateClientsTable = /* GraphQL */ `
	subscription OnCreateClientsTable(
		$filter: ModelSubscriptionClientsTableFilterInput
	) {
		onCreateClientsTable(filter: $filter) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateClientsTable = /* GraphQL */ `
	subscription OnUpdateClientsTable(
		$filter: ModelSubscriptionClientsTableFilterInput
	) {
		onUpdateClientsTable(filter: $filter) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteClientsTable = /* GraphQL */ `
	subscription OnDeleteClientsTable(
		$filter: ModelSubscriptionClientsTableFilterInput
	) {
		onDeleteClientsTable(filter: $filter) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreatePurchaseOrders = /* GraphQL */ `
	subscription OnCreatePurchaseOrders(
		$filter: ModelSubscriptionPurchaseOrdersFilterInput
	) {
		onCreatePurchaseOrders(filter: $filter) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdatePurchaseOrders = /* GraphQL */ `
	subscription OnUpdatePurchaseOrders(
		$filter: ModelSubscriptionPurchaseOrdersFilterInput
	) {
		onUpdatePurchaseOrders(filter: $filter) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeletePurchaseOrders = /* GraphQL */ `
	subscription OnDeletePurchaseOrders(
		$filter: ModelSubscriptionPurchaseOrdersFilterInput
	) {
		onDeletePurchaseOrders(filter: $filter) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateRolesTable = /* GraphQL */ `
	subscription OnCreateRolesTable(
		$filter: ModelSubscriptionRolesTableFilterInput
	) {
		onCreateRolesTable(filter: $filter) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateRolesTable = /* GraphQL */ `
	subscription OnUpdateRolesTable(
		$filter: ModelSubscriptionRolesTableFilterInput
	) {
		onUpdateRolesTable(filter: $filter) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteRolesTable = /* GraphQL */ `
	subscription OnDeleteRolesTable(
		$filter: ModelSubscriptionRolesTableFilterInput
	) {
		onDeleteRolesTable(filter: $filter) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onCreateUserRegistrationValidationResponse = /* GraphQL */ `
	subscription OnCreateUserRegistrationValidationResponse(
		$filter: ModelSubscriptionUserRegistrationValidationResponseFilterInput
	) {
		onCreateUserRegistrationValidationResponse(filter: $filter) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onUpdateUserRegistrationValidationResponse = /* GraphQL */ `
	subscription OnUpdateUserRegistrationValidationResponse(
		$filter: ModelSubscriptionUserRegistrationValidationResponseFilterInput
	) {
		onUpdateUserRegistrationValidationResponse(filter: $filter) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const onDeleteUserRegistrationValidationResponse = /* GraphQL */ `
	subscription OnDeleteUserRegistrationValidationResponse(
		$filter: ModelSubscriptionUserRegistrationValidationResponseFilterInput
	) {
		onDeleteUserRegistrationValidationResponse(filter: $filter) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
