/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import './VideoPlayer.css';
import ReactPlayer from 'react-player';
import Next from './Next.png';
import Prev from './Prev.png';
import { Storage } from 'aws-amplify';
import ThumbnailDisplay from './ThumbnailDisplay';

const VideoPlayer = (props) => {
	const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
	const [hasInteracted, setHasInteracted] = useState(false);
	const [videoList, setvideoList] = useState([]);
	const videoRef = useRef(null);
	const imageRefs = useRef([]);
	const [thumpnails, setThumpnails] = useState([]);

	const imageExtensions = [
		'jpg',
		'jpeg',
		'png',
		'gif',
		'bmp',
		'tiff',
		'svg',
		'webp',
	];
	const videoExtensions = [
		'mp4',
		'avi',
		'mov',
		'wmv',
		'flv',
		'mkv',
		'webm',
		'mpeg',
	];

	useEffect(() => {
		listItems(props.selectedForest.forestName);
	}, []);

	const getFileType = (fileName) => {
		const extension = fileName.split('.').pop().toLowerCase();
		if (imageExtensions.includes(extension)) {
			return 'image';
		} else if (videoExtensions.includes(extension)) {
			return 'video';
		} else {
			return 'unknown';
		}
	};
	const get_image_storage_urls = async (mediaFiles) => {
		const updated_storage_url_objects = await Promise.all(
			mediaFiles.map(async (obj, index) => {
				try {
					const icon = await Storage.get(obj.key);
					const media_type = getFileType(obj.key);
					return { ...obj, icon, type: media_type, id: index };
				} catch (error) {
					console.error('**********', error);
					return { ...obj, url: undefined }; // Optionally handle error case
				}
			})
		);
		return updated_storage_url_objects;
	};
	async function listItems(folderName) {
		try {
			//props.folderName
			let completeS3Path = `${folderName}/`;
			if (props.folderName != '') {
				completeS3Path = `${folderName}/${props.folderName}/`;
			}
			const result = await Storage.list(completeS3Path);
			const result_items = result['results'];
			const storage_urls = await get_image_storage_urls(result_items);
			setvideoList(storage_urls);
			setCurrentVideoIndex(0);
		} catch (error) {
			console.error('Error listing items from S3:', error);
		}
	}

	const render_thumpnail_images = () => {
		if (videoList.length > 0 && currentVideoIndex != null) {
			const thumbnail_components = videoList.map(
				(storageObject, index) => (
					<ThumbnailDisplay
						key={index}
						ref={(el) => (imageRefs.current[index] = el)}
						storageObject={storageObject}
						onSelect={() => handleThumbnailClick(index)}
						currentVideoIndex={currentVideoIndex}
					/>
				)
			);
			return thumbnail_components;
		}
	};

	const handleNext = () => {
		setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoList.length);
		scrollToImage((currentVideoIndex + 1) % videoList.length);
	};
	const handlePrevious = () => {
		setCurrentVideoIndex(
			(prevIndex) => (prevIndex - 1 + videoList.length) % videoList.length
		);
		scrollToImage(
			(currentVideoIndex - 1 + videoList.length) % videoList.length
		);
	};
	const scrollToImage = (index) => {
		imageRefs.current[index].scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
	};
	const handleThumbnailClick = (index) => {
		setCurrentVideoIndex(index);
	};

	const handleInteraction = () => {
		setHasInteracted(true);
		if (videoRef.current) {
			videoRef.current.play();
		}
	};
	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.load();
			videoRef.current.play().catch((error) => {
				console.error('Error attempting to play video:', error);
			});
		}
	});

	useEffect(() => {
		if (hasInteracted && videoRef.current) {
			videoRef.current.load();
			videoRef.current.play().catch((error) => {
				console.error('Error attempting to play video:', error);
			});
		}
	}, [currentVideoIndex, hasInteracted]);

	const display_content = () => {
		if (videoList.length > 0 && currentVideoIndex != null) {
			const current_selected_item = videoList[currentVideoIndex];
			if (current_selected_item.type == 'image') {
				return (
					<div
						style={{
							display: 'flex',
							flex: 1,
							flexGrow: 1,
							flexShrink: 0,
							backgroundImage: `url(${current_selected_item.icon})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center', // Centers the image
							backgroundRepeat: 'no-repeat', // Prevents the image from repeating
						}}
						key={currentVideoIndex}>
						sfsf
					</div>
				);
			}

			return (
				<video
					ref={videoRef}
					controls
					style={{
						display: 'flex',
						width: '100%',
						height: '80%',
						minWidth: '100%',
						maxHeight: '500px',
						objectFit: 'fill',
					}}>
					<source src={current_selected_item.icon} />
					Your browser does not support the video tag.
				</video>
			);
		}
	};

	return (
		<div className="video-player">
			<div
				style={{
					display: 'flex',
					width: '100%',
					maxHeight: '500px',
					minHeight: '500px',
				}}>
				{display_content()}
			</div>
			<div
				className="controls"
				style={{
					display: 'flex',
					flex: 1,
					width: '100%',
					height: '20%',
					maxHeight: '20%',
					minHeight: '150px',
					flexWrap: 'wrap',
					justifyContent: 'space-evenly',
				}}>
				<div
					style={{
						display: 'flex',
						flex: 1,
						width: '10%',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<img
						src={Prev}
						title={'Previous'}
						style={{ width: '45px', height: '45px', margin: '2px' }}
						alt={'Prev'}
						onClick={handlePrevious}
					/>
				</div>

				<div
					className="thumbnails"
					style={{ display: 'flex', flex: 15 }}>
					{/* {thumpnails.map((video, index) => video)} */}
					{render_thumpnail_images()}
				</div>
				<div
					style={{
						display: 'flex',
						flex: 1,
						width: '10%',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<img
						src={Next}
						title={'Next'}
						style={{ width: '45px', height: '45px', margin: '2px' }}
						alt={'Next'}
						onClick={handleNext}
					/>
				</div>
			</div>
		</div>
	);
};
// };
export default VideoPlayer;
