import { useState } from 'react';
import MyvrViewer from './MyvrViewer';
import ExploreLayout from '../Explore/ExploreLayout';
import { layoutKeys } from '../Explore/common/constants';

const MyVrLayout = () => {
	const [data, setData] = useState(null);
	const [showExplore, setShowExplore] = useState(null);
	const [breadcrumb, setBreadcrumb] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);

	if (showExplore) {
		return (
			<ExploreLayout
				selectedForest={data.selectedForest}
				selectedPolygon={data.selectedPolygon}
				selectedItem={selectedItem}
				onBreadcrumbClick={(id) => {
					setShowExplore(false);
					setBreadcrumb(id);
				}}
			/>
		);
	}

	const myVrProps = {};

	const updated_globe_data = {};
	if (breadcrumb === 1) {
		updated_globe_data.currentPage = 'Globe';
	} else if (breadcrumb === 0) {
		updated_globe_data.currentPage = 'Globe';
		updated_globe_data.showPolygons = false;
	}

	if (data) {
		myVrProps.defaultState = {
			...data,
			page: breadcrumb,
			...updated_globe_data,
		};
	}

	return (
		<MyvrViewer
			onExplore={(d, layoutSelectedItem = layoutKeys.DASHBOARD) => {
				setData(d);
				setSelectedItem(layoutSelectedItem);
				setShowExplore(true);
			}}
			{...myVrProps}
		/>
	);
};

export default MyVrLayout;
