import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { Riple } from 'react-loading-indicators';
import { IconButton } from '@digitalreality/ui';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const temp_hardcodedObject = {
	'La Gamba':
		'https://desolinator.s3.eu-north-1.amazonaws.com/Amable360Jun2024.mp4',
	Ibiti: 'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/Ibiti360.MOV',
	'Reclamation mine':
		'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_otherImagesAndAsserts/Mac360.MOV',
};

const getURL = (forestName) => {
	let video_url =
		'https://desolinator.s3.eu-north-1.amazonaws.com/Amable360Jun2024.mp4';
	if (forestName) {
		video_url =
			temp_hardcodedObject[forestName] ||
			'https://desolinator.s3.eu-north-1.amazonaws.com/Amable360Jun2024.mp4';
	}
	return video_url;
};

const Video360Player = (props) => {
	const { selectedForest } = props;
	const { forestName } = selectedForest || {};
	const mountRef = useRef(null);
	const videoRef = useRef(null);
	const [videoStarted, setVideoStarted] = useState(false);
	const [videoLoading, setVideoLoading] = useState(true);

	useEffect(() => {
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.1,
			1000
		);
		const renderer = new THREE.WebGLRenderer();
		renderer.setSize(window.innerWidth, window.innerHeight);
		const mountElement = mountRef.current;
		mountElement.appendChild(renderer.domElement);

		// Create a plane geometry with proper aspect ratio
		const aspectRatio = window.innerWidth / window.innerHeight;
		const geometry = new THREE.PlaneGeometry(16 * aspectRatio, 9);

		const video = document.createElement('video');
		video.src = getURL(forestName);
		video.crossOrigin = 'anonymous';
		video.loop = true;
		video.muted = true; // Start muted
		video.autoplay = true;
		video.playsInline = true;
		videoRef.current = video;

		const texture = new THREE.VideoTexture(video);
		texture.minFilter = THREE.LinearFilter;
		texture.magFilter = THREE.LinearFilter;
		// texture.format = THREE.RGBFormat;
		texture.format = THREE.RGBAFormat;

		const material = new THREE.MeshBasicMaterial({ map: texture });
		const plane = new THREE.Mesh(geometry, material);
		scene.add(plane);

		const animate = () => {
			requestAnimationFrame(animate);
			renderer.render(scene, camera);
		};

		animate();

		video.addEventListener('loadeddata', () => {
			setVideoLoading(false);
		});

		camera.position.z = 5;

		const handleResize = () => {
			const newAspectRatio = window.innerWidth / window.innerHeight;
			camera.aspect = newAspectRatio;
			camera.updateProjectionMatrix();
			renderer.setSize(window.innerWidth, window.innerHeight);
			plane.scale.set(16 * newAspectRatio, 9, 1);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			if (videoRef.current) {
				videoRef.current.pause();
				videoRef.current.src = '';
				videoRef.current.load();
			}
			if (mountElement && renderer.domElement) {
				mountElement.removeChild(renderer.domElement);
			}
		};
	}, [forestName]);

	const handle_play = () => {
		if (videoRef.current) {
			videoRef.current.muted = false;
			videoRef.current.play();
			setVideoStarted(true);
		}
	};
	const handle_pause = () => {
		if (videoRef.current) {
			videoRef.current.pause();
			setVideoStarted(false);
		}
	};
	const handleForward = () => {
		if (videoRef.current) {
			videoRef.current.currentTime += 10; // Forward by 10 seconds
		}
	};

	const handleBackward = () => {
		if (videoRef.current) {
			videoRef.current.currentTime -= 10; // Backward by 10 seconds
		}
	};

	return (
		<div
			ref={mountRef}
			style={{
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
				width: '100%',
				height: '100%',
				overflow: 'hidden',
			}}>
			{videoLoading && (
				<div
					style={{
						color: '#ff0000',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}>
					<Riple
						color="#0cc50c"
						size="large"
						text="Loading"
						textColor="#0deb30"
					/>
				</div>
			)}
			{!videoLoading && (
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100px',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						bottom: 0,
					}}>
					<div className="flex flex-row bg-[#000] h-15 rounded-full p-2">
						<IconButton onClick={handleBackward}>
							<SkipPreviousIcon
								sx={{ color: '#fff', fontSize: '36px' }}
							/>
						</IconButton>

						{!videoStarted && (
							<IconButton onClick={handle_play}>
								<PlayArrowIcon
									sx={{ color: '#fff', fontSize: '40px' }}
								/>
							</IconButton>
						)}

						{videoStarted && (
							<IconButton onClick={handle_pause}>
								<PauseIcon
									sx={{ color: '#fff', fontSize: '40px' }}
								/>
							</IconButton>
						)}

						<IconButton onClick={handleForward}>
							<SkipNextIcon
								sx={{ color: '#fff', fontSize: '36px' }}
							/>
						</IconButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default Video360Player;
