const MyVRBottom = (props) => {
	const { value, onChange, onSubmit, inputDisabled, maxValue } = props;

	return (
		<div className="animate-fadeIn bg-[#77d721] p-2 flex items-center justify-end gap-16 px-16 min-h-16">
			<input
				className="outline-0 border border-[#888888] text-black p-2 rounded-3xl text-center w-40"
				max={maxValue}
				min={1}
				disabled={inputDisabled} // >1
				value={value || ''}
				onChange={(e) => {
					if (e.target.value <= maxValue && e.target.value > -1) {
						onChange(e.target.value);
					}
				}}
				type="number"
			/>
			<button
				type="button"
				onClick={onSubmit}
				disabled={!value}
				className="flex justify-center items-center bg-[#DCFF06] h-10 w-48 rounded-[20px]">
				<span className="uppercase text-sm font-normal text-[#2A2A2A]">
					check out
				</span>
			</button>
		</div>
	);
};

export default MyVRBottom;
