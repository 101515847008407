import { useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { permissionsList } from '../../Pages/CommonComponents/Drawer/routeList';
import { StyleContext } from '../../Pages/ContextThemes/StyleContext';

const MultiSelectChip = ({ value = [] }) => {
	const contextStyles = useContext(StyleContext);
	const permissions =
		value?.map((element) =>
			permissionsList.find((item) => item?.value === element)
		) || [];
	return (
		<div className="flex flex-row flex-wrap text-black overflow-hidden">
			{permissions.map((item) => (
				<span
					key={item?.value}
					className="m-1 rounded-3xl text-black px-4 h-8 flex items-center text-[10px]"
					style={{ backgroundColor: contextStyles.button_bg_color }}>
					{item?.description}
				</span>
			))}
		</div>
	);
};

export default MultiSelectChip;
