import { moreIcon } from '../../../../components/icons/icons';
import { gaugeGreen, AudioIcon } from '../common/images';

const AudioCaptureSidebar = () => {
	return (
		<div className="flex flex-col overflow-hidden gap-[10px] max-h-full flex-1">
			<div className="flex w-full gap-[10px] h-[30%]">
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-4/5"
						src={AudioIcon}
						alt="AudioLG01"
					/>
					<span className="text-[#BFBFBF] shrink-0">Audio LG01</span>
				</div>
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
					<img
						className="object-contain h-4/5"
						src={AudioIcon}
						alt="AudioLG02"
					/>
					<span className="text-[#BFBFBF] shrink-0">Audio LG02</span>
				</div>
			</div>
			<div className="flex w-full gap-[10px] h-[30%]">
				<div className="flex flex-col items-center justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden shrink-0">
					<img
						className="object-contain h-4/5"
						src={AudioIcon}
						alt="AudioLG03"
					/>
					<span className="text-[#BFBFBF] shrink-0">Audio LG03</span>
				</div>
				<div className="flex flex-row items-end justify-end bg-[#1E1E21] rounded-[5px] flex-1 overflow-hidden">
					<div className="flex flex-row gap-2 p-6">
						<div className="rounded-full bg-[#BFBFBF] h-3 w-3" />
						<div className="rounded-full bg-[#BFBFBF] h-3 w-3" />
						<div className="rounded-full bg-[#BFBFBF] h-3 w-3" />
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-center bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
				<div className="flex justify-between items-center p-[10px]">
					<span className="uppercase">relative sighting index</span>
					<span
						className="h-5 w-5 box-border flex items-center justify-center"
						dangerouslySetInnerHTML={{ __html: moreIcon }}
					/>
				</div>
				<img
					className="object-contain h-4/5"
					src={gaugeGreen}
					alt="CAM_LG01"
				/>
			</div>
		</div>
	);
};

export default AudioCaptureSidebar;
