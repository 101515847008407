import React from 'react';

const TextToBool = ({ value }) => {
	return (
		<span
			style={{
				display: 'flex',
				height: '100%',
				width: '100%',
				alignItems: 'center',
			}}>
			{value ? 'Active' : 'Disiabled'}
		</span>
	);
};

export default TextToBool;
