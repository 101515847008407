import React from 'react';
import { connect } from 'react-redux';
import { Button, Text } from '@digitalreality/ui';
import LandingPageVideo from './LandingPageVideo.mp4';
import {
	errorTextAction,
	setLandingPageStatus,
} from '../../ReduxStore/Actions/CommonActions';

const LandingPage = (props) => {
	const {
		setLandingPageStatus: setLandingPageStatusAction,
		errorTextAction: errorTextReduxAction,
	} = props;

	const handleError = (errorText) => {
		errorTextReduxAction(errorText);
	};

	const handleLoginClick = () => {
		handleError('');
		setLandingPageStatusAction();
	};
	return (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					display: 'flex',
					flexGrow: 1,
					flexShrink: 1,
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'row',
					height: '100vh',
				}}>
				<video
					autoPlay
					muted
					loop
					style={{
						width: '100vw',
						height: '100vh',
						objectFit: 'cover',
						position: 'fixed',
						left: 0,
						top: 0,
						zIndex: -1,
						opacity: 0.7,
					}}>
					<source
						src={LandingPageVideo}
						type="video/mp4"
					/>
				</video>
			</div>
			<div
				style={{
					justifyContent: 'center',
					position: 'absolute',
					top: 40,
					marginTop: '20%',
					marginLeft: '30%',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Text
					variant="h1"
					style={{
						fontSize: 'clamp(16px,2.5vw,25px)',
						fontWeight: 'bold',
						margin: 'clamp(0.5px,2.5vw,2px)',
						marginBottom: '5px',
					}}>
					Welcome,
				</Text>
				<Text
					variant="h2"
					style={{
						fontSize: 'clamp(4px,2.5vw,18px)',
						fontWeight: 'bold',
						margin: 'clamp(0.5px,2.5vw,2px)',
					}}>
					What if enterprises could help to preserve biodiversity?
				</Text>
				<Text
					variant="h2"
					style={{
						fontSize: 'clamp(4px,2.5vw,18px)',
						margin: 'clamp(0.5px,2.5vw,2px)',
						marginTop: 'clamp(4px,2.5vw,10px)',
					}}>
					Now you can, by buying Green Cubes: A cubic meter of the
					most critical,
				</Text>
				<Text
					variant="h2"
					style={{
						fontSize: 'clamp(4px,2.5vw,18px)',
						margin: 'clamp(0.5px,2.5vw,2px)',
					}}>
					{' '}
					most vulnerable and most important forests.
				</Text>
				<Button
					variant="contained"
					style={{
						height: '35px',
						width: '85px',
						fontSize: 'clamp(2px,2.5vw,14px)',
						marginTop: 'clamp(4px,2.5vw,15px)',
					}}
					onClick={() => handleLoginClick()}>
					Lets Go
				</Button>
			</div>
		</div>
	);
};

export default connect(null, {
	errorTextAction,
	setLandingPageStatus,
})(LandingPage);
