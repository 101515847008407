/* eslint-disable no-use-before-define */
import React, { useCallback } from 'react';
import { Text } from '@digitalreality/ui';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import {
	errorTextAction,
	loggedInUserData,
	showLoadingIndicator,
} from '../../Pages/ReduxStore/Actions/CommonActions';
import { switchCurrentLoginSection } from '../../Pages/ReduxStore/Actions/LoginPageActions';
import * as queries from '../../graphql/queries';
import SubmitButton from '../base/SubmitButton';
import { useFormState } from '../hooks/useFormState';
import { formValidation } from '../../utils/formValidation';
import { FieldList } from '../base/FieldList';
import { makeGraphQLRequest } from '../../utils/makeGraphQLRequest';

const LoginForm = (props) => {
	const {
		loggedInUserData: loggedInUserDataAction,
		switchCurrentLoginSection: switchCurrentLoginSectionAction,
	} = props;
	const {
		formData,
		setFormData,
		onChange,
		error,
		setError,
		isLoading,
		setIsLoading,
	} = useFormState();

	const formFields = [
		{
			id: 'username',
			label: 'Email',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('username', e.target.value),
				[onChange]
			),
			onKeyDown: (e) => e.key === 'Enter' && handleLogin(),
		},
		{
			id: 'password',
			label: 'Password',
			type: 'password',
			onChange: useCallback(
				(e) => onChange('password', e.target.value),
				[onChange]
			),
			onKeyDown: (e) => e.key === 'Enter' && handleLogin(),
		},
	];

	const handleLogin = async () => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;

		try {
			setIsLoading(true);
			const user = await Auth.signIn(
				reducedData.username.toLowerCase(),
				reducedData.password
			);
			if (user) {
				const email_lowercase = reducedData.username.toLowerCase();
				const responseObject = await makeGraphQLRequest({
					query: queries.getLoggedInUserData,
					variables: { email: email_lowercase },
				});
				loggedInUserDataAction({
					...user,
					...responseObject,
				});
				setError('');
			}
		} catch (e) {
			let errorMsg = '';
			if (e.code === 'UserNotFoundException') {
				errorMsg = 'User does not exist. Please sign up.';
			} else if (e.code === 'NotAuthorizedException') {
				errorMsg = 'Incorrect username or password.';
			} else if (e.code === 'UserNotConfirmedException') {
				errorMsg =
					'User has not been confirmed. Please confirm your account.';
			} else {
				errorMsg =
					'Error signing in make sure to enter user name and password';
			}
			if (Auth.currentSession) {
				await Auth.signOut();
			}
			setError(errorMsg);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSignUp = () => {
		setError('');
		switchCurrentLoginSectionAction({ page: 'SIGNUP_FORM' });
	};

	const handleForgotPassword = () => {
		setError('');
		switchCurrentLoginSectionAction({ page: 'FORGOT_PASSWORD_FORM' });
	};

	const handleSignupConfirmation = () => {
		setError('');
		switchCurrentLoginSectionAction({ page: 'SIGNUP_CONFIRMATION_FORM' });
	};

	return (
		<div className="animate-fadeIn flex flex-col w-3/5 h-full items-center justify-center gap-3">
			<div className="flex flex-col gap-2 w-full">
				<span
					className="text-[#9BE938] text-start"
					style={{
						fontSize: 'clamp(8px,3vw,30px)',
						lineHeight: 'clamp(18px,3.5vw,36px)',
					}}>
					Welcome
				</span>
				<span
					className="text-[#ffffff]"
					style={{
						fontSize: 'clamp(4px,2.5vw,14px)',
						lineHeight: 'clamp(10px,3.5vw,20px)',
					}}>
					You have been added to the Green Cubes
				</span>
				<span
					className="text-[#ffffff] font-bold"
					style={{
						fontSize: 'clamp(4px,2.5vw,14px)',
						lineHeight: 'clamp(10px,3.5vw,20px)',
					}}>
					SignIn
				</span>
			</div>
			<Text className="text-sm text-red-400 w-full">{error}</Text>
			<FieldList
				fields={formFields}
				data={formData}
			/>
			<span
				role="button"
				tabIndex="0"
				onKeyDown={() => {}}
				aria-label="Span"
				className="w-full text-sm text-right cursor-pointer underline text-[#90EED5]"
				onClick={handleForgotPassword}>
				Forgot your password?
			</span>
			<div className="flex flex-col w-full gap-4 mt-8">
				<SubmitButton
					isLoading={isLoading}
					onClick={handleLogin}
					text="Login"
				/>
				<SubmitButton
					onClick={handleSignUp}
					text="SignUp"
				/>
				<SubmitButton
					onClick={handleSignupConfirmation}
					text="Confirm Registration"
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		errorStatus: state.errorStatus,
	};
};

export default connect(mapStateToProps, {
	errorTextAction,
	showLoadingIndicator,
	loggedInUserData,
	switchCurrentLoginSection,
})(LoginForm);
