import ReportList from './ReportList';
import ReportForm from './ReportForm';

const Report = () => {
	return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<ReportForm />
			<ReportList />
		</div>
	);
};

export default Report;
