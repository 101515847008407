import { SET_FORESTS } from './types';

const setForests = (payload) => {
	return {
		type: SET_FORESTS,
		payload,
	};
};

export { setForests };
