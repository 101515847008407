import { ENABLE_ICONS } from './types';

const errorTextAction = (errorMsg) => {
	return {
		type: 'setError',
		payload: errorMsg,
	};
};

const loggedInUserData = (data) => {
	return {
		type: 'login',
		payload: data,
	};
};

const setClientAndRoles = (payload) => {
	return {
		type: 'SET_CLIENT_AND_ROLES',
		payload,
	};
};

const showLoadingIndicator = (flag) => {
	return {
		type: 'show_loading_indicator',
		payload: flag,
	};
};

const setLandingPageStatus = () => {
	return {
		type: 'LANDING_PAGE_VIEWED',
	};
};

const setAppLoadingIndicator = (payload) => {
	return {
		type: 'APP_LOADING_INDICATOR',
		payload,
	};
};

const setSessionExpired = (payload) => {
	return {
		type: 'SESSION_EXPIRED',
		payload,
	};
};

const setShowIcons = (payload) => {
	return {
		type: ENABLE_ICONS,
		payload,
	};
};

export {
	errorTextAction,
	loggedInUserData,
	showLoadingIndicator,
	setLandingPageStatus,
	setClientAndRoles,
	setAppLoadingIndicator,
	setSessionExpired,
	setShowIcons,
};
