import { Text } from '@digitalreality/ui';
import { FieldList } from './FieldList';
import MaxWidthDialog from './Dialog';

const FormModal = (props) => {
	const {
		isLoading,
		formStatus,
		formFields,
		error,
		onSubmit,
		onCancel,
		title,
		formData,
		isEdit,
		hideFooter,
		children,
		hideFields,
		removePadding,
		maxWidth,
	} = props;
	const submitButtonEnabled = Object.keys(formData).some(
		(field) => formData[field]?.value !== formData[field]?.initialValue
	);
	return (
		<MaxWidthDialog
			onCancel={onCancel}
			loading={isLoading}
			cancelButtonText={formStatus ? 'Close' : 'Cancel'}
			submitButtonDisabled={!submitButtonEnabled}
			onSubmit={!formStatus && onSubmit}
			hideFooter={hideFooter}
			removePadding={removePadding}
			maxWidth={maxWidth}
			title={title}>
			{error && (
				<Text className="text-sm text-red-400 w-full">{error}</Text>
			)}
			{!formStatus && !hideFields && (
				<FieldList
					fields={formFields}
					data={formData}
					isEdit={isEdit}
				/>
			)}
			{children}
			{formStatus && (
				<div className="flex flex-col w-full gap-3">
					<span className="text-1xl text-[#9BE938] text-start">
						{title} Successful.
					</span>
				</div>
			)}
		</MaxWidthDialog>
	);
};

export default FormModal;
