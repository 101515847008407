import { memo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const MultiSelectAuto = ({
	onChange,
	value,
	error,
	label,
	id,
	items,
	disabled,
	placeholder,
	hide,
}) => {
	return (
		<Autocomplete
			multiple
			limitTags={6}
			id={id}
			options={items}
			getOptionLabel={(option) => option?.description}
			value={value || []}
			style={{ display: hide ? 'none' : 'block' }}
			onChange={(_event, newValue) => onChange(newValue)}
			renderInput={(params) => (
				<TextField
					error={!!error}
					{...params}
					label={label}
					placeholder={placeholder}
					helperText={error}
				/>
			)}
			disabled={disabled}
		/>
	);
};

export default memo(MultiSelectAuto);
