/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import GlobeIcon23 from '../../../../Images/GlobeIcon23.png';

import 'mapbox-gl/dist/mapbox-gl.css';

const MapViewCustomTiles = ({
	selectedPolygon = undefined,
	handleMarkerSelect = () => {},
	pathCoordinates = [],
}) => {
	const mapContainerRef = useRef();
	const mapRef = useRef();
	const calculateCentroid = (coordinates) => {
		let lngSum = 0;
		let latSum = 0;
		const points = coordinates[0];
		points.forEach(([lng, lat]) => {
			lngSum += lng;
			latSum += lat;
		});
		return [lngSum / points.length, latSum / points.length];
	};

	const formate_polygon = () => {
		if (selectedPolygon) {
			return {
				type: 'Feature',
				properties: {
					id: selectedPolygon.id,
					name: selectedPolygon.name,
					fillColor: selectedPolygon.fillColor,
					fillColorOpacity: selectedPolygon.fillColorOpacity,
					strokeColor: selectedPolygon.strokeColor,
					strokeWidth: selectedPolygon.strokeWidth,
					strokeOpacity: selectedPolygon.strokeOpacity,
				},
				geometry: {
					type: 'Polygon',
					coordinates:
						selectedPolygon.only_polygon_coordinates ||
						selectedPolygon.coordinates,
				},
			};
		}
		return null;
	};
	function getBoundsFromCoordinates(coordinates) {
		let minLng = Infinity,
			minLat = Infinity;
		let maxLng = -Infinity,
			maxLat = -Infinity;

		const actual_array = coordinates[0];
		actual_array.forEach(([lng, lat]) => {
			if (lng < minLng) minLng = lng;
			if (lat < minLat) minLat = lat;
			if (lng > maxLng) maxLng = lng;
			if (lat > maxLat) maxLat = lat;
		});
		return [
			[minLng, minLat], // Southwest corner
			[maxLng, maxLat], // Northeast corner
		];
	}
	useEffect(() => {
		mapboxgl.accessToken =
			'pk.eyJ1Ijoic3Jpbml2YXNhbmFtYnVyaSIsImEiOiJjbTJpdWF2bXkwMXM4MmtxdDc5Nmh6OGVhIn0.iz4-yqZ__o9XKCqOj4Gn7w';
		const mapcenterNew = calculateCentroid(
			selectedPolygon.only_polygon_coordinates ||
				selectedPolygon.coordinates
		);
		const bounds = getBoundsFromCoordinates(
			selectedPolygon.only_polygon_coordinates ||
				selectedPolygon.coordinates
		);
		console.log('Bounds', bounds);
		mapRef.current = new mapboxgl.Map({
			container: mapContainerRef.current,
			center: mapcenterNew,
			zoom: 14.5,
			style: {
				version: 8,
				sources: {},
				layers: [],
			},
			attributionControl: false,
			interactive: false, // Disables all user interactions
			dragPan: false, // Disables panning
			scrollZoom: false, // Disables zooming on scroll
			doubleClickZoom: false, // Disables double-click zoom
			touchZoomRotate: false, // Disables touch gestures for zoom and rotate
			dragRotate: false, // Disables rotation
		});

		const el = document.createElement('div');
		el.style.width = '20px';
		el.style.height = '20px';
		(el.style.borderRadius = '10px'),
			(el.style.backgroundColor = '#ffffff');
		new mapboxgl.Marker(el).setLngLat(mapcenterNew).addTo(mapRef.current);

		mapRef.current.on('style.load', () => {
			mapRef.current.addSource('polygon-source', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [formate_polygon()],
				},
			});

			mapRef.current.addLayer({
				id: 'polygon-layer',
				type: 'fill',
				source: 'polygon-source',
			});

			mapRef.current.addLayer({
				id: 'polygon-outline-layer',
				type: 'line',
				source: 'polygon-source',
				paint: {
					'line-color': ['get', 'strokeColor'],
					'line-width': ['get', 'strokeWidth'],
					'line-opacity': ['get', 'strokeOpacity'],
				},
			});
			mapRef.current.fitBounds(bounds, {
				padding: 20, // Optional: Adds padding around the bounds
				maxZoom: 15, // Optional: Set a maximum zoom level if desired
			});
		});

		return () => mapRef.current.remove();
	}, []);

	return (
		<div
			id="map"
			ref={mapContainerRef}
			style={{ height: '100%', width: '100%' }}></div>
	);
};

export default MapViewCustomTiles;
