import {
	snakeMap,
	spiderMap,
	frogMap,
	Puma,
	Ocelot,
	Whitenosedcoati,
	redEyesFrog,
	redEyesFrogBottom,
	treefrog,
	spider,
	snake,
	lagambaChangeDetection,
	lagambaDigitalElevation,
	lagambaOperational,
	lagambaSpiderGraph,
	lagambaTls,
	lagambaWeather,
} from '../common/images';

/**
 * Dashboard Layout Data
 */
const impacts = {
	onTrack: {
		label: 'ON TRACK',
		color: '#87E78B',
	},
	requiresAttention: {
		label: 'REQUIRES ATTENTION',
		color: '#FFBB37',
	},
	alert: {
		label: 'ALERT',
		color: '#D74547',
	},
};

const natureImpacts = [
	{
		label: 'Topography & soil',
		value: impacts.onTrack,
	},
	{
		label: 'Water',
		value: impacts.onTrack,
	},
	{
		label: 'Pollution',
		value: impacts.requiresAttention,
	},
	{
		label: 'LP & IP',
		value: impacts.alert,
	},
	{
		label: 'Fauna',
		value: impacts.onTrack,
	},
	{
		label: 'Flora',
		value: impacts.requiresAttention,
	},
];

const statistics = [
	{
		label: 'Monitored area',
		value: '100 km<sup>2</sup>',
	},
	{
		label: 'Operation area',
		value: '500 hectare',
	},
	{
		label: 'Active Green cubes',
		value: '2.8 M',
	},
	{
		label: 'Avg. Forest height',
		value: '25m',
	},
	{
		label: 'Conservation projects',
		value: '2',
	},
	{
		label: 'AQI',
		value: '7',
	},
	{
		label: 'Image capture species',
		value: '123 (to date)',
	},
];

/**
 * Camera Trap Data
 */

const cameraTrapItems = [
	{
		id: 0,
		title: 'Puma concolor',
		subTitle: 'Felix concolor',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Cat',
				value: '98.2%',
			},
			{
				label: 'Felix concolor',
				value: '72.7%',
			},
		],
		video: 'La Gamba/Traps/puma_cropped.mp4',
		image: Puma,
		mapImage: frogMap,
		url: 'https://www.inaturalist.org/taxa/30537-Micrurus-mosquitensis',
	},
	{
		id: 1,
		title: 'White nosed coati',
		subTitle: 'Nasua narica',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Cat',
				value: '98.2%',
			},
			{
				label: 'Nasua narica',
				value: '72.7%',
			},
		],
		video: 'La Gamba/Traps/coati_cropped.mp4',
		image: Whitenosedcoati,
		mapImage: spiderMap,
		url: 'https://www.inaturalist.org/taxa/30537-Micrurus-mosquitensis',
	},
	{
		id: 2,
		title: 'Ocelot',
		subTitle: 'Leopardus pardalis',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Cat',
				value: '98.2%',
			},
		],
		video: 'La Gamba/Traps/ocelot_cropped.mp4',
		image: Ocelot,
		mapImage: snakeMap,
		url: 'https://www.inaturalist.org/taxa/30537-Micrurus-mosquitensis',
	},
];

/**
 * Audio Capture Layout
 */

const audioCaptureItems = [
	{
		id: 0,
		title: 'Red-eyed Tree Frog',
		subTitle: 'Agalychnis callidryas',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Frog',
				value: '98.2%',
			},
			{
				label: 'Tree frog',
				value: '72.7%',
			},
		],
		image: redEyesFrog,
		thumbnail: redEyesFrogBottom,
		mapImage: frogMap,
		audioFile: 'La Gamba/audio/FrogAudio.mp3',
	},
	{
		id: 1,
		title: 'Plain-breasted Ground Dove',
		subTitle: 'Columbina minuta',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Frog',
				value: '98.2%',
			},
			{
				label: 'Tree frog',
				value: '72.7%',
			},
		],
		image: redEyesFrogBottom,
		thumbnail: redEyesFrog,
		audioFile: 'La Gamba/audio/FrogAudio.mp3',
	},
];

const audioTrapItems = [
	{ name: 'Great Curassow', scientificName: 'Crax rubra' },
	{ name: 'Pied-billed Grebe', scientificName: 'Podilymbus podiceps' },
	{ name: 'Scaled Pigeon', scientificName: 'Patagioenas speciosa' },
	{ name: 'Band-tailed Pigeon', scientificName: 'Patagioenas fasciata' },
	{ name: 'Short-billed Pigeon', scientificName: 'Patagioenas nigrirostris' },
	{ name: 'Eurasian Collared-Dove', scientificName: 'Streptopelia decaocto' },
	{ name: 'Inca Dove', scientificName: 'Columbina inca' },
	{ name: 'Red-eyed Tree Frog', scientificName: 'Agalychnis callidryas' },
	{ name: 'Common Ground Dove', scientificName: 'Columbina passerina' },
	{ name: 'Plain-breasted Ground Dove', scientificName: 'Columbina minuta' },
	{ name: 'Ruddy Ground Dove', scientificName: 'Columbina talpacoti' },
	{ name: 'Blue Ground Dove', scientificName: 'Claravis pretiosa' },
	{ name: 'Ruddy Quail-Dove', scientificName: 'Geotrygon montana' },
	{ name: 'White-tipped Dove', scientificName: 'Leptotila verreauxi' },
];

/**
 * Latest sightings layout
 */

const latestSightingsItems = [
	{
		id: 0,
		title: 'Rosenberg’s tree frog',
		subTitle: 'Hypsiboas rosenbergi',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Frog',
				value: '98.2%',
			},
			{
				label: 'Tree frog',
				value: '72.7%',
			},
		],
		image: treefrog,
		mapImage: frogMap,
		url: 'https://www.inaturalist.org/observations/238567867',
	},
	{
		id: 1,
		title: 'Wandering spider',
		subTitle: 'Phoneutria depilata',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'Spider',
				value: '88.2%',
			},
			{
				label: 'Tarantula',
				value: '58.7%',
			},
		],
		image: spider,
		mapImage: spiderMap,
		url: 'https://www.inaturalist.org/observations?taxon_id=1247897',
	},
	{
		id: 2,
		title: "Allen's Coralsnake",
		subTitle: 'Micrurus alleni',
		aiData: [
			{
				label: 'Animal',
				value: '99.4%',
			},
			{
				label: 'snake',
				value: '98.2%',
			},
			{
				label: 'Coral snake',
				value: '67.7%',
			},
		],
		image: snake,
		mapImage: snakeMap,
		url: 'https://www.inaturalist.org/observations/238567480',
	},
];

/**
 * General
 */

const animalStatusItems = [
	{
		label: 'EX',
		value: 'EX',
	},
	{
		label: 'EW',
		value: 'EW',
	},
	{
		label: 'CR',
		value: 'CR',
	},
	{
		label: 'EN',
		value: 'EN',
	},
	{
		label: 'VU',
		value: 'VU',
	},
	{
		label: 'NT',
		value: 'NT',
	},
	{
		label: 'LC',
		value: 'LC',
	},
];

const images = {
	changeDetection: lagambaChangeDetection,
	digitalElevation: lagambaDigitalElevation,
	operational: lagambaOperational,
	spiderGraph: lagambaSpiderGraph,
	tls: lagambaTls,
	weather: lagambaWeather,
};

export {
	natureImpacts,
	statistics,
	cameraTrapItems,
	audioCaptureItems,
	audioTrapItems,
	latestSightingsItems,
	animalStatusItems,
	images,
};
