import {
	aboutForestIcon,
	greenCubesIcon,
	polygonSiteIcon,
} from '../../../components/icons/icons';
import Icon from '../../../utils/Icon';

const SponsorForestDetail = (props) => {
	const { selectedForest, callbackHandler, polygons, onPolygonSelect } =
		props;
	const forestPolygons = polygons.filter(
		(item) => item.ForestID === selectedForest.id
	);
	const items = [
		{
			label: 'About the project',
			id: 'about',
			onClick: () => callbackHandler('showAboutForest'),
			icon: aboutForestIcon,
		},
	];

	forestPolygons.forEach((polygon) =>
		items.push({
			id: polygon.id,
			label: polygon.polygon_name,
			onClick: () => onPolygonSelect(polygon),
			icon: polygonSiteIcon,
		})
	);

	return (
		<div className="animate-fadeIn flex flex-col items-center flex-1">
			<div className="flex flex-col justify-center items-center w-full gap-1">
				<span
					className="text-center font-light px-2 max-md:px-[5px]"
					style={{ fontSize: 'clamp(0.5rem, 3vw, 1.5rem)' }}>
					{selectedForest?.forestName}
				</span>
				<div className="flex items-center justify-center gap-2">
					<span
						style={{
							width: 'clamp(1.25rem,1.5vw,2.25rem)',
							height: 'clamp(1.25rem,1.5vw,2.25rem)',
						}}
						dangerouslySetInnerHTML={{
							__html: greenCubesIcon,
						}}
					/>
					<span
						className="font-normal"
						style={{ fontSize: 'clamp(0.5rem,1.5vw,1rem)' }}>
						{/* {formatNumber(selectedForest?.noOfHectars)} Hectares */}
						2.8 M Active cubes
					</span>
				</div>
			</div>
			<div className="flex flex-col items-center w-full py-16 max-md:py-4 justify-center">
				{items.map((item) => (
					<div
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						aria-label="Span"
						key={item.id}
						onClick={item.onClick}
						className="flex items-center gap-3 hover:bg-[#D7F4B1] hover:text-black w-full border-b p-[10px] px-10 max-md:p-[5px] max-md:px-[10px] cursor-pointer">
						<Icon>
							<span
								className="flex justify-center items-center"
								style={{
									width: 'clamp(1.5rem,1.5vw,2rem)',
									height: 'clamp(1.5rem,1.5vw,2rem)',
								}}
								dangerouslySetInnerHTML={{
									__html: item.icon,
								}}
							/>
						</Icon>
						<span
							className="capitalize"
							style={{ fontSize: 'clamp(0.6rem,1.5vw,1rem)' }}>
							{item.label}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default SponsorForestDetail;
