import React from 'react';
import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { ThreeDot } from 'react-loading-indicators';
import Amable from '../../Images/Amable.jpg';
import SponsorForestList from './Sponsor/SponsorForestList';
import SponsorForestDetail from './Sponsor/SponsorForestDetail';
import SponsorForestSite from './Sponsor/SponsorForestSite';
import {
	greenCubesHome,
	lagambaForestDetail,
	lagambaForestSite,
	ibitiForestDetail,
	ibitiForestSite,
	reclamationForestDetail,
	reclamationForestSite,
} from '../Screens/Explore/common/images';

const getPolygonImages = (page, pages, forestName) => {
	switch (forestName) {
		case 'La Gamba Tropenstation':
			return {
				[pages.ForestList]: greenCubesHome,
				[pages.ForestDetail]: lagambaForestDetail,
				[pages.ForestSite]: lagambaForestSite,
			}[page];
		case 'Ibiti':
			return {
				[pages.ForestList]: greenCubesHome,
				[pages.ForestDetail]: ibitiForestDetail,
				[pages.ForestSite]: ibitiForestSite,
			}[page];
		case 'Reclamation mine':
			return {
				[pages.ForestList]: greenCubesHome,
				[pages.ForestDetail]: reclamationForestDetail,
				[pages.ForestSite]: reclamationForestSite,
			}[page];
		case 'Alcoa Australia':
			return {
				[pages.ForestList]: greenCubesHome,
				[pages.ForestDetail]: reclamationForestDetail,
				[pages.ForestSite]: reclamationForestSite,
			}[page];
		default:
			return null;
	}
};

const PolygonComponent = (props) => {
	const {
		forests,
		// onSponsorCal,
		// currentPage,
		selectedPolygon,
		selectedForest,
		polygon_list,
		page,
		pages,
		handlecallbackActions,
		isPage3DataRetriving,
	} = props;

	const handleForestClick = (item) => {
		handlecallbackActions(
			'handleForestSelection',
			item,
			pages.ForestDetail
		);
	};

	const handlePolygonSelect = async (polygon) => {
		try {
			handlecallbackActions(
				'handlePolygonSelection',
				polygon,
				pages.ForestSite
			);
		} catch (error) {
			// console.log(error);
		}
	};

	const mainComponents = {
		[pages.ForestList]: (
			<SponsorForestList
				forests={forests}
				selectedForest={selectedForest}
				onForestClick={handleForestClick}
			/>
		),
		[pages.ForestDetail]: (
			<SponsorForestDetail
				selectedForest={selectedForest}
				polygons={polygon_list}
				callbackHandler={handlecallbackActions}
				onPolygonSelect={handlePolygonSelect}
			/>
		),
		[pages.ForestSite]: (
			<SponsorForestSite
				callbackHandler={handlecallbackActions}
				selectedPolygon={selectedPolygon}
				selectedForest={selectedForest}
			/>
		),
	};

	// const footerComponents = {
	// 	sponsor: (
	// 		<button
	// 			type="button"
	// 			onClick={() => onSponsorCal(selectedPolygon)}
	// 			className="flex justify-center items-center bg-[#DCFF06] h-10 w-108 rounded-[20px]">
	// 			<span className="uppercase text-sm font-normal text-[#2A2A2A]">
	// 				Sponsorship Calculator
	// 			</span>
	// 		</button>
	// 	),
	// };
	const display_forest_image = () => {
		if (selectedForest.project_icon_path) {
			return (
				<img
					alt="Project Icon path"
					src={selectedForest.project_icon_path}
					className="w-full h-full rounded-full object-scale-down border-[3px] border-solid border-[#99D74A]"
				/>
			);
		}
		return (
			<img
				alt="Amble"
				src={Amable}
				className="w-full h-full rounded-full object-cover border-[#99D74A]"
			/>
		);
	};

	return (
		<div className="flex flex-col h-screen bg-[#131314] w-full text-white overflow-hidden z-30">
			<div className="flex flex-col relative bg-[#131314]">
				<img
					src={
						page === 0
							? greenCubesHome
							: getPolygonImages(
									page,
									pages,
									selectedForest.forestName
								)
					}
					alt="Banner"
				/>
				<div className="flex relative">
					<div
						dangerouslySetInnerHTML={{
							__html: `
								<svg xmlns="http://www.w3.org/2000/svg" height="109" viewBox="0 0 400 109" fill="none" style="width: 100%; height: auto; display: block;">
									<path d="M421 31.5C421 74.3021 323.398 109 203 109C82.6019 109 -30.5 74.3021 -30.5 31.5C-30.5 -11.3021 82.6019 -46 203 -46C323.398 -46 421 -11.3021 421 31.5Z" fill="url(#paint0_linear_5217_1216)"/>
									<defs>
										<linearGradient id="paint0_linear_5217_1216" x1="-15" y1="31.5" x2="421" y2="31.5" gradientUnits="userSpaceOnUse">
										<stop stop-color="#34C42F"/>
										<stop offset="0.333333" stop-color="#B2E714"/>
										<stop offset="0.645833" stop-color="#F3F424"/>
										<stop offset="1" stop-color="#C4C5A6"/>
										</linearGradient>
									</defs>
								</svg>	
							`,
						}}
					/>
					<div className="w-[35%] aspect-square rounded-full absolute left-1/2 top-full transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center overflow-hidden bg-[#ffffff]">
						{display_forest_image()}
					</div>
				</div>
				{page > 0 && (
					<div
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						aria-label="Span"
						className="animate-fadeIn absolute cursor-pointer h-5 w-5 sm:h-8 sm:w-8 left-0 m-2 flex flex-col items-center justify-center bg-[#000000]/[.6] rounded-full"
						onClick={() =>
							handlecallbackActions('setPage', {}, page - 1)
						}>
						<ArrowBackIosNewOutlined
							className="text-white"
							style={{ fontSize: 'clamp(0.75rem,1.5vw,1.25rem)' }}
						/>
					</div>
				)}
			</div>
			<div className="flex flex-col flex-1 mt-[72px] max-md:mt-[36px] max-lg:mt-[60px] overflow-auto">
				{isPage3DataRetriving ? (
					<div className="flex justify-center items-center mt-40">
						<ThreeDot
							color="#32cd32"
							size="medium"
						/>
					</div>
				) : (
					mainComponents[page]
				)}
			</div>
			{/* {(currentPage === 'sponsor' || footerComponents[page]) && (
				<div className="bg-[#77d721] flex items-center min-h-16 justify-center shrink-0 overflow-hidden">
					{currentPage === 'sponsor'
						? footerComponents.sponsor
						: footerComponents[page]}
				</div>
			)} */}
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(PolygonComponent);
