const coreStyles = {
	buttonStyle: {
		backgroundColor: '#9BE938',
		borderRadius: 100,
		fontSize: 12,
		paddingLeft: 36,
		paddingRight: 36,
	},
};

const getStyles = (contextStyles = {}, variant = 'primary') => {
	const backgroundColors = {
		danger: '#ef4444',
		primary: contextStyles.button_bg_color,
	};
	return {
		buttonStyle: {
			...coreStyles.buttonStyle,
			fontSize: contextStyles.button_font_size,
			backgroundColor:
				backgroundColors[variant] ||
				contextStyles.button_bg_secondary_color,
			color: contextStyles.button_text_color,
			fontWeight: contextStyles.button_font_weight,
		},
	};
};
export { getStyles };
