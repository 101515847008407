import MiniMap from '../../MYVR/MockScreens/MiniMap';
import { camera360, Lidarscan } from '../common/images';

const items = [
	{
		label: 'Lidar Scan',
		value: 'Lidar',
		showVisulization: false,
		image: Lidarscan,
	},
	{
		label: '360 Video',
		value: 'Video',
		showVisulization: true,
		image: camera360,
	},
];

const Camera360Sidebar = ({
	showVisulization = false,
	selectedPolygon = undefined,
	handleVisulizationSelection = () => {},
}) => {
	const handleSelection = (selectedItem) => {
		if (selectedItem === 'Lidar') {
			handleVisulizationSelection(false);
		} else {
			handleVisulizationSelection(true);
		}
	};
	return (
		<div className="flex flex-col overflow-hidden gap-[10px] max-h-full flex-1">
			<div className="flex w-full gap-[10px] h-[50%]">
				{items.map((item) => (
					<div
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						aria-label="Span"
						className={`flex flex-col items-center justify-between bg-[#1E1E21] rounded-[5px] p-2 flex-1 w-full h-full overflow-hidden ${item.showVisulization === showVisulization ? 'border-2 border-solid box-border border-[#5D5D5D]' : ''} `}
						onClick={() => handleSelection(item.value)}
						key={item.label}>
						<div className="flex flex-col justify-center shrink h-3/4 mt-4">
							<img
								className="object-contain h-3/4"
								src={item.image}
								alt={item.label}
							/>
						</div>
						<span className="shrink-0 text-[#BFBFBF] text-lg">
							{item.label}
						</span>
					</div>
				))}
			</div>
			<div className="flex w-full gap-[10px] h-[50%]">
				<MiniMap selectedPolygon={selectedPolygon} />
			</div>
		</div>
	);
};

export default Camera360Sidebar;
