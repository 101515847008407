import { useContext } from 'react';
// eslint-disable-next-line import/no-unresolved
import { Tweet } from 'react-tweet';
import {
	flightIcon,
	forestNews,
	keyStatistics,
	latestTLS,
	messagesIcon,
	satelliteIcon,
	stateOfNatureImpacts,
} from '../../../../components/icons/icons';
import ExploreWidget from '../common/ExploreWidget';
import { minePlan, meLogo, hxdrLogo } from '../common/images';
import ForestContext from '../utils/ForestContext';

const DashboardLayout = (props) => {
	const { onChangeDetection } = props;
	const { natureImpacts, statistics, images } = useContext(ForestContext);
	return (
		<div className="flex flex-col flex-1 bg-[#131314] h-full w-full justify-between gap-[10px]">
			<div className="flex gap-[10px] h-[30%] shrink-0">
				<div className="flex-1 h-full">
					<ExploreWidget
						title="Change Detection"
						icon={satelliteIcon}
						onExplore={onChangeDetection}>
						<img
							className="w-full h-full object-cover"
							src={images?.changeDetection}
							alt="Change Detection"
						/>
					</ExploreWidget>
				</div>
				<div className="flex-1 h-full">
					<ExploreWidget
						title="Digital elevation model"
						icon={flightIcon}>
						<img
							className="w-full h-full object-cover"
							src={images?.digitalElevation}
							alt="Digital Elevation"
						/>
					</ExploreWidget>
				</div>
				<div className="flex-1 h-full">
					<ExploreWidget
						title="latest TLS"
						icon={latestTLS}>
						<img
							className="w-full h-full object-cover"
							src={images?.tls}
							alt="latest TLS"
						/>
					</ExploreWidget>
				</div>
			</div>
			<div className="flex gap-[10px] h-[30%] shrink-0">
				<div className="w-1/3 h-full">
					<ExploreWidget
						title="key statistics"
						icon={keyStatistics}>
						<div className="flex flex-col w-full gap-1">
							{statistics.map((item) => (
								<div
									className="flex items-center w-full"
									key={item.label}>
									<span
										className="w-2/3 text-sm font-bold"
										style={{
											fontSize:
												'clamp(0.5rem,1.5vw,0.85rem)',
										}}>
										{item.label}
									</span>
									<span
										className="w-1/3 text-sm font-light"
										dangerouslySetInnerHTML={{
											__html: item.value,
										}}
									/>
								</div>
							))}
						</div>
					</ExploreWidget>
				</div>
				<div className="w-2/3 h-full">
					<ExploreWidget
						title="state of nature & impacts"
						icon={stateOfNatureImpacts}>
						<div className="flex justify-between items-center overflow-hidden flex-1 w-full h-full px-10 gap-10">
							<div className="flex flex-col h-full w-[40%] gap-1 overflow-auto shrink-0">
								<span
									className="text-lg font-bold"
									style={{
										fontSize: 'clamp(0.5rem,1.5vw,0.85rem)',
									}}>
									YEAR 2
								</span>
								{natureImpacts.map((item) => (
									<div
										key={item.label}
										className="flex items-center">
										<span className="w-1/2 text-xs">
											{item.label}
										</span>
										<span
											className="flex-1 text-center rounded text-[8px] font-bold text-black p-1"
											style={{
												backgroundColor:
													item.value.color,
											}}>
											{item.value.label}
										</span>
									</div>
								))}
							</div>
							<img
								className="h-full w-[40%] object-contain shrink"
								src={images?.spiderGraph}
								alt="Spider Graph"
							/>
						</div>
					</ExploreWidget>
				</div>
			</div>
			<div className="flex gap-[10px] h-full shrink overflow-hidden">
				<div className="flex flex-col w-[10%] gap-[10px] rounded-[10px] overflow-hidden">
					<ExploreWidget hideHeader>
						<div className="flex items-center">
							<img
								className="object-cover w-full h-full"
								src={minePlan}
								alt="mine plan"
							/>
							<span className="text-xl leading-5 font-normal">
								Mine
								<br />
								Plan
							</span>
						</div>
					</ExploreWidget>
					<ExploreWidget hideHeader>
						<div className="flex items-center">
							<img
								className="object-cover w-full h-full"
								src={meLogo}
								alt="me"
							/>
						</div>
					</ExploreWidget>
					<ExploreWidget hideHeader>
						<div className="flex items-center">
							<img
								className="object-cover w-full h-full"
								src={hxdrLogo}
								alt="hxdr"
							/>
						</div>
					</ExploreWidget>
				</div>
				<div className="flex w-[40%] overflow-hidden">
					<ExploreWidget
						title="SCIENCE UPDATES"
						icon={messagesIcon}>
						<div
							data-theme="dark"
							className="flex-1 m-auto flex flex-col gap-1">
							<Tweet id="1824431871544946918" />
							<div className="h-[1px] bg-black rounded-full" />
						</div>
					</ExploreWidget>
				</div>
				<div className="flex w-[50%] justify-center">
					<ExploreWidget
						title="Forest News"
						icon={forestNews}>
						<div
							data-theme="dark"
							className="flex-1 m-auto">
							<Tweet id="1829079990811463759" />
						</div>
					</ExploreWidget>
				</div>
			</div>
		</div>
	);
};

export default DashboardLayout;
