import React, { useEffect, useRef, useState } from 'react';
import { Storage } from 'aws-amplify';
import { CircularProgress } from '@mui/material';
import {
	musicBackIcon,
	musicNextIcon,
	musicPauseIcon,
	musicPlayIcon,
	musicProgressIcon,
	musicStaticIcon,
} from '../../../../components/icons/icons';

const MusicPlayer = ({ src }) => {
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [audio, setAudio] = useState(null);

	useEffect(() => {
		const getAudio = async () => {
			setIsLoading(true);
			try {
				const res = await Storage.get(src);
				setAudio(res);
			} catch (e) {
				/* empty */
			} finally {
				setIsLoading(false);
			}
		};

		getAudio();
	}, [src]);

	const togglePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	return (
		<div className="bg-[#131314] w-52 h-40 rounded-3xl p-4 flex flex-col justify-around">
			{isLoading ? (
				<div className="flex-1 flex justify-center items-center">
					<CircularProgress
						thickness={1}
						style={{ color: '#D7F4B1' }}
						size={60}
					/>
				</div>
			) : (
				<>
					<audio
						ref={audioRef}
						src={audio}
					/>
					<div className="flex justify-center items-center">
						<span
							dangerouslySetInnerHTML={{
								__html: isPlaying
									? musicProgressIcon
									: musicStaticIcon,
							}}
						/>
					</div>
					<div className="flex items-center justify-around">
						<span
							className="cursor-pointer"
							dangerouslySetInnerHTML={{
								__html: musicBackIcon,
							}}
						/>
						<span
							role="button"
							tabIndex="0"
							onKeyDown={() => {}}
							aria-label="Span"
							className="cursor-pointer hover:bg-[#5D5D5D] rounded-full text-[#BFBFBF]"
							onClick={() => audio && togglePlayPause()}
							dangerouslySetInnerHTML={{
								__html: isPlaying
									? musicPauseIcon
									: musicPlayIcon,
							}}
						/>
						<span
							className="cursor-pointer"
							dangerouslySetInnerHTML={{
								__html: musicNextIcon,
							}}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default MusicPlayer;
