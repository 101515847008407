import GreenCubeMiniLogo from '../../../Images/GreenCubeMiniLogo.png';
import { formatNumber } from '../../../utils/utils';
import CheckoutMain from './CheckoutMain';

const getTwoWeeksLater = () => {
	const today = new Date(); // Today's date

	// Add 15 days to today's date
	const fifteenDaysLater = new Date(today.setDate(today.getDate() + 15));

	// Define month names array (adjust to your language or format)
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	// Format the date as '17 August 2024' (adjust to your desired format)
	// const formattedDate = `${oneMonthLater.getDate()} ${monthNames[oneMonthLater.getMonth()]} ${oneMonthLater.getFullYear()}`;
	const formattedDate = `${fifteenDaysLater.getDate()} ${monthNames[fifteenDaysLater.getMonth()]} ${fifteenDaysLater.getFullYear()}`;

	return formattedDate;
};

const ShoppingCart = (props) => {
	const { onCancel, onSubmit, selectedPolygon, data, isPurchaseError } =
		props;
	const items = [
		{
			label: 'Sponsorship',
			value: `${formatNumber(data?.numberOfCubes * Number(selectedPolygon?.CubePrice))} USD`,
		},
		{ label: 'Holding period', value: '2 Weeks' },
		{ label: 'Holding expiry date', value: getTwoWeeksLater() },
	];
	return (
		<CheckoutMain
			title="Shopping Cart"
			cancelButtonText="cancel"
			submitButtonText="next"
			onCancel={onCancel}
			onSubmit={onSubmit}>
			<div className="flex flex-col items-center flex-1 w-full justify-around text-black gap-4 py-6">
				<div className="flex items-center gap-3 text-start">
					<img
						alt="Mini Logo"
						src={GreenCubeMiniLogo}
						className="w-8 h-8"
					/>
					<span className="text-black text-2xl font-medium">
						{formatNumber(data?.numberOfCubes)}
					</span>
					<span>Green Cubes</span>
				</div>
				<div className="flex flex-col flex-1 w-2/5 gap-2 m-auto">
					{isPurchaseError?.status && (
						<span className="text-red">{`Error - ${isPurchaseError?.message}`}</span>
					)}
					{items.map((item) => (
						<div
							key={item.label}
							className="flex justify-between items-center gap-2">
							<span className="font-bold flex-1 text-sm text-nowrap">
								{item.label}:
							</span>
							<span className="text-right flex-1 text-sm">
								{item.value}
							</span>
						</div>
					))}
				</div>
			</div>
		</CheckoutMain>
	);
};

export default ShoppingCart;
