/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import GlobeIcon23 from '../../../../Images/GlobeIcon23.png';

import 'mapbox-gl/dist/mapbox-gl.css';

const MapViewCustomTiles = ({
	selectedPolygon = undefined,
	handlecallbackActions = () => {},
}) => {
	const mapContainerRef = useRef();
	const mapRef = useRef();

	const handlePolygonOnClick = () => {
		handlecallbackActions('handleSinglePolygonClick');
	};

	const calculateCentroid = (coordinates) => {
		let lngSum = 0;
		let latSum = 0;
		const points = coordinates[0];
		points.forEach(([lng, lat]) => {
			lngSum += lng;
			latSum += lat;
		});
		return [lngSum / points.length, latSum / points.length];
	};

	const formate_polygon = () => {
		if (selectedPolygon) {
			return {
				type: 'Feature',
				properties: {
					id: selectedPolygon.id,
					name: selectedPolygon.name,
					fillColor: selectedPolygon.fillColor,
					fillColorOpacity: selectedPolygon.fillColorOpacity,
					strokeColor: selectedPolygon.strokeColor,
					strokeWidth: selectedPolygon.strokeWidth,
					strokeOpacity: selectedPolygon.strokeOpacity,
				},
				geometry: {
					type: 'Polygon',
					coordinates:
						selectedPolygon.only_polygon_coordinates ||
						selectedPolygon.coordinates,
				},
			};
		}
		return null;
	};

	useEffect(() => {
		mapboxgl.accessToken =
			'pk.eyJ1Ijoic3Jpbml2YXNhbmFtYnVyaSIsImEiOiJjbTJpdWF2bXkwMXM4MmtxdDc5Nmh6OGVhIn0.iz4-yqZ__o9XKCqOj4Gn7w';

		const mapcenterNew = calculateCentroid(
			selectedPolygon.only_polygon_coordinates ||
				selectedPolygon.coordinates
		);
		mapRef.current = new mapboxgl.Map({
			container: mapContainerRef.current,
			zoom: 15,
			minZoom: 8,
			maxZoom: 15,
			center: mapcenterNew,
			style: 'mapbox://styles/mapbox/satellite-v9',
			pitch: 60,
		});

		mapRef.current.on('style.load', () => {
			const el = document.createElement('div');
			el.style.backgroundImage = `url(${GlobeIcon23})`;
			el.style.width = '30px';
			el.style.height = '30px';
			el.style.backgroundSize = 'cover';

			el.onclick = () => {
				console.log('clicked element');
				handlePolygonOnClick();
			};

			new mapboxgl.Marker(el)
				.setLngLat(mapcenterNew)
				.addTo(mapRef.current);

			mapRef.current.addSource('polygon-source', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [formate_polygon()],
				},
			});

			mapRef.current.addLayer({
				id: 'polygon-layer',
				type: 'fill',
				source: 'polygon-source',
				paint: {
					'fill-color': ['get', 'fillColor'],
					'fill-opacity': ['get', 'fillColorOpacity'],
				},
			});

			mapRef.current.addLayer({
				id: 'polygon-outline-layer',
				type: 'line',
				source: 'polygon-source',
				paint: {
					'line-color': ['get', 'strokeColor'],
					'line-width': ['get', 'strokeWidth'],
					'line-opacity': ['get', 'strokeOpacity'],
				},
			});
			mapRef.current.setTerrain({
				source: 'mapbox-dem',
				exaggeration: 1.5,
			});
		});

		return () => mapRef.current.remove();
	}, []);

	return (
		<>
			<div
				id="map"
				ref={mapContainerRef}
				style={{ height: '100%' }}></div>
		</>
	);
};

export default MapViewCustomTiles;
