/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLoggedInUserData = /* GraphQL */ `
	query GetLoggedInUserData($email: String!) {
		getLoggedInUserData(email: $email) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			listofinvitationRoles
			listofinvitationClients
			clientType
			listOfAccesibleForest
			__typename
		}
	}
`;
export const refreshUserData = /* GraphQL */ `
	query RefreshUserData($userRole: String!, $clientName: String) {
		refreshUserData(userRole: $userRole, clientName: $clientName) {
			listofinvitationRoles
			listofinvitationClients
			listofForestName
			listofForestIDs
			listofForestNameMain
			listofForestIDsMain
			listofForestNameMainCountries
			list_forest_data_consolidated {
				forestID
				ForestName
				__typename
			}
			__typename
		}
	}
`;
export const duplicateCheck = /* GraphQL */ `
	query DuplicateCheck(
		$tableName: String
		$columns: [String]
		$enteredvalues: [String]
	) {
		duplicateCheck(
			tableName: $tableName
			columns: $columns
			enteredvalues: $enteredvalues
		)
	}
`;
export const getPolygonCompletePurchaseData = /* GraphQL */ `
	query GetPolygonCompletePurchaseData(
		$poly_id: String
		$user_client_id: String
	) {
		getPolygonCompletePurchaseData(
			poly_id: $poly_id
			user_client_id: $user_client_id
		) {
			cubes_available
			cubes_already_sold
			cubes_notavailable
			cubes_available_inpercentage
			cubes_already_inpercentage
			cubes_notavailable_inpercentage
			__typename
		}
	}
`;
export const validate_purchase_order = /* GraphQL */ `
	query Validate_purchase_order(
		$poly_id: String
		$user_client_id: String
		$currentOrderCubes: String
	) {
		validate_purchase_order(
			poly_id: $poly_id
			user_client_id: $user_client_id
			currentOrderCubes: $currentOrderCubes
		)
	}
`;
export const getForestMainTable = /* GraphQL */ `
	query GetForestMainTable($id: ID!) {
		getForestMainTable(id: $id) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const listForestMainTables = /* GraphQL */ `
	query ListForestMainTables(
		$filter: ModelForestMainTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		listForestMainTables(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				ForestName
				id
				ForestCountry
				lat
				lon
				createdBy
				Status
				icon
				noOfHectars
				about_project_pdf_url
				project_icon_path
				project_map_icon
				agent_emails
				support_emails
				approver_emails
				createdAt
				updatedAt
				audit_client_msg
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const forestMainTablesByForestNameAndCreatedBy = /* GraphQL */ `
	query ForestMainTablesByForestNameAndCreatedBy(
		$ForestName: ID!
		$createdBy: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelForestMainTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		forestMainTablesByForestNameAndCreatedBy(
			ForestName: $ForestName
			createdBy: $createdBy
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				ForestName
				id
				ForestCountry
				lat
				lon
				createdBy
				Status
				icon
				noOfHectars
				about_project_pdf_url
				project_icon_path
				project_map_icon
				agent_emails
				support_emails
				approver_emails
				createdAt
				updatedAt
				audit_client_msg
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getForestsData = /* GraphQL */ `
	query GetForestsData($id: ID!) {
		getForestsData(id: $id) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listForestsData = /* GraphQL */ `
	query ListForestsData(
		$filter: ModelForestsDataFilterInput
		$limit: Int
		$nextToken: String
	) {
		listForestsData(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				polygon_name
				ForestName
				ForestID
				NoOfCubes
				CubePrice
				createdBy
				Status
				lat
				lon
				baseline_report_path
				area_WEBGL_cubified_file_path
				area_MYVR_scene_file_path
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getEmailConfig = /* GraphQL */ `
	query GetEmailConfig($id: ID!) {
		getEmailConfig(id: $id) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listEmailConfigs = /* GraphQL */ `
	query ListEmailConfigs(
		$filter: ModelEmailConfigFilterInput
		$limit: Int
		$nextToken: String
	) {
		listEmailConfigs(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				title
				emailList
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getAuditTrail = /* GraphQL */ `
	query GetAuditTrail($id: ID!) {
		getAuditTrail(id: $id) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listAuditTrails = /* GraphQL */ `
	query ListAuditTrails(
		$filter: ModelAuditTrailFilterInput
		$limit: Int
		$nextToken: String
	) {
		listAuditTrails(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				ActionType
				RecordID
				ModuleName
				PerformedUserClient
				PerformedBy
				PerformedAt
				Message
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const auditTrailsByIdAndPerformedAt = /* GraphQL */ `
	query AuditTrailsByIdAndPerformedAt(
		$id: ID!
		$PerformedAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelAuditTrailFilterInput
		$limit: Int
		$nextToken: String
	) {
		auditTrailsByIdAndPerformedAt(
			id: $id
			PerformedAt: $PerformedAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				ActionType
				RecordID
				ModuleName
				PerformedUserClient
				PerformedBy
				PerformedAt
				Message
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getSupportTicketsTable = /* GraphQL */ `
	query GetSupportTicketsTable($id: ID!) {
		getSupportTicketsTable(id: $id) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listSupportTicketsTables = /* GraphQL */ `
	query ListSupportTicketsTables(
		$filter: ModelSupportTicketsTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		listSupportTicketsTables(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				ticketCreatedBy
				ticketCreatedOn
				tickerCreatedClientName
				ticketMainForestName
				ticketComments
				ticketRefNumber
				ticketStatus
				contactEmail
				contactPhone
				meetingDateAndTime
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getPurchaseInvoices = /* GraphQL */ `
	query GetPurchaseInvoices($id: ID!) {
		getPurchaseInvoices(id: $id) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const listPurchaseInvoices = /* GraphQL */ `
	query ListPurchaseInvoices(
		$filter: ModelPurchaseInvoicesFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPurchaseInvoices(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				purchasedForestMainName
				purchasedForestMainID
				purchasedForestArea
				purchasedForestAreaID
				purchasedByClientID
				purchasedByClientName
				purchaseInvoiceCost
				purchasedCubesCount
				purchasedInvoicePath
				purchasedInvoiceNumber
				purchasedInvoiceCreatedon
				purchaseOrderRaisedBy
				createdAt
				updatedAt
				audit_client_msg
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getInvitationTable = /* GraphQL */ `
	query GetInvitationTable($id: ID!) {
		getInvitationTable(id: $id) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listInvitationTables = /* GraphQL */ `
	query ListInvitationTables(
		$filter: ModelInvitationTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		listInvitationTables(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				emailId
				firstName
				clientName
				clientId
				clientRole
				invitedBy
				invitationStatus
				invitationCurrentStatus
				invited_from_domain
				audit_client_msg
				id
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getUserData = /* GraphQL */ `
	query GetUserData($id: ID!) {
		getUserData(id: $id) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listUserData = /* GraphQL */ `
	query ListUserData(
		$filter: ModelUserDataFilterInput
		$limit: Int
		$nextToken: String
	) {
		listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				emailId
				firstName
				lastName
				phoneNumber
				clientId
				clientName
				assignedRole
				profilePicURL
				profileStatus
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getMedia = /* GraphQL */ `
	query GetMedia($id: ID!) {
		getMedia(id: $id) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listMedia = /* GraphQL */ `
	query ListMedia(
		$filter: ModelMediaFilterInput
		$limit: Int
		$nextToken: String
	) {
		listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				ForestName
				ForestID
				SiteName
				SiteID
				MediaPath
				MediaType
				createdBy
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getGEOJsonMapper = /* GraphQL */ `
	query GetGEOJsonMapper($id: ID!) {
		getGEOJsonMapper(id: $id) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listGEOJsonMappers = /* GraphQL */ `
	query ListGEOJsonMappers(
		$filter: ModelGEOJsonMapperFilterInput
		$limit: Int
		$nextToken: String
	) {
		listGEOJsonMappers(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				forestID
				forestName
				siteName
				siteID
				siteCubicMetersArea
				siteGreenCubes
				siteGEOJson
				createdBy
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getPDFReports = /* GraphQL */ `
	query GetPDFReports($id: ID!) {
		getPDFReports(id: $id) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listPDFReports = /* GraphQL */ `
	query ListPDFReports(
		$filter: ModelPDFReportsFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPDFReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				clientID
				pdfName
				pdfStorageURL
				createdBy
				status
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getClientsTable = /* GraphQL */ `
	query GetClientsTable($id: ID!) {
		getClientsTable(id: $id) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listClientsTables = /* GraphQL */ `
	query ListClientsTables(
		$filter: ModelClientsTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		listClientsTables(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				clientName
				clientContact
				clientAddress
				clientStreetNumber
				clientSuitNumber
				clientCity
				clientCountry
				clientVAT
				clientProfilePicURL
				clientId
				createdBy
				clientSatus
				clienttype
				listOfAccesibleForest
				audit_client_msg
				id
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getPurchaseOrders = /* GraphQL */ `
	query GetPurchaseOrders($id: ID!) {
		getPurchaseOrders(id: $id) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listPurchaseOrders = /* GraphQL */ `
	query ListPurchaseOrders(
		$filter: ModelPurchaseOrdersFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPurchaseOrders(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				purchasedClientName
				purchasedClientID
				purchaseddBy
				purchasedForestName
				purchasedForestID
				purchasedForestArea
				purchasedForestAreaID
				purchasedNoOfCubes
				purchasedCubePrice
				purchasedInvoiceNumber
				purchasedInvoiceCost
				purchaseStatus
				purchasedOn
				purchaseComments
				purchasedInvoicePath
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getRolesTable = /* GraphQL */ `
	query GetRolesTable($id: ID!) {
		getRolesTable(id: $id) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listRolesTables = /* GraphQL */ `
	query ListRolesTables(
		$filter: ModelRolesTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		listRolesTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const rolesTablesByIdAndRoleName = /* GraphQL */ `
	query RolesTablesByIdAndRoleName(
		$id: ID!
		$roleName: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelrolesTableFilterInput
		$limit: Int
		$nextToken: String
	) {
		rolesTablesByIdAndRoleName(
			id: $id
			roleName: $roleName
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
export const getUserRegistrationValidationResponse = /* GraphQL */ `
	query GetUserRegistrationValidationResponse($id: ID!) {
		getUserRegistrationValidationResponse(id: $id) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const listUserRegistrationValidationResponses = /* GraphQL */ `
	query ListUserRegistrationValidationResponses(
		$filter: ModelUserRegistrationValidationResponseFilterInput
		$limit: Int
		$nextToken: String
	) {
		listUserRegistrationValidationResponses(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				clientID
				clientName
				clientRole
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;
