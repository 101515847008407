const SponsorWidget = ({ title, icon, children }) => {
	return (
		<div className="flex flex-col overflow-auto">
			<div className="flex gap-1 items-center justify-start">
				<span
					className="h-10 w-10 p-2 box-border flex items-center justify-center"
					dangerouslySetInnerHTML={{ __html: icon }}
				/>
				<span className="text-[#eeeeee] text-base font-bold uppercase">
					{title}
				</span>
			</div>
			{children}
		</div>
	);
};
export default SponsorWidget;
