import React, { useState } from 'react';
import { ToggleOnOutlined, ToggleOffOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, CircularProgress } from '@digitalreality/ui';

import * as mutations from '../../../graphql/mutations';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const PDFActionRenderer = (props) => {
	const { value, onError } = props;
	const [isLoading, setIsLoading] = useState(false);

	const handleAction = async () => {
		setIsLoading(true);
		const data = {
			id: props?.data?.id,
			status: value === 'Active' ? 'Inactive' : 'Active',
		};
		try {
			await makeGraphQLRequest({
				query: mutations.updatePDFReports,
				variables: { input: data },
			});
			onError('');
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<span className="flex flex-col items-center justify-center h-full">
			{!isLoading ? (
				<IconButton onClick={handleAction}>
					{value === 'Active' ? (
						<Tooltip title="Disable PDF">
							<ToggleOnOutlined className="text-sky-500" />
						</Tooltip>
					) : (
						<Tooltip title="Enable PDF">
							<ToggleOffOutlined className="text-gray-500" />
						</Tooltip>
					)}
				</IconButton>
			) : (
				<CircularProgress
					thickness={4}
					style={{ color: '#0ea5e9' }}
					size={20}
				/>
			)}
		</span>
	);
};

export default PDFActionRenderer;
