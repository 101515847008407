import CheckoutMain from './CheckoutMain';

const ContactRequest = (props) => {
	const { onSubmit } = props;
	return (
		<CheckoutMain
			title="Contact request"
			submitButtonText="close"
			onSubmit={onSubmit}>
			<div className="flex flex-col items-center flex-1 w-full justify-around text-black gap-4 p-10">
				<span className="text-sm font-normal">
					Thank you for reaching out, an agent will be in contact
					shortly
				</span>
			</div>
		</CheckoutMain>
	);
};

export default ContactRequest;
