import { useState } from 'react';
import {
	changeDetection2017,
	changeDetection2019,
	changeDetection2021,
	changeDetection2023,
} from '../common/images';
import VariationYear from '../common/VariationByYear';
import ImageSlider from './ImageSlider';

const yearList = [
	{
		label: '2017',
		image: changeDetection2017,
	},
	{
		label: '2019',
		image: changeDetection2019,
	},
	{
		label: '2021',
		image: changeDetection2021,
	},
	{
		label: '2023',
		image: changeDetection2023,
	},
];

const ChangeDetectionLayout = () => {
	const [leftChangeImage, setLeftChangeImage] = useState(yearList[1]);
	const [rightChangeImage, setRightChangeImage] = useState(yearList[2]);
	// const [onSliderMode, setOnSilderMode] = useState(false);
	// const [selectToggle, setSelectToggle] = useState('');
	return (
		<div className="flex flex-col px-48 flex-1 h-full overflow-hidden">
			<div className="flex flex-col">
				<span className="text-[#eeeeee] text-base leading-6 font-bold uppercase">
					NDVI METRIC
				</span>
				{/* prettier-ignore */}
				<span className="text-[#eeeeee] text-xs font-normal text-wrap whitespace-pre break-words w-2/3">
				The normalized difference vegetation index (NDVI) is a widely-used metric for
                quantifying the health and density of vegetation using sensor data.
			    </span>
			</div>
			<div className="flex space-between w-full m-4">
				<VariationYear
					years={yearList}
					defaultSelection={yearList[1].label}
					onChangeDectectionClick={(item) => setLeftChangeImage(item)}
				/>
				<VariationYear
					years={yearList}
					defaultSelection={yearList[2].label}
					onChangeDectectionClick={(item) =>
						setRightChangeImage(item)
					}
				/>
			</div>
			<div className="flex flex-1 gap-8 overflow-hidden">
				<ImageSlider
					image1={leftChangeImage?.image}
					image2={rightChangeImage?.image}
				/>
			</div>
		</div>
	);
};

export default ChangeDetectionLayout;
