// eslint-disable-next-line default-param-last
export const letsGoStatus = (state = 'Home', action) => {
	switch (action.type) {
		case 'letsGo-status':
			return action.payload;
		case 'clear':
			return 'Home';
		default:
			return state;
	}
};
