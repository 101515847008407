import { CircularProgress } from '@mui/material';
import { Storage } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { musicPlayIcon } from '../../../../components/icons/icons';

const VideoPlayer = ({ videoStorageKey }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [video, setVideo] = useState(null);
	const [showCustomControl, setShowCustomControl] = useState(true);
	const videoRef = useRef(null);

	useEffect(() => {
		const getVideo = async () => {
			setIsLoading(true);

			try {
				setIsLoading(true);
				const res = await Storage.get(videoStorageKey);
				setVideo(res);
			} catch (e) {
				//
			} finally {
				setIsLoading(false);
			}
		};

		getVideo();
	}, [videoStorageKey]);

	return (
		<div className="flex flex-1 h-full relative">
			{isLoading ? (
				<div className="flex-1 flex justify-center items-center">
					<CircularProgress
						thickness={1}
						style={{ color: '#D7F4B1' }}
						size={60}
					/>
				</div>
			) : (
				<div className="flex flex-1 relative">
					<video
						controls={!showCustomControl}
						ref={videoRef}
						onPause={() => setShowCustomControl(true)}
						className="flex flex-1 object-fill">
						<source src={video} />
						Your browser does not support the video tag.
					</video>
					{showCustomControl && (
						<div
							role="button"
							tabIndex="0"
							onKeyDown={() => {}}
							aria-label="Span"
							className="absolute top-0 left-0 flex flex-1 w-full h-full justify-center items-center"
							onClick={() => {
								videoRef.current.play();
								setShowCustomControl(false);
							}}>
							<div className="flex bg-white/40 p-3 rounded-full cursor-pointer">
								<span
									dangerouslySetInnerHTML={{
										__html: musicPlayIcon,
									}}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default VideoPlayer;
