import React, { useEffect, useRef, useState } from 'react';
import {
	graphIcon,
	sliderIcon,
	miniSliderIcon,
} from '../../../../components/icons/icons';
import { spiderMap } from '../common/images';

const ImageSlider = ({ image1, image2 }) => {
	const [sliderPosition, setSliderPosition] = useState(50); // Initial slider position at 50%
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const [isDragging, setIsDragging] = useState(false); // State to check if dragging is happening
	const [onSliderMode, setOnSilderMode] = useState(true);
	const [selectToggle, setSelectToggle] = useState('');
	const [graphMode, setGraphMode] = useState(false);
	const containerRef = useRef(null);

	// Calculate and set the dimensions of the image container on mount and resize
	useEffect(() => {
		const updateDimensions = () => {
			if (containerRef.current) {
				const { offsetWidth: width, offsetHeight: height } =
					containerRef.current;
				setDimensions({ width, height });
			}
		};

		updateDimensions();
		window.addEventListener('resize', updateDimensions);

		return () => {
			window.removeEventListener('resize', updateDimensions);
		};
	}, []);

	// Handle slider move on click and drag
	const handleSliderMove = (clientX) => {
		if (containerRef.current) {
			const sliderWidth = containerRef.current.offsetWidth;
			const offsetX =
				clientX - containerRef.current.getBoundingClientRect().left;
			const newSliderPosition = Math.max(
				0,
				Math.min((offsetX / sliderWidth) * 100, 100)
			);
			setSliderPosition(newSliderPosition);
		}
	};

	// Start dragging when clicking on the leftSliderIcon element
	const handleStartDrag = (event) => {
		setIsDragging(true);
		handleSliderMove(event.clientX);
	};

	// Stop dragging on mouseup or touchend
	const handleStopDrag = () => {
		setIsDragging(false);
	};

	// Add event listeners for mouse and touch when dragging starts
	useEffect(() => {
		const handleMove = (event) => {
			if (isDragging) {
				if (event.type === 'mousemove') {
					handleSliderMove(event.clientX);
				}
			}
		};
		if (isDragging) {
			window.addEventListener('mousemove', handleMove);
			window.addEventListener('mouseup', handleStopDrag);
		} else {
			window.removeEventListener('mousemove', handleMove);
			window.removeEventListener('mouseup', handleStopDrag);
		}
		return () => {
			window.removeEventListener('mousemove', handleMove);
			window.removeEventListener('mouseup', handleStopDrag);
		};
	}, [isDragging]);

	return (
		<div className="flex gap-4 flex-1 h-full overflow-hidden">
			<div className="flex flex-col gap-4 flex-1 overflow-hidden py-4">
				<div className="w-full relative shrink-0">
					<span
						className="flex cursor-ew-resize"
						style={{
							visibility:
								onSliderMode && !graphMode
									? 'visible'
									: 'hidden',
							paddingLeft:
								sliderPosition > 99
									? '99%'
									: `${sliderPosition}%`,
						}}
						dangerouslySetInnerHTML={{ __html: sliderIcon }}
						onMouseDown={handleStartDrag}
						role="slider"
						tabIndex="0"
						aria-valuemin="0"
						aria-valuemax="100"
						aria-valuenow={sliderPosition}
						aria-label="Adjust slider position"
					/>
				</div>
				{graphMode ? (
					<div className="flex-1 overflow-hidden relative">
						<img
							src={spiderMap}
							alt="Sample one"
							style={{
								width: '100%',
								height: '100%',
								display:
									selectToggle === 'L' ? 'none' : 'block',
							}}
						/>
					</div>
				) : (
					<div
						className="relative overflow-hidden flex-1"
						ref={containerRef}>
						<div
							className="flex flex-1 overflow-hidden"
							style={{
								width: '100%',
								height: '100%',
							}}>
							{/* First Image (Static Background) */}
							<img
								src={image2}
								alt="First one"
								style={{
									width: '100%',
									height: '100%',
									display:
										selectToggle === 'L' ? 'none' : 'block',
								}}
							/>

							{/* Second Image (Overlay controlled by slider) */}
							<div
								className={`absolute top-0 left-0 ${!selectToggle && 'overflow-hidden'}`}
								style={{
									width: `${sliderPosition}%`,
									height: '100%',
								}}>
								<img
									src={image1}
									alt="Second one"
									style={{
										minWidth: dimensions.width,
										width: dimensions.width,
										height: dimensions.height,
										display:
											selectToggle === 'R'
												? 'none'
												: 'block',
									}}
								/>
							</div>
							<div
								className="absolute top-0 h-full w-[1px] bg-white"
								style={{
									visibility: onSliderMode
										? 'visible'
										: 'hidden',
									left:
										sliderPosition > 99.9
											? '99.9%'
											: `${sliderPosition}%`,
								}}
							/>
						</div>
					</div>
				)}
				<div className="w-full relative shrink-0">
					<span
						className="flex cursor-ew-resize"
						style={{
							visibility:
								onSliderMode && !graphMode
									? 'visible'
									: 'hidden',
							paddingLeft:
								sliderPosition > 99
									? '99%'
									: `${sliderPosition}%`,
						}}
						dangerouslySetInnerHTML={{ __html: sliderIcon }}
						onMouseDown={handleStartDrag}
						role="slider"
						tabIndex="0"
						aria-valuemin="0"
						aria-valuemax="100"
						aria-valuenow={sliderPosition}
						aria-label="Adjust slider position"
					/>
				</div>
			</div>
			<div className="flex flex-col h-full w-1/6 gap-4 overflow-hidden py-4">
				<span className="h-8" />
				<div className="flex-1 flex flex-col gap-3 relative">
					<div className="flex flex-col gap-2">
						<div
							className={`flex rounded-full h-6 w-20 items-center justify-center gap-2 cursor-pointer ${onSliderMode ? 'bg-[#9BE938] text-[#3E3E3E] border-none' : 'bg-[#3E3E3E] text-[#767676] border border-[#5D5D5D]'}`}
							onKeyDown={() => {}}
							role="button"
							tabIndex="0"
							onClick={() => {
								setOnSilderMode(!onSliderMode);
								setSelectToggle('');
								setGraphMode(false);
							}}>
							<span
								className="box-border flex items-center justify-center"
								dangerouslySetInnerHTML={{
									__html: miniSliderIcon,
								}}
							/>
							<span
								className={`text-[#767676] text-sm font-bold uppercase `}>
								Slider
							</span>
						</div>
						<div
							className={`flex border border-gray-900	rounded-full h-6 w-20 items-center justify-center cursor-pointer ${selectToggle ? 'bg-[#9BE938]' : 'bg-[#767676]'} `}
							onKeyDown={() => {}}
							role="button"
							tabIndex="0"
							onClick={() => {
								if (selectToggle !== '') {
									setOnSilderMode(false);
									setGraphMode(false);
									setSelectToggle(
										selectToggle === 'R' ? 'L' : 'R'
									);
								} else {
									setSelectToggle('R');
									setOnSilderMode(false);
									setGraphMode(false);
								}
							}}>
							<span
								className={`flex flex-1 items-center justify-center ${selectToggle === 'L' ? 'bg-[#9BE938] text-[#3E3E3E]' : 'bg-[#767676] text-transparent'} rounded-l-full `}>
								L
							</span>
							{selectToggle === '' && (
								<span className="bg-[#767676] flex">R</span>
							)}
							<span
								className={`flex flex-1 items-center justify-center ${selectToggle === 'R' ? 'bg-[#9BE938] text-[#3E3E3E]' : 'bg-[#767676] text-transparent'} rounded-r-full`}>
								R
							</span>
						</div>
						<div
							onKeyDown={() => {}}
							role="button"
							tabIndex="0"
							label="button"
							className="flex h-20 mt-2"
							onClick={() => {
								setGraphMode(true);
								setOnSilderMode(false);
								setSelectToggle('');
							}}>
							<span
								className="box-border flex items-center justify-center"
								dangerouslySetInnerHTML={{ __html: graphIcon }}
							/>
						</div>
						<div className="flex h-[121px] bottom-40 absolute bg-[#1E1E21] w-[172px] p-[10px]">
							<div className="flex flex-col gap-2">
								<span className="text-[72px] font-extralight text-[#BFBFBF] leading-[72px] tracking-[-7.2px]">
									0.6%
								</span>
								<span className="text-[10px] font-bold">
									Vegetation expansion based on tne NDVI index
									metrics
								</span>
							</div>
						</div>
						<div className="flex h-24 bottom-12 absolute bg-[#1E1E21] w-[172px] p-[10px]">
							<div className="flex flex-col gap-2">
								<span className="text-[16px] font-bold text-[#BFBFBF] ">
									KEY
								</span>
								<div className="flex gap-2">
									<span className="h-4 w-4 border bg-[#B0730C]" />
									<span className="text-[12px]">
										This Color
									</span>
								</div>
								<div className="flex gap-2">
									<span className="h-4 w-4 border bg-[#713B55]" />
									<span className="text-[12px]">
										Another Color
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <span className="h-6" /> */}
			</div>
		</div>
	);
};

export default ImageSlider;
