import React from 'react';
import { connect } from 'react-redux';
import ForestLogo from '../Images/bg_1024x1024.png';
import GreenCubesLogo from '../Images/greencubesLogo.png';
import HexagonLogo from '../Images/hexagon.png';
import LoginForm from '../components/signin/LoginForm';
import SignupForm from '../components/signin/SignupForm';
import ForgotPasswordForm from '../components/signin/ForgotPasswordForm';
import SignupConfirmationForm from '../components/signin/SignupConfirmationForm';

const SignIn = ({ currentLoginSection }) => {
	const ComponetMap = {
		SIGNUP_FORM: SignupForm,
		LOGIN_FORM: LoginForm,
		FORGOT_PASSWORD_FORM: ForgotPasswordForm,
		SIGNUP_CONFIRMATION_FORM: SignupConfirmationForm,
	};
	const Component = ComponetMap[currentLoginSection?.page];

	return (
		<div className="flex w-screen bg-[#313131] h-screen">
			<div className="w-2/5 flex flex-col items-center ">
				<div className="flex flex-col mt-8 w-full ml-12">
					<img
						src={GreenCubesLogo}
						alt="GreenCubes"
						className="w-full max-w-xs h-auto object-contain"
						style={{ maxWidth: '19%' }}
					/>
					<img
						src={HexagonLogo}
						alt="Hexagon"
						className="w-full max-w-xs h-auto object-contain"
						style={{ maxWidth: '22%' }}
					/>
				</div>
				<Component />
			</div>
			<div
				style={{
					backgroundImage: `url(${ForestLogo})`,
				}}
				className="w-3/5 bg-cover brightness-200"
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentLoginSection: state.currentLoginSection,
	};
};

export default connect(mapStateToProps)(SignIn);
