import Camera360Sidebar from './Camera360Sidebar';
import CameraTrapSidebar from './CameraTrapSidebar';
import DashboardSidebar from './DashboardSidebar';
import ExploreMenu from '../ExploreMenu';
import { layoutKeys } from '../common/constants';
import AudioCaptureSidebar from './AudioCaptureSidebar';
import LatestSightingSidebar from './LatestSightingSidebar';
import ChangeDetectionSideBar from './ChangeDetectionSideBar';

const ExploreSideLayout = (props) => {
	const {
		onSelectedSection,
		selectedSection,
		showVisulization,
		handleVisulizationSelection,
		selectedPolygon,
	} = props;

	const sideNavList = {
		[layoutKeys.DASHBOARD]: <DashboardSidebar />,
		[layoutKeys.CAMERA360]: (
			<Camera360Sidebar
				showVisulization={showVisulization}
				handleVisulizationSelection={handleVisulizationSelection}
				selectedPolygon={selectedPolygon}
			/>
		),
		[layoutKeys.CAMERATRAP]: <CameraTrapSidebar />,
		[layoutKeys.AUDIOCAPTURE]: <AudioCaptureSidebar />,
		[layoutKeys.LATESTSIGHTINGS]: <LatestSightingSidebar />,
		[layoutKeys.CHANGEDETECTION]: <ChangeDetectionSideBar />,
	};
	return (
		<div className="flex flex-col h-full w-full flex-1 justify-between overflow-auto gap-[10px]">
			{sideNavList[selectedSection]}
			<div className="w-full overflow-auto shrink-0">
				<ExploreMenu
					onSelectionClick={onSelectedSection}
					selectedSection={selectedSection}
				/>
			</div>
		</div>
	);
};

export default ExploreSideLayout;
