/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const validateInviteEmails = /* GraphQL */ `
	mutation ValidateInviteEmails(
		$emails: [String]
		$clientID: String
		$clientName: String
		$invitationRole: String
	) {
		validateInviteEmails(
			emails: $emails
			clientID: $clientID
			clientName: $clientName
			invitationRole: $invitationRole
		)
	}
`;
export const createForestMainTable = /* GraphQL */ `
	mutation CreateForestMainTable(
		$input: CreateForestMainTableInput!
		$condition: ModelForestMainTableConditionInput
	) {
		createForestMainTable(input: $input, condition: $condition) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const updateForestMainTable = /* GraphQL */ `
	mutation UpdateForestMainTable(
		$input: UpdateForestMainTableInput!
		$condition: ModelForestMainTableConditionInput
	) {
		updateForestMainTable(input: $input, condition: $condition) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const deleteForestMainTable = /* GraphQL */ `
	mutation DeleteForestMainTable(
		$input: DeleteForestMainTableInput!
		$condition: ModelForestMainTableConditionInput
	) {
		deleteForestMainTable(input: $input, condition: $condition) {
			ForestName
			id
			ForestCountry
			lat
			lon
			createdBy
			Status
			icon
			noOfHectars
			about_project_pdf_url
			project_icon_path
			project_map_icon
			agent_emails
			support_emails
			approver_emails
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const createForestsData = /* GraphQL */ `
	mutation CreateForestsData(
		$input: CreateForestsDataInput!
		$condition: ModelForestsDataConditionInput
	) {
		createForestsData(input: $input, condition: $condition) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateForestsData = /* GraphQL */ `
	mutation UpdateForestsData(
		$input: UpdateForestsDataInput!
		$condition: ModelForestsDataConditionInput
	) {
		updateForestsData(input: $input, condition: $condition) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteForestsData = /* GraphQL */ `
	mutation DeleteForestsData(
		$input: DeleteForestsDataInput!
		$condition: ModelForestsDataConditionInput
	) {
		deleteForestsData(input: $input, condition: $condition) {
			id
			polygon_name
			ForestName
			ForestID
			NoOfCubes
			CubePrice
			createdBy
			Status
			lat
			lon
			baseline_report_path
			area_WEBGL_cubified_file_path
			area_MYVR_scene_file_path
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createEmailConfig = /* GraphQL */ `
	mutation CreateEmailConfig(
		$input: CreateEmailConfigInput!
		$condition: ModelEmailConfigConditionInput
	) {
		createEmailConfig(input: $input, condition: $condition) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateEmailConfig = /* GraphQL */ `
	mutation UpdateEmailConfig(
		$input: UpdateEmailConfigInput!
		$condition: ModelEmailConfigConditionInput
	) {
		updateEmailConfig(input: $input, condition: $condition) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteEmailConfig = /* GraphQL */ `
	mutation DeleteEmailConfig(
		$input: DeleteEmailConfigInput!
		$condition: ModelEmailConfigConditionInput
	) {
		deleteEmailConfig(input: $input, condition: $condition) {
			id
			title
			emailList
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createAuditTrail = /* GraphQL */ `
	mutation CreateAuditTrail(
		$input: CreateAuditTrailInput!
		$condition: ModelAuditTrailConditionInput
	) {
		createAuditTrail(input: $input, condition: $condition) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateAuditTrail = /* GraphQL */ `
	mutation UpdateAuditTrail(
		$input: UpdateAuditTrailInput!
		$condition: ModelAuditTrailConditionInput
	) {
		updateAuditTrail(input: $input, condition: $condition) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteAuditTrail = /* GraphQL */ `
	mutation DeleteAuditTrail(
		$input: DeleteAuditTrailInput!
		$condition: ModelAuditTrailConditionInput
	) {
		deleteAuditTrail(input: $input, condition: $condition) {
			id
			ActionType
			RecordID
			ModuleName
			PerformedUserClient
			PerformedBy
			PerformedAt
			Message
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createSupportTicketsTable = /* GraphQL */ `
	mutation CreateSupportTicketsTable(
		$input: CreateSupportTicketsTableInput!
		$condition: ModelSupportTicketsTableConditionInput
	) {
		createSupportTicketsTable(input: $input, condition: $condition) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateSupportTicketsTable = /* GraphQL */ `
	mutation UpdateSupportTicketsTable(
		$input: UpdateSupportTicketsTableInput!
		$condition: ModelSupportTicketsTableConditionInput
	) {
		updateSupportTicketsTable(input: $input, condition: $condition) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteSupportTicketsTable = /* GraphQL */ `
	mutation DeleteSupportTicketsTable(
		$input: DeleteSupportTicketsTableInput!
		$condition: ModelSupportTicketsTableConditionInput
	) {
		deleteSupportTicketsTable(input: $input, condition: $condition) {
			id
			ticketCreatedBy
			ticketCreatedOn
			tickerCreatedClientName
			ticketMainForestName
			ticketComments
			ticketRefNumber
			ticketStatus
			contactEmail
			contactPhone
			meetingDateAndTime
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createPurchaseInvoices = /* GraphQL */ `
	mutation CreatePurchaseInvoices(
		$input: CreatePurchaseInvoicesInput!
		$condition: ModelPurchaseInvoicesConditionInput
	) {
		createPurchaseInvoices(input: $input, condition: $condition) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const updatePurchaseInvoices = /* GraphQL */ `
	mutation UpdatePurchaseInvoices(
		$input: UpdatePurchaseInvoicesInput!
		$condition: ModelPurchaseInvoicesConditionInput
	) {
		updatePurchaseInvoices(input: $input, condition: $condition) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const deletePurchaseInvoices = /* GraphQL */ `
	mutation DeletePurchaseInvoices(
		$input: DeletePurchaseInvoicesInput!
		$condition: ModelPurchaseInvoicesConditionInput
	) {
		deletePurchaseInvoices(input: $input, condition: $condition) {
			id
			purchasedForestMainName
			purchasedForestMainID
			purchasedForestArea
			purchasedForestAreaID
			purchasedByClientID
			purchasedByClientName
			purchaseInvoiceCost
			purchasedCubesCount
			purchasedInvoicePath
			purchasedInvoiceNumber
			purchasedInvoiceCreatedon
			purchaseOrderRaisedBy
			createdAt
			updatedAt
			audit_client_msg
			__typename
		}
	}
`;
export const createInvitationTable = /* GraphQL */ `
	mutation CreateInvitationTable(
		$input: CreateInvitationTableInput!
		$condition: ModelInvitationTableConditionInput
	) {
		createInvitationTable(input: $input, condition: $condition) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateInvitationTable = /* GraphQL */ `
	mutation UpdateInvitationTable(
		$input: UpdateInvitationTableInput!
		$condition: ModelInvitationTableConditionInput
	) {
		updateInvitationTable(input: $input, condition: $condition) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteInvitationTable = /* GraphQL */ `
	mutation DeleteInvitationTable(
		$input: DeleteInvitationTableInput!
		$condition: ModelInvitationTableConditionInput
	) {
		deleteInvitationTable(input: $input, condition: $condition) {
			emailId
			firstName
			clientName
			clientId
			clientRole
			invitedBy
			invitationStatus
			invitationCurrentStatus
			invited_from_domain
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createUserData = /* GraphQL */ `
	mutation CreateUserData(
		$input: CreateUserDataInput!
		$condition: ModelUserDataConditionInput
	) {
		createUserData(input: $input, condition: $condition) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateUserData = /* GraphQL */ `
	mutation UpdateUserData(
		$input: UpdateUserDataInput!
		$condition: ModelUserDataConditionInput
	) {
		updateUserData(input: $input, condition: $condition) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteUserData = /* GraphQL */ `
	mutation DeleteUserData(
		$input: DeleteUserDataInput!
		$condition: ModelUserDataConditionInput
	) {
		deleteUserData(input: $input, condition: $condition) {
			id
			emailId
			firstName
			lastName
			phoneNumber
			clientId
			clientName
			assignedRole
			profilePicURL
			profileStatus
			accessLevel {
				id
				roleName
				createdBy
				status
				permissions
				audit_client_msg
				createdAt
				updatedAt
				__typename
			}
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createMedia = /* GraphQL */ `
	mutation CreateMedia(
		$input: CreateMediaInput!
		$condition: ModelMediaConditionInput
	) {
		createMedia(input: $input, condition: $condition) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateMedia = /* GraphQL */ `
	mutation UpdateMedia(
		$input: UpdateMediaInput!
		$condition: ModelMediaConditionInput
	) {
		updateMedia(input: $input, condition: $condition) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteMedia = /* GraphQL */ `
	mutation DeleteMedia(
		$input: DeleteMediaInput!
		$condition: ModelMediaConditionInput
	) {
		deleteMedia(input: $input, condition: $condition) {
			id
			ForestName
			ForestID
			SiteName
			SiteID
			MediaPath
			MediaType
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createGEOJsonMapper = /* GraphQL */ `
	mutation CreateGEOJsonMapper(
		$input: CreateGEOJsonMapperInput!
		$condition: ModelGEOJsonMapperConditionInput
	) {
		createGEOJsonMapper(input: $input, condition: $condition) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateGEOJsonMapper = /* GraphQL */ `
	mutation UpdateGEOJsonMapper(
		$input: UpdateGEOJsonMapperInput!
		$condition: ModelGEOJsonMapperConditionInput
	) {
		updateGEOJsonMapper(input: $input, condition: $condition) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteGEOJsonMapper = /* GraphQL */ `
	mutation DeleteGEOJsonMapper(
		$input: DeleteGEOJsonMapperInput!
		$condition: ModelGEOJsonMapperConditionInput
	) {
		deleteGEOJsonMapper(input: $input, condition: $condition) {
			id
			forestID
			forestName
			siteName
			siteID
			siteCubicMetersArea
			siteGreenCubes
			siteGEOJson
			createdBy
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createPDFReports = /* GraphQL */ `
	mutation CreatePDFReports(
		$input: CreatePDFReportsInput!
		$condition: ModelPDFReportsConditionInput
	) {
		createPDFReports(input: $input, condition: $condition) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updatePDFReports = /* GraphQL */ `
	mutation UpdatePDFReports(
		$input: UpdatePDFReportsInput!
		$condition: ModelPDFReportsConditionInput
	) {
		updatePDFReports(input: $input, condition: $condition) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deletePDFReports = /* GraphQL */ `
	mutation DeletePDFReports(
		$input: DeletePDFReportsInput!
		$condition: ModelPDFReportsConditionInput
	) {
		deletePDFReports(input: $input, condition: $condition) {
			id
			clientID
			pdfName
			pdfStorageURL
			createdBy
			status
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createClientsTable = /* GraphQL */ `
	mutation CreateClientsTable(
		$input: CreateClientsTableInput!
		$condition: ModelClientsTableConditionInput
	) {
		createClientsTable(input: $input, condition: $condition) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateClientsTable = /* GraphQL */ `
	mutation UpdateClientsTable(
		$input: UpdateClientsTableInput!
		$condition: ModelClientsTableConditionInput
	) {
		updateClientsTable(input: $input, condition: $condition) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteClientsTable = /* GraphQL */ `
	mutation DeleteClientsTable(
		$input: DeleteClientsTableInput!
		$condition: ModelClientsTableConditionInput
	) {
		deleteClientsTable(input: $input, condition: $condition) {
			clientName
			clientContact
			clientAddress
			clientStreetNumber
			clientSuitNumber
			clientCity
			clientCountry
			clientVAT
			clientProfilePicURL
			clientId
			createdBy
			clientSatus
			clienttype
			listOfAccesibleForest
			audit_client_msg
			id
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createPurchaseOrders = /* GraphQL */ `
	mutation CreatePurchaseOrders(
		$input: CreatePurchaseOrdersInput!
		$condition: ModelPurchaseOrdersConditionInput
	) {
		createPurchaseOrders(input: $input, condition: $condition) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updatePurchaseOrders = /* GraphQL */ `
	mutation UpdatePurchaseOrders(
		$input: UpdatePurchaseOrdersInput!
		$condition: ModelPurchaseOrdersConditionInput
	) {
		updatePurchaseOrders(input: $input, condition: $condition) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deletePurchaseOrders = /* GraphQL */ `
	mutation DeletePurchaseOrders(
		$input: DeletePurchaseOrdersInput!
		$condition: ModelPurchaseOrdersConditionInput
	) {
		deletePurchaseOrders(input: $input, condition: $condition) {
			id
			purchasedClientName
			purchasedClientID
			purchaseddBy
			purchasedForestName
			purchasedForestID
			purchasedForestArea
			purchasedForestAreaID
			purchasedNoOfCubes
			purchasedCubePrice
			purchasedInvoiceNumber
			purchasedInvoiceCost
			purchaseStatus
			purchasedOn
			purchaseComments
			purchasedInvoicePath
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createRolesTable = /* GraphQL */ `
	mutation CreateRolesTable(
		$input: CreateRolesTableInput!
		$condition: ModelRolesTableConditionInput
	) {
		createRolesTable(input: $input, condition: $condition) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateRolesTable = /* GraphQL */ `
	mutation UpdateRolesTable(
		$input: UpdateRolesTableInput!
		$condition: ModelRolesTableConditionInput
	) {
		updateRolesTable(input: $input, condition: $condition) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteRolesTable = /* GraphQL */ `
	mutation DeleteRolesTable(
		$input: DeleteRolesTableInput!
		$condition: ModelRolesTableConditionInput
	) {
		deleteRolesTable(input: $input, condition: $condition) {
			id
			roleName
			createdBy
			status
			permissions
			audit_client_msg
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const createUserRegistrationValidationResponse = /* GraphQL */ `
	mutation CreateUserRegistrationValidationResponse(
		$input: CreateUserRegistrationValidationResponseInput!
		$condition: ModelUserRegistrationValidationResponseConditionInput
	) {
		createUserRegistrationValidationResponse(
			input: $input
			condition: $condition
		) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const updateUserRegistrationValidationResponse = /* GraphQL */ `
	mutation UpdateUserRegistrationValidationResponse(
		$input: UpdateUserRegistrationValidationResponseInput!
		$condition: ModelUserRegistrationValidationResponseConditionInput
	) {
		updateUserRegistrationValidationResponse(
			input: $input
			condition: $condition
		) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const deleteUserRegistrationValidationResponse = /* GraphQL */ `
	mutation DeleteUserRegistrationValidationResponse(
		$input: DeleteUserRegistrationValidationResponseInput!
		$condition: ModelUserRegistrationValidationResponseConditionInput
	) {
		deleteUserRegistrationValidationResponse(
			input: $input
			condition: $condition
		) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
export const userRegistrationvalidation = /* GraphQL */ `
	mutation UserRegistrationvalidation(
		$userObject: UserRegistrationValidationObject!
	) {
		userRegistrationvalidation(userObject: $userObject) {
			id
			clientID
			clientName
			clientRole
			createdAt
			updatedAt
			__typename
		}
	}
`;
