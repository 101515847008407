import { useContext, useState } from 'react';
import { learnMoreLogo } from '../../../../components/icons/icons';
import SpeciesInfo from '../common/SpeciesInfo';
import DistributionMap from '../common/DistributionMap';
import AnimalStatus from '../common/AnimalStatus';
import AnimalThumbnails from '../common/AnimalThumbnails';
import AnimalShow from '../common/AnimalShow';
import ForestContext from '../utils/ForestContext';

const LatestSightingLayout = () => {
	const { latestSightingsItems } = useContext(ForestContext);
	const [selectedItem, setSelectedItem] = useState(0);
	const selectedAnimal = latestSightingsItems[selectedItem];

	const handleSelectedItem = (item) => {
		setSelectedItem(item);
	};

	return (
		<div className="flex flex-col flex-1 bg-[#131314] h-full w-full justify-between gap-[10px] overflow-hidden">
			<div className="flex gap-[10px] h-[43%]">
				<div className="w-2/3 h-full rounded-[5px] overflow-hidden">
					<AnimalShow selectedAnimal={selectedAnimal} />
				</div>
				<div className="w-1/3 h-full">
					<DistributionMap selectedAnimal={selectedAnimal} />
				</div>
			</div>
			<div className="flex gap-[10px] h-[15%]">
				<div className="w-2/3 h-full ">
					<SpeciesInfo selectedAnimal={selectedAnimal} />
				</div>
				<div className="w-1/3 h-full">
					<AnimalStatus />
				</div>
			</div>
			<div className="flex gap-[10px] h-full overflow-hidden">
				<div className="w-1/5 h-full flex flex-col gap-[10px] bg-[#1E1E21] rounded-[5px] p-4 shrink-0">
					<span
						className="font-bold uppercase"
						style={{ fontSize: 'clamp(8px,2vw,16px)' }}>
						Learn More
					</span>
					<span
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						aria-label="Span"
						onClick={() =>
							window.open(selectedAnimal.url, '_blank')
						}
						className="h-full flex items-center justify-center cursor-pointer"
						dangerouslySetInnerHTML={{ __html: learnMoreLogo }}
					/>
				</div>
				<div className="h-full">
					<AnimalThumbnails
						onItemSelect={handleSelectedItem}
						selectedAnimal={selectedAnimal}
						items={latestSightingsItems}
						title="field sightings"
						subTitle="La Gamba region"
					/>
				</div>
			</div>
		</div>
	);
};

export default LatestSightingLayout;
