import { InputLabel, OutlinedInput } from '@digitalreality/ui';
import { memo } from 'react';

const FileField = ({ onChange, label, disabled, mimeType, error }) => {
	return (
		<>
			<InputLabel className="w-full shrink-0">{label}</InputLabel>
			<OutlinedInput
				fullWidth
				onChange={onChange}
				disabled={disabled}
				type="file"
				inputProps={{ accept: mimeType }}
			/>
			{!!error && (
				<span className="text-[12px] text-[#F85263] w-full mx-3.5 mt-[3px] mb-0 font-light">
					{error}
				</span>
			)}
		</>
	);
};

export default memo(FileField);
