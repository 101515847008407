import { connect } from 'react-redux';
import MaxWidthDialog from '../components/base/Dialog';
import SubmitButton from '../components/base/SubmitButton';
import { setSessionExpired } from './ReduxStore/Actions/CommonActions';

const SessionExpired = (props) => {
	const { sessionExpired, setSessionExpired: setSessionExpiredAction } =
		props;
	if (!sessionExpired) return null;

	return (
		<MaxWidthDialog
			maxWidth="sm"
			hideFooter
			hideHeader>
			<div className="flex flex-col items-center justify-center gap-8 p-4">
				<span className="font-semibold italic text-red-400">
					Session Expired. Click below to login!
				</span>
				<SubmitButton
					onClick={() => {
						setSessionExpiredAction(false);
						window.location.href = '/';
					}}
					text="Login">
					Login
				</SubmitButton>
			</div>
		</MaxWidthDialog>
	);
};

const mapStateToProps = (state) => ({
	sessionExpired: state.sessionExpired,
});

export default connect(mapStateToProps, {
	setSessionExpired,
})(SessionExpired);
