// eslint-disable-next-line import/no-cycle
import RolesForm from './RolesForm';
import RolesList from './RolesList';

const Roles = () => {
	return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<RolesForm />
			<RolesList />
		</div>
	);
};

export default Roles;
