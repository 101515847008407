import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@digitalreality/ui';
import { Storage } from 'aws-amplify';

import { showModal } from '../../Pages/ReduxStore/Actions/ModalActions';

const PDFActionViewer = (props) => {
	const { value, showModal: showModalAction } = props;
	const [s3URL, setS3URL] = useState('');
	useEffect(() => {
		const getS3URL = async () => {
			const pdfURL = await Storage.get(value);
			setS3URL(pdfURL);
		};

		getS3URL();
	}, [value]);
	return (
		<Tooltip title="View report">
			<IconButton
				onClick={() => showModalAction({ value: true, pdfUrl: s3URL })}>
				<Visibility className="text-black" />
			</IconButton>
		</Tooltip>
	);
};

export default connect(null, { showModal })(PDFActionViewer);
