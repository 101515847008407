/* eslint-disable default-param-last */
import { SET_CLIENTS } from '../Actions/types';

export const clientsInfo = (state = [], action) => {
	switch (action.type) {
		case SET_CLIENTS:
			return action.payload;
		default:
			return state;
	}
};
