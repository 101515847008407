import { SHOW_MODAL } from '../Actions/types';

// eslint-disable-next-line default-param-last
const ModalReducer = (state = { value: false, selectedItem: null }, action) => {
	switch (action.type) {
		case SHOW_MODAL:
			return action.payload;
		default:
			return state;
	}
};

export default ModalReducer;
