import { SET_CLIENTS } from './types';

const setClients = (payload) => {
	return {
		type: SET_CLIENTS,
		payload,
	};
};

export { setClients };
