import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { ProfileAvatar } from '@digitalreality/ui';

function ProfilePic({ value }) {
	const [imageUrl, setImageUrl] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		let isMounted = true;

		async function fetchImageUrl() {
			try {
				const url = await Storage.get(value);
				if (isMounted) {
					setImageUrl(url);
				}
			} catch (e) {
				if (isMounted) {
					setError(true);
				}
			}
		}

		fetchImageUrl();

		return () => {
			isMounted = false;
		};
	}, [value]);

	return (
		<div className="flex justify-center w-full items-center h-full">
			<ProfileAvatar
				src={error ? '/default-profile-pic.jpg' : imageUrl}
			/>
		</div>
	);
}

export default ProfilePic;
