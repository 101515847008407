import BillingList from './BillingList';

const Billing = () => {
	return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<BillingList />
		</div>
	);
};

export default Billing;
