import {
	greenCubesIcon,
	notificationIcon,
} from '../../../../components/icons/icons';
import pages from '../../MYVR/PolygonPagesInfo';

const ExploreHeader = ({
	forestTitle,
	polygonTitle,
	tileTitle,
	onBreadcrumbClick,
}) => {
	const breadcrumbs = [
		{
			label: 'World',
			id: pages.ForestList,
		},
		{
			label: forestTitle,
			id: pages.ForestDetail,
		},
		{
			label: polygonTitle,
			id: pages.ForestSite,
		},
	];

	if (tileTitle) {
		breadcrumbs.push({
			label: 'explore',
			id: 3,
			local: true,
		});
		breadcrumbs.push({
			label: tileTitle,
			id: 4,
			selected: true,
			local: true,
		});
	} else {
		breadcrumbs.push({
			label: 'explore',
			id: 3,
			selected: true,
			local: true,
		});
	}

	return (
		<div className="flex p-4 max-sm:p-2 font-normal justify-between items-center">
			<div className="flex items-center w-1/3 gap-2">
				<span
					role="button"
					tabIndex="0"
					onKeyDown={() => {}}
					aria-label="Span"
					style={{
						width: 'clamp(1.25rem,2.5vw,2.25rem)',
						height: 'clamp(1.25rem,2.5vw,2.25rem)',
					}}
					onClick={() => onBreadcrumbClick(pages.ForestList)}
					className="cursor-pointer z-30"
					dangerouslySetInnerHTML={{ __html: greenCubesIcon }}
				/>
				<span
					className="text-4xl font-bold capitalize"
					style={{ fontSize: 'clamp(0.5rem,1.5vw,2rem)' }}>
					{forestTitle}
				</span>
			</div>
			<div className="flex gap-2 items-center flex-1 justify-start">
				{breadcrumbs.map((item, index) => (
					<span
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						aria-label="Span"
						onClick={() => {
							if (!item.selected) {
								onBreadcrumbClick(item.id, item.local);
							}
						}}
						className={`text-[#BFBFBF] capitalize ${item.selected ? 'font-bold text-[#E5E9EF]' : 'cursor-pointer'}`}
						key={item.id}
						style={{ fontSize: 'clamp(0.5rem,1.5vw,1rem)' }}>
						{item.label}
						{index === breadcrumbs.length - 1 ? '' : ' /'}
					</span>
				))}
			</div>
			<span
				className="flex justify-end items-center"
				style={{
					width: 'clamp(1.25rem,2.5vw,2.25rem)',
					height: 'clamp(1.25rem,2.5vw,2.25rem)',
				}}
				dangerouslySetInnerHTML={{ __html: notificationIcon }}
			/>
		</div>
	);
};

export default ExploreHeader;
