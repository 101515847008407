/* eslint-disable default-param-last */
import { SET_FORESTS } from '../Actions/types';

export const forestsInfo = (state = [], action) => {
	switch (action.type) {
		case SET_FORESTS:
			return action.payload;
		default:
			return state;
	}
};
