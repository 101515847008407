import { connect } from 'react-redux';
import { setShowIcons } from '../Pages/ReduxStore/Actions/CommonActions';

const Icon = (props) => {
	const { children, enableIcons } = props;
	return enableIcons && children;
};

const mapStateToProps = (state) => ({
	enableIcons: state.enableIcons,
});

export default connect(mapStateToProps, {
	setShowIcons,
})(Icon);
