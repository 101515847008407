import { SHOW_MODAL } from './types';

const showModal = (value) => {
	return {
		type: SHOW_MODAL,
		payload: value,
	};
};

export { showModal };
