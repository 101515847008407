import { useState } from 'react';

const VariationYear = (props) => {
	const { years, onChangeDectectionClick, defaultSelection } = props;
	const [selectedYear, setSelectedYear] = useState(defaultSelection);
	const handleClick = (item) => {
		setSelectedYear(item.label);
		onChangeDectectionClick(item);
	};
	return (
		<div className="flex space-between m-3">
			<div className="flex gap-2 justify-center items-center">
				<div
					dangerouslySetInnerHTML={{
						__html: `
								<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                                <path d="M1.80602 10.4365C1.46457 10.2457 1.46456 9.75434 1.80602 9.56353L15.7561 1.7679C16.0894 1.58165 16.5 1.82257 16.5 2.20437L16.5 17.7956C16.5 18.1774 16.0894 18.4183 15.7561 18.2321L1.80602 10.4365Z" stroke="#767676"/>
                                </svg>
							`,
					}}
				/>
				{years.map((item) => {
					return (
						<span
							className={`h-[22px] w-[64px] text-[#767676] border rounded-[3px] border-[#767676] flex items-center justify-center hover:text-[#3E3E3E] hover:border-[#BFBFBF] hover:bg-[#E6E6E6] ${
								item.label === selectedYear
									? 'bg-[#3E3E3E] font-bold !text-[#FFF]'
									: ''
							}`}
							key={item.label}
							role="button"
							tabIndex="0"
							onKeyDown={() => {}}
							onClick={() => {
								onChangeDectectionClick(item);
								handleClick(item);
							}}>
							{item.label}
						</span>
					);
				})}
				<div
					dangerouslySetInnerHTML={{
						__html: `
								<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                                <path d="M15.194 9.56353C15.5354 9.75434 15.5354 10.2457 15.194 10.4365L1.24391 18.2321C0.91063 18.4183 0.500005 18.1774 0.500005 17.7956L0.500006 2.20437C0.500006 1.82258 0.910633 1.58165 1.24392 1.7679L15.194 9.56353Z" stroke="#767676"/>
                                </svg>
							`,
					}}
				/>
			</div>
		</div>
	);
};

export default VariationYear;
