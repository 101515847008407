import React, { useState } from 'react';
import {
	ToggleOnOutlined,
	ToggleOffOutlined,
	CheckOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip, CircularProgress } from '@digitalreality/ui';

import * as mutations from '../../../graphql/mutations';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const InvitationActionRenderer = (props) => {
	const { value, onError } = props;
	const [isLoading, setIsLoading] = useState(false);

	const handleAction = async () => {
		setIsLoading(true);
		const data = {
			id: props?.data?.id,
			invitationCurrentStatus:
				value === 'New' && value !== 'confirmed' ? 'Disabled' : 'New',
		};
		try {
			await makeGraphQLRequest({
				query: mutations.updateInvitationTable,
				variables: { input: data },
			});
			onError('');
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const renderContent = () => {
		if (isLoading) {
			return (
				<CircularProgress
					thickness={4}
					style={{ color: '#0ea5e9' }}
					size={20}
				/>
			);
		}

		if (value === 'confirmed') {
			return (
				<Tooltip title="Successfully Signed Up">
					<CheckOutlined className="text-sky-500" />
				</Tooltip>
			);
		}

		return (
			<IconButton onClick={handleAction}>
				{value === 'New' ? (
					<Tooltip title="Disable Invitation">
						<ToggleOnOutlined className="text-sky-500" />
					</Tooltip>
				) : (
					<Tooltip title="Enable Invitation">
						<ToggleOffOutlined className="text-gray-500" />
					</Tooltip>
				)}
			</IconButton>
		);
	};

	return (
		<span className="flex flex-col items-center justify-center h-full">
			{renderContent()}
		</span>
	);
};

export default InvitationActionRenderer;
