import { useContext, useState } from 'react';
import ForestContext from '../utils/ForestContext';

const AnimalStatus = () => {
	const { animalStatusItems } = useContext(ForestContext);
	const [selectedStatus] = useState('LC');
	return (
		<div className="w-full h-full bg-[#1E1E21] rounded-[5px] p-4 flex">
			<div className="flex flex-col shrink-0">
				<span
					className="uppercase text-base font-bold"
					style={{ fontSize: 'clamp(6px,2vw,16px)' }}>
					status
				</span>
				<span
					className="uppercase text-[10px] font-bold"
					style={{ fontSize: 'clamp(4px,2vw,10px)' }}>
					IUCN red list
				</span>
			</div>
			<div className="flex flex-col items-center self-center justify-self-center w-2/3 m-auto gap-1">
				<span
					className="uppercase text-[#EE2C24] text-[10px] font-bold leading-[22px] text-center"
					style={{ fontSize: 'clamp(4px,2vw,10px)' }}>
					least concern
				</span>
				<span className="h-1 bg-[#EE2C24] w-full" />
				<div className="flex items-center justify-between w-full">
					{animalStatusItems.map((item) => (
						<span
							key={item.label}
							style={{
								backgroundColor:
									selectedStatus === item.value
										? '#EE2C24'
										: '',
								borderRadius: '50px 0px 50px 50px',
								fontSize: 'clamp(4px,2vw,10px)',
							}}
							className="text-[10px] font-bold p-2">
							{item.label}
						</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default AnimalStatus;
