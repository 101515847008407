import { Provider } from 'react-redux';
import './App.css';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@digitalreality/ui';
import Root from './Pages/Screens/RootPages/Root';
import { StyleContext } from './Pages/ContextThemes/StyleContext';
import { AppThemeObject } from './Pages/ContextThemes/AppThemeStyles';
import { store, persistor } from './Pages/ReduxStore/Stores/Store';

function App() {
	return (
		<ThemeProvider>
			<StyleContext.Provider value={AppThemeObject}>
				<Provider store={store}>
					<PersistGate
						loading={null}
						persistor={persistor}>
						<Root />
					</PersistGate>
				</Provider>
			</StyleContext.Provider>
		</ThemeProvider>
	);
}
export default App;
