import { useState } from 'react';
import { expandIcon } from '../../../../components/icons/icons';
import MaxWidthDialog from '../../../../components/base/Dialog';

const ExploreWidget = (props) => {
	const { title, icon, children, hideHeader, onExplore } = props;
	const [open, setOpen] = useState(false);
	const content = (
		<>
			{!hideHeader && (
				<div className="flex justify-between items-center w-full p-2 pb-0 overflow-hidden">
					<div className="flex gap-1 items-center">
						<span
							className="h-10 w-10 p-2 box-border flex items-center justify-center"
							style={{
								width: 'clamp(2rem,2.5vw,3rem)',
								height: 'clamp(2rem,2.5vw,3rem)',
							}}
							dangerouslySetInnerHTML={{ __html: icon }}
						/>
						<span
							className="text-[#eeeeee] text-base font-bold uppercase max-md:leading-normal"
							style={{ fontSize: 'clamp(0.5rem, 1vw, 1rem)' }}>
							{title}
						</span>
					</div>
					<span
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						aria-label="Span"
						onClick={() => {
							if (onExplore) {
								onExplore();
							} else {
								setOpen(!open);
							}
						}}
						className="h-10 w-10 p-2 box-border flex items-center justify-center cursor-pointer hover:bg-[#3E3E3E]"
						style={{
							width: 'clamp(2rem,2.3vw,3rem)',
							height: 'clamp(2rem,2.3vw,3rem)',
						}}
						dangerouslySetInnerHTML={{ __html: expandIcon }}
					/>
				</div>
			)}
			<div className="flex items-center justify-center p-[10px] max-md:p-[5px] flex-1 h-full overflow-auto">
				{children}
			</div>
		</>
	);
	return (
		<div className="flex flex-col bg-[#1E1E21] rounded-[5px] flex-1 w-full h-full overflow-hidden">
			{content}
			{open && (
				<MaxWidthDialog
					open
					hideFooter
					hideHeader
					maxWidth="3xl"
					removePadding
					onCancel={() => setOpen(false)}>
					<div className="flex flex-col overflow-hidden">
						{content}
					</div>
				</MaxWidthDialog>
			)}
		</div>
	);
};

export default ExploreWidget;
