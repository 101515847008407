import { Text } from '@digitalreality/ui';
import MaxWidthDialog from './Dialog';

const MYVRModal = (props) => {
	const {
		isLoading,
		error,
		onCancel,
		title,
		children,
		removePadding,
		maxWidth,
		open,
	} = props;

	return (
		<MaxWidthDialog
			open={open}
			onCancel={onCancel}
			loading={isLoading}
			cancelButtonText="Close"
			submitButtonDisabled
			onSubmit={() => {}}
			hideFooter
			removePadding={removePadding}
			maxWidth={maxWidth}
			title={title}>
			{error && (
				<Text className="text-sm text-red-400 w-full">{error}</Text>
			)}
			{children}
		</MaxWidthDialog>
	);
};

export default MYVRModal;
