import { Text } from '@digitalreality/ui';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { switchCurrentLoginSection } from '../../Pages/ReduxStore/Actions/LoginPageActions';
import SubmitButton from '../base/SubmitButton';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { useFormState } from '../hooks/useFormState';
import { formValidation } from '../../utils/formValidation';
import { FieldList } from '../base/FieldList';
import { makeGraphQLRequest } from '../../utils/makeGraphQLRequest';

const SignupConfirmationForm = ({
	currentLoginSection,
	switchCurrentLoginSection: switchCurrentLoginSectionAction,
}) => {
	const {
		formData,
		setFormData,
		isLoading,
		setIsLoading,
		error,
		setError,
		onChange,
	} = useFormState({
		email: { value: currentLoginSection?.email || '' },
	});
	/**
	 * values for step state
	 * 0 - Enter email and confirmation code
	 * 1 - Click signin after successfull verification
	 */
	const [step, setStep] = useState(0);

	const formFields = [
		{
			id: 'email',
			label: 'Email',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('email', e.target.value),
				[onChange]
			),
			disabled: currentLoginSection?.email,
		},
		{
			id: 'code',
			label: 'Confirmation Code',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('code', e.target.value),
				[onChange]
			),
		},
	];

	const handleVerifyCode = async () => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;

		try {
			setIsLoading(true);
			const email = reducedData.email.toLowerCase();
			await Auth.confirmSignUp(email, reducedData.code);

			const responseObject = await makeGraphQLRequest({
				query: queries.listInvitationTables,
				variables: {
					filter: {
						invitationCurrentStatus: { eq: 'New' },
						emailId: { eq: email },
					},
				},
				authMode: 'AWS_IAM',
			});

			const clientObject = {
				id: responseObject?.data?.listInvitationTables?.items?.[0]?.id,
				invitationCurrentStatus: 'confirmed',
			};

			await makeGraphQLRequest({
				query: mutations.updateInvitationTable,
				variables: { input: clientObject },
				authMode: 'AWS_IAM',
			});

			setStep(1);
		} catch (e) {
			let errorMsg = e.message;

			if (!errorMsg) {
				errorMsg = e?.errors?.[0]?.message;
			}
			setError(errorMsg);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSignin = () => {
		switchCurrentLoginSectionAction({ page: 'LOGIN_FORM' });
	};

	return (
		<div className="animate-fadeIn flex flex-col w-3/5 h-full items-center justify-center gap-3">
			{step === 0 && (
				<>
					<div className="flex flex-col gap-2 w-full">
						<span
							className="text-[#9BE938] text-start"
							style={{
								fontSize: 'clamp(8px,3vw,30px)',
								lineHeight: 'clamp(18px,3.5vw,36px)',
							}}>
							Signup Confirmation
						</span>
						<span
							className="text-[#ffffff] "
							style={{
								fontSize: 'clamp(4px,2.5vw,14px)',
								lineHeight: 'clamp(10px,3.5vw,20px)',
							}}>
							Please enter the confirmation code sent to your
							email
						</span>
					</div>
					<Text
						className=" text-red-400 w-full"
						style={{
							fontSize: 'clamp(4px,2.5vw,14px)',
							lineHeight: 'clamp(10px,3.5vw,20px)',
						}}>
						{error}
					</Text>
					<FieldList
						fields={formFields}
						data={formData}
					/>
					<div className="flex flex-row w-full justify-between mt-3">
						<SubmitButton
							disabled={isLoading}
							onClick={handleSignin}
							text="Cancel"
							variant="secondary"
						/>
						<SubmitButton
							isLoading={isLoading}
							onClick={handleVerifyCode}
							text="Verify"
						/>
					</div>
				</>
			)}
			{step === 1 && (
				<div className="flex flex-col w-full gap-3">
					<span
						className="text-[#9BE938] text-start"
						style={{
							fontSize: 'clamp(6px,2.5vw,20px)',
							lineHeight: 'clamp(10px,3.5vw,28px)',
						}}>
						Signup Successfull, Please login!
					</span>
					<SubmitButton
						onClick={handleSignin}
						text="Login"
					/>
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		currentLoginSection: state.currentLoginSection,
	};
};
export default connect(mapStateToProps, { switchCurrentLoginSection })(
	SignupConfirmationForm
);
