import CheckoutMain from '../MYVR/CheckoutMain';
import GreenCubeMiniLogo from '../../../Images/GreenCubeMiniLogo.png';
import { formatNumber } from '../../../utils/utils';

const PurchaseConfirm = (props) => {
	const { onSubmit, data } = props;
	return (
		<CheckoutMain
			title="Thank you"
			submitButtonText="close"
			onSubmit={onSubmit}>
			<div className="flex flex-col gap-4 w-3/4 items-center py-10">
				<div className="flex items-center gap-3 text-start">
					<img
						alt="Mini Logo"
						src={GreenCubeMiniLogo}
						className="w-8 h-8"
					/>
					<span className="text-black text-2xl font-medium">
						{formatNumber(data?.numberOfCubes)}
					</span>
					<span>Green Cubes</span>
				</div>
				<span className="text-sm font-normal text-[#2A2A2A]">
					Will now be escrowed for two weeks, and an agent wil be in
					contact shortly to manage the purchase order and payment.
				</span>
			</div>
		</CheckoutMain>
	);
};

export default PurchaseConfirm;
