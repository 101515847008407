import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined } from '@mui/icons-material';

import * as queries from '../../../graphql/queries';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import ProfilePic from '../../../components/grid/Profile';
import { useGridState } from '../../../components/hooks/useGridState';
import UserActionRenderer from './UserActionRenderer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const UsersList = (props) => {
	const { loginedInUserData, showModal: showModalAction } = props;
	const { assignedRole, clientId } =
		loginedInUserData?.data?.getLoggedInUserData;

	const {
		error,
		rowData,
		gridRef,
		onError,
		onRecords,
		onGridReady,
		onSelectionChanged,
		selectedRows,
	} = useGridState({
		onUpdateTable: 'onUpdateUserData',
		fetchRecords: async () => {
			try {
				let filter_variables = {};

				if (assignedRole === 'super-admin') {
					filter_variables = {};
				} else if (assignedRole === 'client-admin') {
					filter_variables = {
						filter: { clientName: { eq: clientId } },
					};
				} else {
					filter_variables = {
						filter: { clientName: { eq: 'NA' } },
					};
				}

				const responseObject = await makeGraphQLRequest({
					query: queries.listUserData,
					variables: filter_variables,
				});
				const items_list =
					responseObject?.data?.listUserData?.items || [];
				onRecords(items_list);
			} catch (e) {
				onError(e);
			}
		},
	});

	const colDefs = useMemo(
		() => [
			{
				field: 'profilePicURL',
				headerName: 'Profile Image',
				width: 150,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				cellRenderer: ProfilePic,
				pinned: 'left',
			},
			{
				field: 'firstName',
				headerName: 'Name',
			},
			{
				field: 'emailId',
				headerName: 'Email',
				flex: 1,
				filter: true,
			},
			{
				field: 'clientId',
				headerName: 'Client',
			},
			{
				field: 'assignedRole',
				headerName: 'Role',
			},
			{
				field: 'profileStatus',
				cellRenderer: UserActionRenderer,
				cellRendererParams: {
					onError,
				},
				headerName: 'Status',
				width: 150,
			},
			{
				field: 'updatedAt',
				flex: 1,
			},
		],
		[onError]
	);

	const actions = [
		{
			id: 'edit_user',
			name: 'Update User',
			onClick: () =>
				showModalAction({ value: true, selectedItem: selectedRows[0] }),
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Users">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				suppressRowClickSelection
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(UsersList);
