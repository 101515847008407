import { API } from 'aws-amplify';
import { store } from '../Pages/ReduxStore/Stores/Store';
import { setSessionExpired } from '../Pages/ReduxStore/Actions/CommonActions';

const makeGraphQLRequest = async (payload) => {
	let response;
	try {
		response = await API.graphql(payload);
		return response;
	} catch (e) {
		if (e.message === 'No current user') {
			store.dispatch(setSessionExpired(true));
			return undefined;
		}
		throw e;
	}
};

export { makeGraphQLRequest };
