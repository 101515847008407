import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Tooltip } from '@digitalreality/ui';
import { CloseOutlineIcon } from '@digitalreality/ui-icons';

import SubmitButton from './SubmitButton';

export default function MaxWidthDialog({
	maxWidth,
	onCancel,
	onSubmit,
	children,
	title,
	loading,
	submitButtonText = 'Save',
	cancelButtonText = 'Cancel',
	submitButtonDisabled,
	hideFooter,
	removePadding,
	open = true,
	hideHeader,
}) {
	const handleClose = (_event, reason) => {
		if (reason === 'backdropClick') return;
		onCancel();
	};
	return (
		<Dialog
			fullWidth
			maxWidth={maxWidth || 'md'}
			open={open}
			className="flex flex-col overflow-hidden"
			onClose={handleClose}>
			{!hideHeader && (
				<DialogTitle
					sx={{
						padding: '10px',
					}}
					className="flex justify-between items-center overflow-hidden">
					<span>{title}</span>
					<Tooltip title="Close">
						<IconButton onClick={onCancel}>
							<CloseOutlineIcon />
						</IconButton>
					</Tooltip>
				</DialogTitle>
			)}
			<div
				className={`flex flex-col p-${removePadding ? 0 : 4} gap-3 bg-[rgba(0, 0, 0, 0.6)] overflow-auto`}>
				{children}
			</div>
			{!hideFooter && (
				<DialogActions className="flex justify-end gap-2">
					{onCancel && (
						<SubmitButton
							disabled={loading}
							text={cancelButtonText}
							onClick={onCancel}
							variant="secondary"
						/>
					)}
					{onSubmit && (
						<SubmitButton
							disabled={submitButtonDisabled}
							isLoading={loading}
							onClick={onSubmit}
							text={submitButtonText}
						/>
					)}
				</DialogActions>
			)}
		</Dialog>
	);
}
