import { connect } from 'react-redux';
import { useMemo } from 'react';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import Grid from '../../CommonComponents/AGGrid/Grid';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useGridState } from '../../../components/hooks/useGridState';
import * as queries from '../../../graphql/queries';
import InvoiceActionRenderer from './InvoiceActionRenderer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const InvoiceList = (props) => {
	const { loginedInUserData } = props;
	const { assignedRole, clientId } =
		loginedInUserData?.data?.getLoggedInUserData;
	const {
		error,
		rowData,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreatePurchaseInvoices',
		onUpdateTable: 'onUpdatePurchaseInvoices',
		fetchRecords: async () => {
			try {
				let filter_variables = {};
				if (assignedRole !== 'super-admin') {
					filter_variables = {
						filter: { purchasedByClientName: { eq: clientId } },
					};
				}
				const responseObject = await makeGraphQLRequest({
					query: queries.listPurchaseInvoices,
					variables: filter_variables,
				});
				const items_list =
					responseObject?.data?.listPurchaseInvoices?.items || [];
				onRecords(items_list);
			} catch (e) {
				onError(e);
			}
		},
	});

	const colDefs = useMemo(
		() => [
			{
				field: 'purchasedForestMainName',
				pinned: 'left',
				headerName: 'Forest',
				width: 150,
			},
			{
				field: 'purchasedForestArea',
				headerName: 'Forest Plot',
				width: 150,
			},
			{
				field: 'purchasedByClientName',
				headerName: 'Client',
				width: 150,
			},
			{
				field: 'purchasedCubesCount',
				headerName: '# Cubes',
				width: 120,
			},
			{
				field: 'purchaseInvoiceCost',
				headerName: 'Invoice Amount',
				width: 140,
			},
			{
				field: 'purchasedInvoiceNumber',
				headerName: 'Invoice Number',
				width: 140,
			},
			{
				field: 'purchasedInvoicePath',
				headerName: 'Invoice',
				cellRenderer: InvoiceActionRenderer,
				width: 120,
			},
			{
				field: 'purchasedInvoiceCreatedon',
				headerName: 'Created On',
				width: 250,
			},
			{
				field: 'purchaseOrderRaisedBy',
				headerName: 'Created By',
				flex: 1,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			title="Invoices">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				suppressRowClickSelection
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(InvoiceList);
