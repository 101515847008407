import { Field } from '@digitalreality/ui';
import { memo } from 'react';

const TextField = ({
	onChange,
	value,
	onKeyDown,
	error,
	multiline,
	label,
	placeholder,
	hide,
	helperText,
	disabled,
	type,
}) => {
	return (
		<Field
			fullWidth
			multiline={multiline}
			maxRows={4}
			value={value ?? ''}
			label={label}
			placeholder={placeholder || ''}
			onKeyDown={(e) => onKeyDown?.(e)}
			style={{ display: hide ? 'none' : 'block' }}
			error={!!error}
			type={type}
			helperText={error || helperText}
			onChange={onChange}
			disabled={disabled}
		/>
	);
};

export default memo(TextField);
