import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@digitalreality/ui';
import MaxWidthDialog from '../../../components/base/Dialog';
import PDFViewer from '../../../components/base/PDFViewer';

const InvoiceActionRenderer = (props) => {
	const { value } = props;
	const [showModal, setShowModal] = useState(false);
	const [s3URL, setS3URL] = useState('');
	useEffect(() => {
		const getS3URL = async () => {
			const pdfURL = await Storage.get(value);
			setS3URL(pdfURL);
		};

		getS3URL();
	}, [value]);

	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<MaxWidthDialog
				loading={false}
				cancelButtonText="Cancel"
				onCancel={handleCloseModal}
				hideFooter
				removePadding
				open={showModal}
				title="Invoice"
				maxWidth="xl">
				<PDFViewer url={s3URL} />
			</MaxWidthDialog>
			<Tooltip title="View report">
				<IconButton onClick={() => setShowModal(true)}>
					<Visibility className="text-black" />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default InvoiceActionRenderer;
