import { leftNavIcon, rightNavIcon } from '../../../../components/icons/icons';

const AnimalThumbnails = ({
	onItemSelect,
	selectedAnimal,
	items,
	title,
	subTitle,
}) => {
	return (
		<div className="flex flex-col flex-1 bg-[#1E1E21] rounded-[5px] gap-2 overflow-hidden h-full p-2">
			<div className="flex flex-col">
				<span
					className="uppercase font-bold text-[16px]"
					style={{ fontSize: 'clamp(8px,2vw,16px)' }}>
					{title}
				</span>
				<span
					className="uppercase text-[10px]"
					style={{ fontSize: 'clamp(5px,2vw,10px)' }}>
					{subTitle}
				</span>
			</div>
			<div className="w-full h-full items-center overflow-hidden gap-8 px-10 py-4 flex">
				<span
					className="cursor-pointer"
					role="button"
					tabIndex="0"
					onKeyDown={() => {}}
					aria-label="Span"
					onClick={() =>
						onItemSelect((prevSelectedItem) =>
							prevSelectedItem - 1 < 0
								? items.length - 1
								: prevSelectedItem - 1
						)
					}
					dangerouslySetInnerHTML={{
						__html: leftNavIcon,
					}}
				/>
				<div className="flex items-center gap-4 w-full h-full overflow-hidden">
					{items.map((item) => (
						<div
							key={item.id}
							className="flex flex-col w-1/3 h-full"
							style={{
								border:
									selectedAnimal.id === item.id
										? '1px solid #87E78B'
										: 'none',
								padding:
									selectedAnimal.id === item.id
										? '10px'
										: '0px',
								display: [
									selectedAnimal.id - 1 < 0
										? items.length - 1
										: selectedAnimal.id - 1,
									selectedAnimal.id,
									selectedAnimal.id + 1 > items.length - 1
										? 0
										: selectedAnimal.id + 1,
								].includes(item.id)
									? 'flex'
									: 'none',
							}}>
							<img
								src={item.image}
								alt={item.title}
								className="h-[80%] object-cover"
							/>
							<span
								className="font-bold text-base"
								style={{ fontSize: 'clamp(8px,2vw,16px)' }}>
								{item.title}
							</span>
							<span
								className="text-[10px] italic"
								style={{ fontSize: 'clamp(5px,2vw,10px)' }}>
								{item.subTitle || ' '}
							</span>
						</div>
					))}
				</div>
				<span
					className="cursor-pointer"
					role="button"
					tabIndex="0"
					onKeyDown={() => {}}
					aria-label="Span"
					onClick={() =>
						onItemSelect((prevSelectedItem) =>
							prevSelectedItem + 1 > items.length - 1
								? 0
								: prevSelectedItem + 1
						)
					}
					dangerouslySetInnerHTML={{
						__html: rightNavIcon,
					}}
				/>
			</div>
		</div>
	);
};

export default AnimalThumbnails;
