import { Box, Text } from '@digitalreality/ui';
import { GridActions } from '../../../components/base/GridActions';

const GridLayout = ({ error, actions, title, children }) => {
	return (
		<Box className="flex flex-col grow-1 h-full gap-4">
			<div className="flex w-full justify-between items-center">
				<span className="text-2xl text-nowrap">{title}</span>
				<GridActions actions={actions || []} />
			</div>
			{error && (
				<Box className="flex w-full justify-start mr-5 border-s-red p-3">
					<Box className="flex flex-col w-2/4 justify-start items-center">
						<Text className="text-sm text-red-400 w-full">
							{error}
						</Text>
					</Box>
				</Box>
			)}
			<Box className="flex w-full h-full justify-start grow-1">
				{children}
			</Box>
		</Box>
	);
};

export default GridLayout;
