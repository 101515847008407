import { useContext, useState } from 'react';
import { monthlyCameraTrapCaptures } from '../common/images';
import SpeciesInfo from '../common/SpeciesInfo';
import DistributionMap from '../common/DistributionMap';
import AnimalStatus from '../common/AnimalStatus';
import AnimalThumbnails from '../common/AnimalThumbnails';
import AnimalShow from '../common/AnimalShow';
import ForestContext from '../utils/ForestContext';

const CameraTrapLayout = () => {
	const { cameraTrapItems } = useContext(ForestContext);
	const [selectedItem, setSelectedItem] = useState(0);
	const selectedAnimal = cameraTrapItems[selectedItem];

	const handleSelectedItem = (item) => {
		setSelectedItem(item);
	};

	return (
		<div className="flex flex-col flex-1 bg-[#131314] h-full w-full justify-between gap-[10px] overflow-hidden">
			<div className="flex gap-[10px] h-[43%]">
				<div className="w-2/3 h-full bg-[#1E1E21] rounded-[5px] overflow-hidden">
					<AnimalShow selectedAnimal={selectedAnimal} />
				</div>
				<div className="w-1/3 h-full">
					<DistributionMap selectedAnimal={selectedAnimal} />
				</div>
			</div>
			<div className="flex gap-[10px] h-[15%]">
				<div className="w-2/3 h-full ">
					<SpeciesInfo selectedAnimal={selectedAnimal} />
				</div>
				<div className="w-1/3 h-full">
					<AnimalStatus />
				</div>
			</div>
			<div className="flex gap-[10px] h-full overflow-hidden">
				<div className="w-1/6 h-full flex flex-col gap-[10px] bg-[#1E1E21] rounded-[5px] p-4 shrink-0">
					<span
						className="font-bold uppercase"
						style={{ fontSize: 'clamp(8px,2vw,16px)' }}>
						Captures
					</span>
					<img
						className="object-contain h-full overflow-hidden"
						src={monthlyCameraTrapCaptures}
						alt="Monthly Camera Trap Capture"
					/>
				</div>
				<div className="h-full flex-1">
					<AnimalThumbnails
						onItemSelect={handleSelectedItem}
						selectedAnimal={selectedAnimal}
						items={cameraTrapItems}
						title="camera trap"
						subTitle="Cam lg01"
					/>
				</div>
			</div>
		</div>
	);
};

export default CameraTrapLayout;
