import { connect } from 'react-redux';

const SponsorForestList = (props) => {
	const { loginedInUserData, forests, onForestClick, selectedForest } = props;
	const { firstName } = loginedInUserData?.data?.getLoggedInUserData || {};
	const isLongName = firstName && firstName.length > 10;

	return (
		<div className="animate-fadeIn flex flex-col items-center">
			<span
				className="font-light text-center max-md:px-[5px] px-2"
				style={{
					fontSize: isLongName
						? 'clamp(0.5rem, 2.5vw, 1.5rem)' // For longer names(1rem=16px)
						: 'clamp(0.5rem, 2.5vw, 2.25rem)', // For shorter names
				}}>
				Welcome{firstName ? `, ${firstName}` : ''}
			</span>
			<div className="flex flex-col w-full py-16 max-md:py-4 overflow-hidden">
				{forests?.map((item) => (
					<div
						role="button"
						tabIndex="0"
						onKeyDown={() => {}}
						onClick={() => onForestClick(item)}
						className={`w-full hover:bg-[#D7F4B1] border-b border-b-[#BFBFBF] hover:text-black p-[10px] max-md:p-[5px] px-10 max-md:px-[10px] cursor-pointer flex justify-between items-center ${
							selectedForest?.id === item.id
								? 'bg-[#D7F4B1] text-black'
								: ''
						}`}
						key={item.id}>
						<div className="flex items-center">
							<span
								className="ml-2 capitalize text-base leading-normal"
								style={{
									fontSize: 'clamp(0.5rem,1.5vw,1rem)',
								}}>{`${item.name} - ${item.forestName}`}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(SponsorForestList);
