import { combineReducers } from 'redux';
import { loginedInUserData, currentLoginSection } from './LoginReducer';
import { letsGoStatus } from './LandingReducer';
import { selectedFooterLegal } from './FooterReducer';
import { clientsInfo as clients } from './ClientReducer';
import { forestsInfo as forests } from './ForestReducer';
import { polygonsInfo as polygons } from './PolygonReducer';
import {
	errorStatus,
	landingPage,
	appLoadingIndicator,
	sessionExpired,
	loading_indicator_reducer_status,
	enableIcons,
} from './CommonReducer';
import modalReducer from './ModalReducer';

const RootReducer = combineReducers({
	letsGoStatus,
	selectedFooterLegal,
	errorStatus,
	loginedInUserData,
	loading_indicator_reducer_status,
	modalStatus: modalReducer,
	landingPage,
	appLoadingIndicator,
	currentLoginSection,
	sessionExpired,
	clients,
	forests,
	polygons,
	enableIcons,
});

export default RootReducer;
