// eslint-disable-next-line default-param-last
export const selectedFooterLegal = (state = '', action) => {
	switch (action.type) {
		case 'SelectedLegalLink':
			return action.payload;
		case 'clear':
			return '';
		default:
			return state;
	}
};
