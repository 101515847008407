import { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import * as mutations from '../../../graphql/mutations';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
import FormModal from '../../../components/base/FormModal';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

function SupportTicketForm(props) {
	const {
		modalStatus,
		loginedInUserData,
		showModal: showModalAction,
	} = props;
	const { selectedItem } = modalStatus || {};
	const [showMap, setShowMap] = useState(false);
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	const formFields = [
		{
			id: 'ticketRefNumber',
			label: 'Ticket #',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('ticketRefNumber', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'tickerCreatedClientName',
			label: 'Client Name',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('tickerCreatedClientName', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'contactEmail',
			label: 'Requestor Email',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('contactEmail', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'ticketComments',
			label: 'Comments',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('ticketComments', e.target.value),
				[onChange]
			),
			editable: true,
		},
	];
	const handleSubmit = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			loginedInUserData?.data?.getLoggedInUserData;
		try {
			// Changed below code to handle both edit and creation as array
			const data = {
				...reducedData,
				ticketComments: newState.ticketComments.value,
			};
			if (!isEdit) {
				data.ticketStatus = 'New';
			} else {
				data.ticketStatus = 'Closed';
				data.id = newState?.id.value;
			}

			data.audit_client_msg = `${clientId}@@${emailId}`;
			await makeGraphQLRequest({
				query: isEdit
					? mutations.updateSupportTicketsTable
					: mutations.createSupportTicketsTable,
				variables: { input: data },
			});
			setFormStatus(true);
		} catch (e) {
			onError(e);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		setShowMap(false);
		showModalAction({ value: false });
	};

	if (!modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handleSubmit(!!selectedItem)}
			isLoading={isLoading}
			title={`${selectedItem ? 'Update' : 'Add'} Ticket`}
			error={
				formData?.ticketStatus?.value === 'Closed'
					? 'Ticket already closed.'
					: error
			}
			formStatus={formStatus}
			formFields={
				formData?.ticketStatus?.value === 'Closed' ? [] : formFields
			}
			formData={formData}
			hideFooter={showMap}
			hideFields={showMap}
			isEdit={!!selectedItem}
		/>
	);
}

const mapStateToProps = (state) => ({
	modalStatus: state.modalStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(SupportTicketForm);
