import { checkMarkIcon } from '../../../../components/icons/icons';

const SpeciesInfo = ({ selectedAnimal }) => {
	return (
		<div className="w-full h-full flex justify-between p-4 bg-[#1E1E21] rounded-[5px]">
			<div className="flex flex-col w-1/2">
				<span
					className="text-sm font-bold mb-[5px]"
					style={{ fontSize: 'clamp(6px,2vw,14px)' }}>
					Species
				</span>
				<span
					className="text-xl font-bold text-[#BFBFBF]"
					style={{ fontSize: 'clamp(0.5rem,2vw,1.25rem)' }}>
					{selectedAnimal.title}
				</span>
				<span
					className="text-[10px] text-[#D9D9D9]"
					style={{ fontSize: 'clamp(8px,2vw,10px)' }}>
					{selectedAnimal.subTitle}
				</span>
			</div>
			<div className="flex flex-col">
				<span
					className="text-sm font-bold mb-[5px]"
					style={{ fontSize: 'clamp(6px,2vw,14px)' }}>
					AI Prediction model
				</span>
				<div className="flex flex-col">
					{selectedAnimal.aiData.map((item) => (
						<div
							className="flex gap-5 items-center"
							key={item.label}>
							<span
								className="w-2/3 text-[10px]"
								style={{ fontSize: 'clamp(8px,2vw,10px)' }}>
								{item.label}
							</span>
							<span
								className="text-[10px]"
								style={{ fontSize: 'clamp(8px,2vw,10px)' }}>
								{item.value}
							</span>
						</div>
					))}
				</div>
			</div>
			<div className="flex flex-col">
				<span
					className="text-sm font-bold mb-[5px]"
					style={{ fontSize: 'clamp(6px,2vw,14px)' }}>
					Scientific confirmation
				</span>
				<div className="flex items-center gap-[10px]">
					<span
						dangerouslySetInnerHTML={{
							__html: checkMarkIcon,
						}}
					/>
					<span
						className="text-[10px]"
						style={{ fontSize: 'clamp(8px,2vw,10px)' }}>
						Anton weissenhofer
					</span>
				</div>
			</div>
		</div>
	);
};

export default SpeciesInfo;
