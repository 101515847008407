import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import RootReducer from '../Reducers/RootReducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'letsGoStatus',
		'selectedFooterLegal',
		'errorStatus',
		'loginedInUserData',
		'modalStatus',
		'landingPage',
		'sessionExpired',
	],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
const persistor = persistStore(store);

export { store, persistor };
