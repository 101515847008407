import {
	audioCaptureIcon,
	camera360Icon,
	cameraTrapIcon,
	dashboardIcon,
	latestSightingsIcon,
} from '../../../components/icons/icons';
import { layoutKeys } from './common/constants';

const ExploreMenu = (props) => {
	const { onSelectionClick, selectedSection } = props;
	const options = [
		{
			id: layoutKeys.DASHBOARD,
			label: 'Dashboard',
			onClick: () => onSelectionClick(layoutKeys.DASHBOARD),
			padding: 3,
			icon: dashboardIcon,
		},
		{
			id: layoutKeys.CAMERA360,
			label: 'Camera 360',
			onClick: () => onSelectionClick(layoutKeys.CAMERA360),
			padding: 3,
			icon: camera360Icon,
		},
		{
			id: layoutKeys.CAMERATRAP,
			label: 'Camera Trap',
			onClick: () => onSelectionClick(layoutKeys.CAMERATRAP),
			icon: cameraTrapIcon,
		},
		{
			id: layoutKeys.AUDIOCAPTURE,
			label: 'Audio Capture',
			onClick: () => onSelectionClick(layoutKeys.AUDIOCAPTURE),
			icon: audioCaptureIcon,
		},
		{
			id: layoutKeys.LATESTSIGHTINGS,
			label: 'Latest Sightings',
			onClick: () => onSelectionClick(layoutKeys.LATESTSIGHTINGS),
			icon: latestSightingsIcon,
		},
	];
	return (
		<div className="flex flex-col gap-[10px]">
			{options.map((option) => (
				<div
					role="button"
					tabIndex="0"
					onKeyDown={() => {}}
					aria-label="Span"
					className={`flex items-center hover:bg-[#D7F4B1] rounded-[5px] gap-[10px] p-1 hover:text-[#1E1E21] cursor-pointer ${selectedSection === option.id ? 'bg-[#D7F4B1] text-[#1E1E21]' : 'bg-[#1E1E21] text-[#BFBFBF]'}`}
					key={option.id}
					onClick={option.onClick}>
					<span
						className={`w-12 h-12 p-${option.padding || 2} max-sm:p-0 `}
						style={{
							width: 'clamp(2.5rem,3vw,3rem)',
							height: 'clamp(2.5rem,3vw,3rem)',
						}}
						dangerouslySetInnerHTML={{
							__html: option.icon,
						}}
					/>
					<span
						className="text-lg font-normal capitalize max-md:leading-normal"
						style={{ fontSize: 'clamp(0.5rem,2vw,1.1rem)' }}>
						{option.label}
					</span>
				</div>
			))}
		</div>
	);
};

export default ExploreMenu;
